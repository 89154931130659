import React from 'react';
import {webs} from "./../Socket";
import {translate} from "react-i18next";
import {WEB_SOCKET_URL} from "../helpers/constants";


const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

const currensys = [
    "USDEUR", "USDGBP", "USDJPY", "USDAUD",
    "EURUSD","EURGBP","EURJPY", "EURAUD",
    "GBPEUR","GBPJPY","GBPAUD", "GBPUSD",
];

class LiveExchangeRates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount()
        {
            const web_sock = webs(WEB_SOCKET_URL);
            const newArr =  currensys.map((item)=>  {
                return  {
                    "cmd":"quote",
                    "symbol": item
                };
            });

        newArr.forEach((item) => {
            web_sock.send(item, (data) => {
                const symbol = data.symbol;
                this.setState({...this.state, [symbol]: data})
            });
        });
      }

    render(){
        const { t } = this.props;
        const {GBPUSD, USDJPY, EURUSD, EURGBP, AUDUSD, EURJPY, EURAUD, GBPJPY, GBPAUD } = this.state;
        return(
            <div className="rate_changes">
                <h3>{renderHtml(t('CALC_QUOTES_HEAD'))}</h3>
                <div className="swipe">
                    {renderHtml(t('$_SWIPE'))}
                    <span></span></div>
                <div className="table">
                    <table>
                        <tbody>
                        <tr className="hor">
                            <td></td>
                            <td>USD</td>
                            <td>EUR</td>
                            <td>GBP</td>
                            <td>JPY</td>
                            <td>AUD</td>
                        </tr>
                        <tr>
                            <td className="for_flag">
                                <span className="flag_24 USD"></span>
                                <span className="its_name">1 USD</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_USDUSD">
                                <span className="bid">1.0000</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_USDEUR">
                                <span className="bid">{EURUSD ? EURUSD.bid : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_USDGBP">
                                <span className="bid">{GBPUSD ? GBPUSD.bid : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_USDJPY">
                                <span className="bid">{USDJPY ? USDJPY.bid : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_USDAUD" >
                                <span className="bid">{AUDUSD ? AUDUSD.bid : "---"}</span>
                            </td>
                        </tr>
                        <tr className="even">
                            <td>{renderHtml(t('INVERCE'))}</td>
                            <td className="quotes_ul_item quotes_ul_item_USDUSD" >
                                <span className="bid">1.0000</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_EURUSD" >
                                <span className="bid">{EURUSD ? Math.ceil((1 / EURUSD.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_GBPUSD" >
                                <span className="bid">{GBPUSD ? Math.ceil((1 / GBPUSD.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_JPYUSD" >
                                <span className="bid">{USDJPY ? Math.ceil((1 / USDJPY.ask) * 100000) / 100000 : "---"}</span></td>
                            <td className="quotes_ul_item quotes_ul_item_AUDUSD" >
                                <span className="bid">{AUDUSD ? Math.ceil((1 / AUDUSD.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="for_flag">
                                <span className="flag_24 EUR"></span>
                                <span className="its_name">1 EUR</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_EURUSD">
                                <span className="bid">{EURUSD ? EURUSD.bid : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_EUREUR">
                                <span className="bid">1.0000</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_EURGBP">
                                <span className="bid">{EURGBP ? EURGBP.bid : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_EURJPY">
                                <span className="bid">{EURJPY ? EURJPY.bid : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_EURAUD">
                                <span className="bid">{EURAUD ? EURAUD.bid : "---"}</span>
                            </td>
                        </tr>
                        <tr className="even">
                            <td>{renderHtml(t('INVERCE'))}</td>
                            <td className="quotes_ul_item quotes_ul_item_USDEUR">
                                <span className="bid">{EURUSD ? Math.ceil((1 / EURUSD.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_EUREUR">
                                <span className="bid">1.0000</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_GBPEUR">
                                <span className="bid">{EURGBP ? Math.ceil((1 / EURGBP.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_JPYEUR">
                                <span className="bid">{EURJPY ? Math.ceil((1 / EURJPY.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_AUDEUR">
                                <span className="bid">{EURAUD ? Math.ceil((1 / EURAUD.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="for_flag">
                                <span className="flag_24 GBP"/>
                                <span className="its_name">1 GBP</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_GBPUSD">
                                <span className="bid">{GBPUSD ? GBPUSD.bid : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_GBPEUR">
                                <span className="bid">{EURGBP ? EURGBP.bid : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_GBPGBP">
                                <span className="bid">1.0000</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_GBPJPY">
                                <span className="bid">{GBPJPY ? GBPJPY.bid : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_GBPAUD">
                                <span className="bid">{GBPAUD ? GBPAUD.bid : "---"}</span>
                            </td>
                        </tr>
                        <tr className="even">

                            <td>{renderHtml(t('INVERCE'))}</td>
                            <td className="quotes_ul_item quotes_ul_item_USDGBP">
                                <span className="bid">{GBPUSD ? Math.ceil((1 / GBPUSD.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_EURGBP">
                                <span className="bid">{EURGBP ? Math.ceil((1 / EURGBP.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_GBPGBP">
                                <span className="bid">1.0000</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_JPYGBP">
                                <span className="bid">{GBPJPY ? Math.ceil((1 / GBPJPY.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                            <td className="quotes_ul_item quotes_ul_item_AUDGBP">
                                <span className="bid">{GBPAUD ? Math.ceil((1 / GBPAUD.ask) * 100000) / 100000 : "---"}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export default (translate('common')(LiveExchangeRates));