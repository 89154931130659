import React, {Component} from 'react';
import {translate} from "react-i18next";

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props}}>
</span>);

class Modal extends Component {

    modalRef = React.createRef();

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.modalRef && !this.modalRef.current.contains(event.target)) {
            this.props.onCloseModal();
        }
    };


    render() {
        let className, symbol, url, demo = false;
        const {profit, lang, t} = this.props;

        switch (lang) {
            case "fa":
                url = `https://login.ifcmiran.asia/${lang}/register`;
                break;
            case "tr":
                url = `https://login.fxifcm.asia/${lang}/register`;
                break;
            default:
                url = `https://private.ifcmarkets.com/${lang}/register`;
        }

        if (profit >= 0) {
            className = 'green';
            symbol = '+';

        } else {
            demo = true;
            className = 'red';
            symbol = '-';
        }

        let calculatedAmountClass = `calculated-amount ${className}`;

        let numberLength = profit.toString().length;
        let calculatedAmountStyle = {};
        if (numberLength >= 6) {
            calculatedAmountStyle.minHeight = '150px';
            calculatedAmountStyle.minWidth = '150px';
            calculatedAmountStyle.fontSize = '25px';
        } else {
            calculatedAmountStyle.minHeight = '140px';
            calculatedAmountStyle.minWidth = '140px';
        }
        let calculatedAmountText = symbol + Math.abs(profit);

        return (
            <div className="modal" style={styles.modal}>
                <div ref={this.modalRef} className="modal-content" style={styles.modalContent}>
                    <div style={styles.modalBody}>
                        <div className={demo ? 'profit modal-header-notice d-none mb-20' : 'profit modal-header-notice mb-20'}>
                            <h2 className="profit modal-header-notice" style={styles.modalTitle}> {renderHtml(t('CONGRATULATIONS'))}</h2>
                            <p style={styles.modalText}>{renderHtml(t('DEMO_POSITIVE_RES'))}</p>
                        </div>
                        <div className={demo ? 'loss modal-header-notice mb-20' : 'loss modal-header-notice d-none mb-20'}>
                            <p>{renderHtml(t('DEMO_NEGATIVE_RES'))}</p>
                        </div>
                        <span id="calculated-amount-modal" className={calculatedAmountClass}
                              style={calculatedAmountStyle}>
                            {calculatedAmountText} <sup>$</sup>
                        </span>
                        <div className={demo ? 'profit modal-body-block d-none' : 'profit modal-body-block'}>
                            <p className="mb-20">{renderHtml(t('IN_ORDER_TO_START'))}</p>
                            <a href={url} className="btn btn-lg btn-success ga__open_real_acc_cfd">{renderHtml(t('$_OPEN_TRADING_ACCOUNT'))}</a>
                        </div>
                        <div className={demo ? 'loss modal-body-block' : 'loss modal-body-block d-none'}>
                            <p className="mb-20">{renderHtml(t('START_WITHOUT_RISKING'))}</p>
                            <a href={`${url}?type=demo`} className="button_blue btn btn-lg ga__open_demo_acc_cfd">{renderHtml(t('TRY_GREE_DEMO'))}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5,
    },
    modalBody: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: '40px',
        borderRadius: '5px',
        maxWidth: '500px',
    },
    modalTitle: {
        marginTop: '0',
    },
    modalText: {
        marginBottom: '20px',
    },
    closeButton: {
        padding: '8px 16px',
    },
};

export default (translate('common')(Modal));

