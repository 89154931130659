import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export const chartConfig = () => {
    am4core.useTheme(am4themes_animated);
    am4core.options.commercialLicense = true;
    var chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;

    // Category Axis (X)
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.parseDates = true;
    categoryAxis.graphType = "line";
    categoryAxis.tooltip.background.fill = "#67b7dc";
    categoryAxis.dateFormats = [{
        period: 'fff',
        format: 'JJ:NN:SS'
    }, {
        period: 'ss',
        format: 'JJ:NN:SS'
    }, {
        period: 'mm',
        format: 'JJ:NN'
    }, {
        period: 'hh',
        format: 'JJ:NN'
    }, {
        period: 'DD',
        format: 'DD.MM.YYYY'
    }, {
        period: 'WW',
        format: 'DD.MM.YYYY'
    }, {
        period: 'MM',
        format: 'MM.YYYY'
    }, {
        period: 'YYYY',
        format: 'YYYY'
    }];


    //Value Axis (Y)
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;
    valueAxis.tooltip.background.fill = "#67b7dc";
    valueAxis.renderer.grid.template.strokeOpacity = 1;
    valueAxis.renderer.grid.template.strokeDasharray = "1,3";
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.autoGridCount = true;
    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 1;
    valueAxis.renderer.line.stroke = am4core.color("#000000");
    valueAxis.renderer.ticks.template.disabled = false;
    valueAxis.renderer.ticks.template.strokeOpacity = 1;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#000000");
    valueAxis.renderer.ticks.template.strokeWidth = 1;
    valueAxis.renderer.ticks.template.length = 5;
    valueAxis.renderer.inversed = false;




    const range = valueAxis.axisRanges.create();
    range.grid.stroke = am4core.color("#8a8a8a");
    range.grid.strokeDasharray = 1000;
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 1;
    range.label.inside = true;
    range.label.fill = range.grid.stroke;
    range.label.verticalCenter = "bottom";



    // Series CandlestickSeries
    var series = chart.series.push(new am4charts.LineSeries());
    // COLOR FOR CHART LINES (BLUE)
    series.stroke = am4core.color("#67b7dc");
    series.strokeWidth = 3;
    series.minBulletDistance = 15;
    // Value X
    series.dataFields.categoryX = "date";
    // Value Y
    series.dataFields.valueY = "value";

    // For CandlestickSeries and OHLCSeries
    series.dataFields.openValueY = "open";
    series.dataFields.lowValueY = "low";
    series.dataFields.highValueY = "high";
    series.simplifiedProcessing = true;


    // Bullets
    series.bullets.push(new am4charts.CircleBullet());


    // TOOL TIP
    series.tooltipText ="Close: {valueY.value}";
    series.tooltip.background.cornerRadius = 0;
    series.tooltip.getFillFromObject = true;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = "horizontal";
    series.tooltip.label.fill = "#000000";
    series.tooltip.label.minWidth = 20;
    series.tooltip.label.minHeight = 20;
    series.tooltip.label.textAlign = "middle";
    series.tooltip.label.textValign = "middle";



    // Make bullets grow on hover
    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 2;
    bullet.circle.fill = am4core.color("#67b7dc");
    let bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 2.8;


    // Make a panning cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.stroke = am4core.color("#67b7dc");
    chart.cursor.lineX.strokeWidth = 2;
    chart.cursor.lineX.strokeOpacity = 0.5;
    chart.cursor.lineX.strokeDasharray = "";
    chart.cursor.lineY.stroke = am4core.color("#67b7dc");
    chart.cursor.lineY.strokeWidth = 2;
    chart.cursor.lineY.strokeOpacity = 0.5;
    chart.cursor.lineY.strokeDasharray = "";


    // Scroll Bar
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    const scrollBarSeries = chart.series.push(new am4charts.LineSeries());
    // chart.scrollbarX.background.fill = am4core.color("#ccfff5");
    chart.scrollbarX.background.fillOpacity = 0.9;
    scrollBarSeries.dataFields.categoryX = "date";
    scrollBarSeries.dataFields.valueY = "value";
    scrollBarSeries.defaultState.properties.visible = false;
    scrollBarSeries.hiddenInLegend = true;
    scrollBarSeries.fillOpacity = 0.5;
    scrollBarSeries.strokeOpacity = 0.6;

    chart.scrollbarX.series.push(scrollBarSeries);
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    chart.scrollbarX.height = 30;


    // Listeners
    chart.events.on("ready", function () {
        categoryAxis.zoom({start:0.79, end:1});
    });


    return chart;

}


export const chartConfigCandle = () => {
        am4core.useTheme(am4themes_animated);
        var chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.paddingRight = 20;


        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;

        categoryAxis.renderer.labels.template.adapter.add('text', function(text, target) {
            if (target.dataItem && target.dataItem.category !== undefined) {
                return chart.dateFormatter.format(target.dataItem.category, "dd MMM"); //format as different date string from data
            }
            else {
                return text
            }
        });

        categoryAxis.adapter.add("getTooltipText", function(text, target) {
            return chart.dateFormatter.format(text, "dd MMM"); //format for cursor tooltip
        });

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;

        var series = chart.series.push(new am4charts.CandlestickSeries());
        series.dataFields.categoryX = "date";
        series.dataFields.valueY = "close";
        series.dataFields.openValueY = "open";
        series.dataFields.lowValueY = "low";
        series.dataFields.highValueY = "high";
        series.simplifiedProcessing = true;
        series.tooltipText = "Open:${openValueY.value}\nLow:${lowValueY.value}\nHigh:${highValueY.value}\nClose:${valueY.value}";

        chart.cursor = new am4charts.XYCursor();

        const range = valueAxis.axisRanges.create();
        range.grid.stroke = am4core.color("#8a8a8a");
        range.grid.strokeDasharray = 1000;
        range.grid.strokeWidth = 2;
        range.grid.strokeOpacity = 1;
        range.label.inside = true;
        range.label.fill = range.grid.stroke;
        range.label.verticalCenter = "bottom";

        /*
        // optional if you want to use a separate line series
        // for the scrollbar
        var lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "date";
        lineSeries.dataFields.valueY = "close";
        // need to set on default state, as initially series is "show"
        lineSeries.defaultState.properties.visible = false;

        // hide from legend too (in case there is one)
        lineSeries.hiddenInLegend = true;
        lineSeries.fillOpacity = 0.5;
        lineSeries.strokeOpacity = 0.5;
        */


    chart.scrollbarX = new am4charts.XYChartScrollbar();
    const scrollBarSeries = chart.series.push(new am4charts.LineSeries());
    // chart.scrollbarX.background.fill = am4core.color("#ccfff5");
    chart.scrollbarX.background.fillOpacity = 0.9;
    scrollBarSeries.dataFields.categoryX = "date";
    scrollBarSeries.dataFields.valueY = "value";
    scrollBarSeries.defaultState.properties.visible = false;
    scrollBarSeries.hiddenInLegend = true;
    scrollBarSeries.fillOpacity = 0.5;
    scrollBarSeries.strokeOpacity = 0.6;

    chart.scrollbarX.series.push(scrollBarSeries);
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    chart.scrollbarX.height = 30;

        // var scrollbarX = new am4charts.XYChartScrollbar();
        // scrollbarX.series.push(series);
        // chart.scrollbarX = scrollbarX;


        return chart;

    }
