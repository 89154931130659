import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router,Switch } from 'react-router-dom';
import Routes from "./routes";
import {applyMiddleware, createStore} from "redux";
import { Provider } from "react-redux";
import mainReducer from "./redux/reducers"
import middleWare from "./redux/middleWare";
import "../src/main.css"
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-KCLGMJQ'
};

TagManager.initialize(tagManagerArgs);

const store = createStore(mainReducer, applyMiddleware(middleWare));

export const history = createBrowserHistory();

class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <Switch >
                     <Routes />
                    </Switch>
                </Router>
            </Provider>
        );
    }
}

export default App;
