export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const CHANGE_CURRENCY_CRYPTO = "CHANGE_CURRENCY_CRYPTO";
export const CHANGE_CURRENCY_LANDING = "CHANGE_CURRENCY_LANDING";
export const GET_QUOTE_LIST = "GET_QUOTE_LIST";
export const GET_RATE = "GET_RATE";
export const GET_RATE_SUCCESS = "GET_RATE_SUCCESS";
export const GET_EXCHANGE = "GET_EXCHANGE";
export const GET_EXCHANGE_SUCCESS = "GET_EXCHANGE_SUCCESS";
export const GET_EXCHANGE_CRYPTO = "GET_EXCHANGE_CRYPTO";
export const GET_EXCHANGE_SUCCESS_CRYPTO = "GET_EXCHANGE_SUCCESS_CRYPTO";
export const OTHER_CURRENCY = "OTHER_CURRENCY";
export const CHANGE_NUMBER = "CHANGE_NUMBER";
export const LANGUAGE_CHANGE = "LANGUAGE_CHANGE";

export const NUMBER_VALUE = "NUMBER_VALUE";
export const NUMBER_VALUE_CRYPTO = "NUMBER_VALUE_CRYPTO";
export const NUMBER_VALUE_LANDING = "NUMBER_VALUE_LANDING";
export const HANDLE_IS_FETCHING = "HANDLE_IS_FETCHING";
export const GET_AVAILABLE_COUPLES = "GET_AVAILABLE_COUPLES";
export const GET_AVAILABLE_COUPLES_CRYPTO = "GET_AVAILABLE_COUPLES_CRYPTO";
export const GET_AVAILABLE_COUPLES_SUCCESS = "GET_AVAILABLE_COUPLES_SUCCESS";
export const GET_AVAILABLE_COUPLES_SUCCESS_CRYPTO = "GET_AVAILABLE_COUPLES_SUCCESS_CRYPTO";

/** Mobile menu **/
export const OPEN_MOBILE_MENU = "OPEN_MOBILE_MENU";
export const CLOSE_MOBILE_MENU = "CLOSE_MOBILE_MENU";
export const CHANGE_PLATFORM_TYPE = "PLATFORM_TYPE";
// export const NUMBER_NULL = "NUMBER_NULL";

export const changeCurency = (cur,cur2, isInitial) => ({type: CHANGE_CURRENCY, payload: {cur,cur2, isInitial}});
export const changeCurencyCrypto = (curCrypto,curCrypto2, isInitial) => ({type: CHANGE_CURRENCY_CRYPTO, payload: {curCrypto,curCrypto2, isInitial}});
export const changeCurencyLanding = (cur,cur2, isInitial) => ({type: CHANGE_CURRENCY_LANDING, payload: {cur,cur2, isInitial}});
export const changeNumber = (item) => ({type: CHANGE_NUMBER, payload: {item}});
export const onLanguageChange = (language) => ({type: LANGUAGE_CHANGE, payload: {language}});
export const onChangePlatformType = (platform) => ({type: CHANGE_PLATFORM_TYPE, payload: {platform}});

export const GET_CURS_DATA = "GET_CURS_DATA";
export const GET_CURS_DATA_SUCCESS = "GET_CURS_DATA_SUCCESS";
export const CHANGED_CALCULATOR_COUPLES = "CHANGED_CALCULATOR_COUPLES";
export const getCursData = (calculatorInitialCouple) => ({type: GET_CURS_DATA, payload: calculatorInitialCouple});
export const getCursDataSuccess = (curData) => ({type: GET_CURS_DATA_SUCCESS, payload: {curData}})
export const changedCalculatorAvailableCouples = (calculatorInitialCouple) => ({type: CHANGED_CALCULATOR_COUPLES, payload: {calculatorInitialCouple}})


export const getRate = (cur, cur2) => ({type: GET_RATE, payload: {cur, cur2}});
export const getRateSuccess = (rate) => ({type: GET_RATE_SUCCESS, payload: {rate}});
export const getExchange = (cur,cur2) => ({type: GET_EXCHANGE, payload: {cur,cur2}});
export const getSuccessExchange = (data) => ({type: GET_EXCHANGE_SUCCESS, payload: {data}});
export const getExchangeCrypto = (curCrypto,curCrypto2) => ({type: GET_EXCHANGE_CRYPTO, payload: {curCrypto,curCrypto2}});
export const getSuccessExchangeCrypto = (dataCrypto) => ({type: GET_EXCHANGE_SUCCESS_CRYPTO, payload: {dataCrypto}});
export const otherCurrancy = (cur,cur2) => ({type: OTHER_CURRENCY, payload: {cur, cur2}});
export const numberValue = (number) => ({type: NUMBER_VALUE, payload: {number}});
export const numberValueCrypto = (numberCrypto) => ({type: NUMBER_VALUE_CRYPTO, payload: {numberCrypto}});
export const numberValueLanding = (number) => ({type: NUMBER_VALUE_LANDING, payload: {number}});
export const handleFetching = (isFetching) =>{ return {type: HANDLE_IS_FETCHING, payload: {isFetching}}};
// export const numberNull = () => ({type: NUMBER_NULL, payload: 1});

export const getAvailableCouples = () => ({type: GET_AVAILABLE_COUPLES });
export const getAvailableCouplesCrypto = (availableCouples) => ({type: GET_AVAILABLE_COUPLES_CRYPTO });
// export const handleStyleChange = () => ({type: HANDLE_STYLE_CHANGE });
export const getAvailableCouplesSuccess = (availableCouples) => ({type: GET_AVAILABLE_COUPLES_SUCCESS, payload: {availableCouples} });
export const getAvailableCouplesSuccessCrypto = (availableCouplesCrypto) => ({type: GET_AVAILABLE_COUPLES_SUCCESS_CRYPTO, payload: {availableCouplesCrypto} });

export const CURRENCY_CONVERTER = "CURRENCY_CONVERTER";
export const CURRENCY_CONVERTER_SUCCESS = "CURRENCY_CONVERTER_SUCCESS";

export const CURRENCY_CONVERTER_CRYPTO = "CURRENCY_CONVERTER_CRYPTO";
export const CURRENCY_CONVERTER_SUCCESS_CRYPTO = "CURRENCY_CONVERTER_SUCCESS_CRYPTO";

export const CURRENCY_CONVERTER_LANDING = "CURRENCY_CONVERTER_LANDING";
export const CURRENCY_CONVERTER_SUCCESS_LANDING = "CURRENCY_CONVERTER_SUCCESS_LANDING";

export const currencyConverte = (cur,cur2) => ({type: CURRENCY_CONVERTER, payload: {cur,cur2}})
export const CurrencyConverteSuccess = (CurrencyConverteData) => ({type: CURRENCY_CONVERTER_SUCCESS, payload: {CurrencyConverteData}})

export const currencyConverterCrypto = (curCrypto,curCrypto2) => ({type: CURRENCY_CONVERTER_CRYPTO, payload: {curCrypto,curCrypto2}})
export const CurrencyConverteSuccessCrypto = (CurrencyConverterDataCrypto) => ({type: CURRENCY_CONVERTER_SUCCESS_CRYPTO, payload: {CurrencyConverterDataCrypto}})

export const currencyConverterLanding = (cur,cur2) => ({type: CURRENCY_CONVERTER_LANDING, payload: {cur,cur2}})
export const CurrencyConverteSuccessLanding = (CurrencyConverterDataLanding) => ({type: CURRENCY_CONVERTER_SUCCESS_LANDING, payload: {CurrencyConverterDataLanding}})


export const openMobileMenu = () => ({type: OPEN_MOBILE_MENU });
export const closeMobileMenu = () => ({type: CLOSE_MOBILE_MENU});

export const GET_TOP_GAIN_LOSS = "GET_TOP_GAIN_LOSS";
export const GET_TOP_GAIN_LOSS_SUCCESS = "GET_TOP_GAIN_LOSS_SUCCESS";
export const getTopGainLoss = () => ({type: GET_TOP_GAIN_LOSS});
export const getTopGainLossSuccess = (topGainLoss) => ({type: GET_TOP_GAIN_LOSS_SUCCESS, payload: {topGainLoss}});

export const GET_EXCHANGE_RATE_TODAY = "GET_EXCHANGE_RATE_TODAY";
export const GET_EXCHANGE_RATE_TODAY_SUCCESS = "GET_EXCHANGE_RATE_TODAY_SUCCESS";
export const getExchangeRateTodayRate = (cur,cur2) => ({type: GET_EXCHANGE_RATE_TODAY, payload: {cur,cur2}});
export const getExchangeRateTodayRateSuccess = (exchangeRateTodayRate) => ({type: GET_EXCHANGE_RATE_TODAY_SUCCESS, payload: {exchangeRateTodayRate}});


export const GET_EXCHANGE_RATE_TODAY_CRYPTO = "GET_EXCHANGE_RATE_TODAY_CRYPTO";
export const GET_EXCHANGE_RATE_TODAY_SUCCESS_CRYPTO = "GET_EXCHANGE_RATE_TODAY_SUCCESS_CRYPTO";
export const getExchangeRateTodayRateCrypto = (curCrypto,curCrypto2) => ({type: GET_EXCHANGE_RATE_TODAY_CRYPTO, payload: {curCrypto,curCrypto2}});
export const getExchangeRateTodayRateSuccessCrypto = (exchangeRateTodayRateCrypto) => ({type: GET_EXCHANGE_RATE_TODAY_SUCCESS_CRYPTO, payload: {exchangeRateTodayRateCrypto}});


export const GET_TOP_CRYPTO_PAIRS_RATE = "GET_TOP_CRYPTO_PAIRS_RATE";
export const GET_TOP_CRYPTO_PAIRS_RATE_SUCCESS = "GET_TOP_CRYPTO_PAIRS_RATE_SUCCESS";
export const getTopCryptoPairsRate = (curCrypto,curCrypto2) => ({type: GET_TOP_CRYPTO_PAIRS_RATE, payload: {curCrypto,curCrypto2}});
export const getTopCryptoPairsRateSuccess = (topCryptoPairsRate) => ({type: GET_TOP_CRYPTO_PAIRS_RATE_SUCCESS, payload: {topCryptoPairsRate}});


export const CHANGED_ACTIVE_INDEX = "CHANGED_ACTIVE_INDEX";
export const onChangeActiveIndex = (activeIndex) => ({type: CHANGED_ACTIVE_INDEX, payload: {activeIndex}})

export const GET_INITIAL_CURS_DATA = "GET_INITIAL_CURS_DATA";
export const GET_INITIAL_CURS_DATA_SUCCESS = "GET_INITIAL_CURS_DATA_SUCCESS";
export const getInitialCursData = (cur,cur2) => ({type: GET_INITIAL_CURS_DATA, payload: {cur,cur2}});
export const getInitialCursDataSuccess = (rate) => ({type: GET_INITIAL_CURS_DATA_SUCCESS, payload: {rate}});

export const GET_INITIAL_CURS_DATA_CRYPTO = "GET_INITIAL_CURS_DATA_CRYPTO";
export const GET_INITIAL_CURS_DATA_SUCCESS_CRYPTO = "GET_INITIAL_CURS_DATA_SUCCESS_CRYPTO";
export const getInitialCursDataCrypto = (curCrypto,curCrypto2) => ({type: GET_INITIAL_CURS_DATA_CRYPTO, payload: {curCrypto,curCrypto2}});
export const getInitialCursDataSuccessCrypto = (rate) => ({type: GET_INITIAL_CURS_DATA_SUCCESS_CRYPTO, payload: {rate}});

export const GET_INITIAL_CURS_DATA_LANDING = "GET_INITIAL_CURS_DATA_LANDING";
export const GET_INITIAL_CURS_DATA_SUCCESS_LANDING = "GET_INITIAL_CURS_DATA_SUCCESS_LANDING";
export const getInitialCursDataLanding = (cur,cur2) => ({type: GET_INITIAL_CURS_DATA_LANDING, payload: {cur,cur2}});
export const getInitialCursDataSuccessLanding = (rate) => ({type: GET_INITIAL_CURS_DATA_SUCCESS_LANDING, payload: {rate}});


export const GET_VOLATILITY = "GET_VOLATILITY";
export const GET_VOLATILITY_SUCCESS = "GET_VOLATILITY_SUCCESS";
export const getVolatility = () => ({type: GET_VOLATILITY});
export const getVolatilitySuccess = (volatility) => ({type: GET_VOLATILITY_SUCCESS, payload: {volatility}});
