import React from "react";
import {connect} from "react-redux";
import {
    changeCurencyLanding, getInitialCursDataLanding, currencyConverterLanding, numberValueLanding
} from "../redux/actions/userActions"
import {withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import SelectCompLanding from "../components/SelectCompLanding";

const renderHtml = (props) => (<div className="p2"
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

const renderHtml_2 = (props) => (<h1 className="h1_title"
    dangerouslySetInnerHTML={{__html: props}}>
</h1>);


class DemoMiniHomePageLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rate: ""
        }
    }

    handleTextChanged = (cur, cur2) => {
        this.props.onCurrencyChangedLanding(cur, cur2)
        this.props.currencyConverterLanding(cur, cur2)
    }

    componentDidMount() {
        this.props.getInitialCursDataLanding(this.props.cur, this.props.cur2);
        const currenciesArrayFromHistory = this.props.match.params.curencies && this.props.match.params.curencies.split("-");
        const currenciesAmount = this.props.match.params.amount;
        if (currenciesAmount) {
            this.props.onInitialNumberValueLanding(currenciesAmount)
        }
        if (currenciesArrayFromHistory) {
            this.props.onCurrencyChangedLanding(currenciesArrayFromHistory[0].toUpperCase(), currenciesArrayFromHistory[1].toUpperCase(), true);
        } else {
            this.props.onCurrencyChangedLanding(this.props.cur, this.props.cur2, true);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.language !== nextProps.language)
            || (this.props.cur !== nextProps.cur)
            || (this.props.cur2 !== nextProps.cur2)
            || (this.props.rate !== nextProps.rate)
            || (this.props.number !== nextProps.number)
    }

    render() {
        const {cur, cur2, changedAmount, rate, number} = this.props;
        const {t} = this.props;
        const myCur_first = `${cur}`;
        const myCur_second = `${cur2}`;
        const myCur_first_plural = `${cur}_PLURAL`;
        const myCur_second_plural = `${cur2}_PLURAL`;
        const myCur_first_dative = `${cur}_DATIVE`;
        const myCur_second_dative = `${cur2}_DATIVE`;
        const myCur_first_multiple = `${cur}_MULTIPLE`;
        const myCur_second_multiple = `${cur2}_MULTIPLE`;
        const myCur_first_trans = `${cur}_TRANS`;
        const myCur_second_trans = `${cur2}_TRANS`;


        console.log(number,'here');
        console.log(cur2,'cur2');
        console.log(cur,'cur');
        console.log(rate,'rate');


        return (
            <div className="currency_box">

                <div className="left_box">
                    <div className="left_text">
                        {renderHtml_2(t('$_CURRENCY_CONVERTER', {
                                AMOUNT: number,
                                CUR1: myCur_first,
                                CUR2: myCur_second,
                                CUR1_PLURAL: (t(myCur_first_plural)),
                                CUR2_PLURAL: (t(myCur_second_plural)),
                                CUR1_DATIVE: (t(myCur_first_dative)),
                                CUR2_DATIVE: (t(myCur_second_dative)),
                                CUR1_MULTIPLE: (t(myCur_first_multiple)),
                                CUR2_MULTIPLE: (t(myCur_second_multiple)),
                                CUR1_TRANS: (t(myCur_first_trans)),
                                CUR2_TRANS: (t(myCur_second_trans)),
                            }
                        ))}
                        {renderHtml(t('$_Real_TIME_LIVE'))}
                        <div className="select_div"
                             id="select_wrapper"
                             data-cur1={cur.toUpperCase()}
                             data-cur2={cur2.toUpperCase()}>
                            <SelectCompLanding cur={"wewewewe"} onCurrencyChangedLanding={this.handleTextChanged}/>
                        </div>
                        <div className="select_text" dir="ltr">
                            {
                                (number > 0 || number < 0) ?
                                    <span className="amount" id="cur1_val">{number}</span>
                                    : <span className="amount" id="cur1_val">0</span>
                            }
                            <span className="cur1" data-currency={cur.toLowerCase()}>{` ${cur}`}</span>
                            {` = `}
                            {
                                ((number * (1 * rate)).toFixed(6) > 0) ? <span id="fz">
                            <span className="result" id="_calc_wrap">{changedAmount}</span>
                            <span className="cur2"
                                  data-currency={cur2.toLowerCase()}>{`${(number * (1 * rate)).toFixed(6)} ${cur2}`}</span>
                            </span>
                                    :
                                    `${cur2}`
                            }
                        </div>

                    </div>
                    <div className="p2-currency">
                        <div className="select_text" dir="ltr">
                            {
                                <span className="amount" id="cur1_val">1</span>
                            }
                            <span className="cur1" data-currency={cur.toLowerCase()}>{` ${cur}`}</span>
                            <span className="equal">{` = `}</span>
                            {
                                <span id="fz">
                            <span className="result" id="_calc_wrap">{changedAmount}</span>
                            <span className="cur2 cur2-color"
                                  data-currency={cur2.toLowerCase()}>{`${((1 * rate)).toFixed(2)} `}</span>
                               <span className="cur2">{`${cur2}`}</span>
                            </span>
                            }
                            <span className="slash">{` / `}</span>
                            {
                                <span className="amount" id="cur1_val">1</span>
                            }
                            <span className="cur1" data-currency={cur.toLowerCase()}>{` ${cur2}`}</span>
                            <span className="equal">{` = `}</span>
                            {
                                <span id="fz">
                            <span className="result" id="_calc_wrap">{changedAmount}</span>
                            <span className="cur2 cur2-color"
                                  data-currency={cur2.toLowerCase()}>{`${((1 / rate)).toFixed(2)} `}</span>
                               <span className="cur2" data-currency={cur2.toLowerCase()}>{`${cur}`}</span>
                            </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, ownProps) {
    return {
        cur: state.cur,
        cur2: state.cur2,
        rate: state.rate,
        number: state.number,
        language: state.language
    }
}

function mapDispatchToProps(dispatch){

    return {
        onCurrencyChangedLanding: (cur, cur2, isInitial) => dispatch(changeCurencyLanding(cur, cur2, isInitial)),
        onNumberValueLanding: (e) => dispatch(numberValueLanding(e.target.value)),
        onInitialNumberValueLanding: (num) => dispatch(numberValueLanding(num)),
        currencyConverterLanding: (cur, cur2) => dispatch(currencyConverterLanding(cur, cur2)),
        getInitialCursDataLanding: (cur, cur2) => dispatch(getInitialCursDataLanding(cur, cur2)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(translate('common')(withRouter(DemoMiniHomePageLanding)))

