import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {chartConfig, chartConfigCandle} from "./configs";
import moment from "moment"
import {WEB_SOCKET_URL} from "../../helpers/constants";


const intervalData = [
    {
        interval: "1m",
        period: 1
    },
    {
        interval: "5m",
        period: 5
    },
    {
        interval: "15m",
        period: 15
    },
    {
        interval: "30m",
        period: 30
    },
    {
        interval: "1h",
        period: 60
    },
    {
        interval: "4h",
        period: 240
    },
    {
        interval: "1d",
        period: 1440
    },
    {
        interval: "1w",
        period: 10080
    },
];
let dateNew = Math.round((Date.now()) / 1000);

 class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bullet: 2,
            period: 240
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.availableCouple !== prevProps.availableCouple){
             this.getChartData(false,null,true);
        }
    }


    getChartData(renderChart, chartType, rerenderChartData){

        const Req = {
            "period": this.state.period,
            "from": dateNew - (this.state.period * 60 * 1000),
            "to": dateNew,
            "cmd": "history",
            "symbol": this.props.availableCouple.toUpperCase()
        };

        const chartSocket = new WebSocket(WEB_SOCKET_URL);

        chartSocket.onopen = () => {
            chartSocket.send(JSON.stringify(Req));
            chartSocket.onmessage = ({data}) => {
                const chartData = JSON.parse(data);
                renderChart && this.initializeChart(chartData);
                if(rerenderChartData){
                    this.chart.data = this.getChartFilteredData(chartData.list);
                }


         }
            }
        };

    componentDidMount() {
        this.getChartData(true);
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }


     removeDuplicates(originalArray, prop) {
         var newArray = [];
         var lookupObject  = {};

         for(var i in originalArray) {
             lookupObject[originalArray[i][prop]] = originalArray[i];
         }

         for(i in lookupObject) {
             newArray.push(lookupObject[i]);
         }
         return newArray;
     }

     getChartFilteredData(list){
        let filteredData = null;
        switch (this.state.period) {
            case 1:
            case 5:
            case 15:
            case 30:
            case 60:
                {
                filteredData = list.map(quote => {
                    var ctm_value = new Date(quote.ctm).getTime();
                    return  {
                    "ctm": quote.ctm,
                    "date": moment(quote.ctm).format("h:mm"),
                    "value": quote.open,
                    "open": quote.open,
                    "close": quote.close,
                    "high": quote.high,
                    "low": quote.low,
                }});

                return this.removeDuplicates(filteredData, "date");
            };

            case 240:
            case 1440:
            case 10080:
                {
                filteredData = list.map(quote => ({
                    "date": moment(quote.ctm).format("DD.MM.YY"),
                    "value": `${quote.open}`,
                    "open": `${quote.open}`,
                    "close": `${quote.close}`,
                    "high": `${quote.high}`,
                    "low": `${quote.low}`,
                }));
                return this.removeDuplicates(filteredData, "date")
            }


        }

    }

     initializeChart = (chartData) => {
         this.chart = chartConfig();
         //Chart Data
         this.chart.data = this.getChartFilteredData(chartData.list);
         this.chart.yAxes.values[0].axisRanges.values[0].value = this.chart.data[this.chart.data.length-1];
      }

      changeChartType(){
          this.chart.xAxes.values[0].zoom({start:0.96, end:1});
      }


    render() {

        return (
            <div className="Apper">
                <button onClick={() => this.changeChartType("LineSeries")} aria-label="Switch to Line Chart">
                    {/*<div className={`LineChart ${this.state.LineSeries}`}> </div>*/}
                </button>
                <button onClick={() => this.changeTypes("CandlestickSeries")} aria-label="Switch to Candlestick Chart">
                    {/*<div className={`CalumChart ${this.state.CalumChart}`}> </div>*/}
                </button>
                <button onClick={() => this.changeTypes("OHLCSeries")} aria-label="Switch to Bar Chart">
                    {/*<div className={`OHLCSeries ${this.state.OHLCSeries}`}> </div>*/}
                </button>
                <div className="intervalButton">
                    {intervalData.map((item, index) => (
                        <button
                            key={index}
                            className={item.period === this.state.period ? "chart_active_period" : ""}
                            onClick={() => {
                                this.setState({ period: item.period }, () => this.getChartData(false, null, true));
                            }}
                            aria-label={`Select ${item.interval} interval`}
                        >
                            {item.interval}
                        </button>
                    ))}
                </div>

                <div id="chartdiv" style={{ width: "100%", height: "370px" }} aria-label="Chart">
                    {/* Chart content */}
                </div>
            </div>
        );
    }
}

export default withRouter(Chart)
