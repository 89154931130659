import React from 'react';
import CurrencyInstrument from "./CurrencyInstrument";
import {translate} from "react-i18next";
import {connect} from "react-redux";


const renderHtml3 = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

class Currenciesdecscomp extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            moreInfo: "none",
            arrow: "",
            search: this.props.searchText,
            description: "",
            instruments: [],
            bid: "---",
            ask: "---"
        };
    }

    showCurDesc(){
       if(this.state.arrow === ""){
           this.setState({arrow: "u_turn", moreInfo: "table-row"})

           fetch(`https://www.ifcm.co.uk:2083/instrument/currency/description?currency=${this.props.abbreviation}`, {
               method: 'get',
               headers: {'Content-Type': 'application/x-www-form-urlencoded'}
           }).then((res) => res.json())
               .then((data) => {
                   if(data.instruments.length < 3) {
                       this.setState({
                           description: data.description,
                           instruments: data.instruments,
                       })
                   } else this.setState({
                       description: data.description,
                       instruments: data.instruments.slice(0,4),
                   })
               }
               )
               .catch((err)=>console.log(err));
       }
       else
           this.setState({
               arrow: "",
               moreInfo: "none",
               description: "",
               instruments: [],
           })
    }


    render(){

        const { t, name } = this.props;

        if(this.props.abbreviation === this.props.searchText.toUpperCase()){
            setTimeout(
                function() {
                    let elm = document.getElementById("red");
                    elm.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                }
                    .bind(this),
                10
            );
        }

        return[
                  <tr className={`${(this.props.index%2===1)?"odd":"even"}  curr_${this.props.abbreviation} __curow`}
                      id={`${this.props.abbreviation === this.props.searchText.toUpperCase() ? "red" : "none"}`}

                  >
                        <td className="__currencies_name">
                            <span className={` flag_24 ${this.props.abbreviation}`}/>
                            {(t(`${name}`))}
                        </td>
                        <td>{this.props.abbreviation}</td>
                        <td>{this.props.code}</td>
                        <td>{this.props.symbol}</td>
                        <td className="tgl_btn_col">
                            <a className="curr_info_tgl"
                               onClick={()=>this.showCurDesc()}>
                                <span className="hide370"> {`${this.props.abbreviation} \...`}</span>
                                <span className={`arrow_to_show ${this.state.arrow}`}/>
                            </a>
                        </td>
                    </tr>
                      ,
                  <tr className="curr_AUD_info info_none" style={{display: this.state.moreInfo}}>
                        <td colSpan="5" className="curr_info_td">
                            <div className="left_dropping_down" style={{clear: "both"}}>
                                <span className={`flag_32 ${this.props.abbreviation}_32`}/>
                                <div className="description-body">
                                    {renderHtml3(t(`${this.props.abbreviation}_F_PROFILE`))}
                                </div>
                            </div>

                            <div className="right_dropping_down">
                                {
                                    this.state.instruments.map((item,index) => {
                                        return <CurrencyInstrument item={item} key={index} index={index}/>

                                    })
                                }
                                <div className="see_more_pairs">
                                    {
                                        (this.props.language === 'fa')
                                            ?
                                            <a href={`https://www.ifcmiran.asia/${this.props.language}/trading-conditions/forex/${this.props.abbreviation.toLowerCase()}`}>
                                                {renderHtml3(t("SEE_ALL_PAIRS"))}
                                            </a>
                                            :
                                            (this.props.language === "zh_cn")?
                                            <a href={`https:///www.ifcmarkets.com/trading-conditions/forex/${this.props.abbreviation.toLowerCase()}`}>
                                                {renderHtml3(t("SEE_ALL_PAIRS"))}
                                            </a>
                                                :<a href={`https://www.ifcmarkets.com/${this.props.language}/trading-conditions/forex/${this.props.abbreviation.toLowerCase()}`}>
                                                {renderHtml3(t("SEE_ALL_PAIRS"))}
                                            </a>
                                    }
                                </div>
                            </div>
                        </td>
                    </tr>
    ]
    }
}

export default connect(
    state => ({
        language: state.language
    })
)(translate('common')(Currenciesdecscomp));