import React from 'react';
import Tabs from "../tab_component/Tabs";
import Tab from "../tab_component/Tab";
import ProfitLossCalcForm from "./ProfitLossCalcForm";
import _ from "lodash";
import {translate} from "react-i18next";
import {Link} from "react-router-dom";
import { CALCULATOR_ROUTE_MAKER, MAIN_ROUTE_BY_LANGUAGE__FUNC } from "../helpers/constants";
import {connect} from "react-redux";


const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

class ProfitLossCalculator extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        fetch("https://www.ifcm.co.uk:2083/instrument")
            .then(res => res.json())
            .then(instruments => {
            // console.log(instruments, "instruments")

            var grouped = _.mapValues(_.groupBy(instruments, 'group'),
                clist => clist.map(car => _.omit(car, 'group')));

            // console.log(grouped)

            this.setState({instruments: grouped});

        })
    }

    render(){
        const { t, language } = this.props;

        return(
            <div>
                <div  className="bradcrumbs size_900">
                    <Link to={MAIN_ROUTE_BY_LANGUAGE__FUNC(language)}>{(t('$_HOME'))}</Link><span className="brdcrumbs_line"> / </span><Link to={CALCULATOR_ROUTE_MAKER(language)}>{(t('$_TRADING_TOOLS'))}</Link> <span className="brdcrumbs_line"> > </span>
                    {(t('PROFIT_LOSS_CALCULATOR'))}
                </div>
            <div className="size_900">
            <div className="calculators">
                {renderHtml(t('PROFIT_LOSS_CALCULATOR'))}
            </div>
            <div className="nt_mt_tabs">
                <Tabs type={"profitLoss"} platform={this.props.platform}>
                    <Tab label="NetTradeX" >
                        <ProfitLossCalcForm hideBuySell={true}
                                            platform={"nttx"} />
                    </Tab>
                    <Tab label="MetaTrader 4/5">
                        <ProfitLossCalcForm platform={"mt4"} />
                    </Tab>
                </Tabs>
            </div>
            </div>
            </div>
        );
    }
}
export default connect(
    state => ({
        language: state.language,
        platform: state.platform
    }))(translate('common')(ProfitLossCalculator))
