import {
    CHANGE_CURRENCY,
    CHANGE_CURRENCY_CRYPTO,
    CHANGE_CURRENCY_LANDING,
    // GET_RATE,
    // GET_QUOTE_LIST,
    GET_EXCHANGE,
    getRateSuccess,
    getSuccessExchange,
    // GET_AVAILABLE_COUPLES,
    LANGUAGE_CHANGE,
    // getAvailableCouplesSuccess,
    GET_CURS_DATA,
    getCursDataSuccess,
    GET_TOP_GAIN_LOSS,
    getTopGainLossSuccess,
    CURRENCY_CONVERTER,
    CURRENCY_CONVERTER_CRYPTO,
    CurrencyConverteSuccess,
    CurrencyConverteSuccessCrypto,
    GET_EXCHANGE_RATE_TODAY,
    GET_EXCHANGE_RATE_TODAY_CRYPTO,
    getExchangeRateTodayRateSuccess,
    GET_TOP_CRYPTO_PAIRS_RATE,
    getTopCryptoPairsRateSuccess,
    getExchangeRateTodayRateSuccessCrypto,
    GET_INITIAL_CURS_DATA,
    GET_INITIAL_CURS_DATA_CRYPTO,
    GET_INITIAL_CURS_DATA_LANDING,
    getInitialCursDataSuccess,
    getInitialCursDataSuccessCrypto,getInitialCursDataSuccessLanding, GET_EXCHANGE_CRYPTO, getSuccessExchangeCrypto,GET_VOLATILITY,getVolatilitySuccess
} from "./actions/userActions";
import {webs} from "../Socket";
import {history} from "./../App"
import {WEB_SOCKET_URL} from "../helpers/constants";

// import API from "../api"

const WEB_SOCKET = webs(WEB_SOCKET_URL);

const middleWare = store => next => {
    return action => {
        const {type, payload} = action;
        switch (type) {
            case CHANGE_CURRENCY : {
                // web_sock.close(Req, (data) => next(getRateSuccess(data.rate)));
                const Req = {
                    "cmd": "exchange",
                    "from": payload.cur,
                    "to": payload.cur2,
                    "auxiliary": ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                        "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR"]
                };

                const pathname = history.location.pathname;
                const state = store.getState();
                let changedPathname = `/${state.language}/${payload.cur.toLowerCase()}-${payload.cur2.toLowerCase()}`;

                if(pathname.split('/').length === 4){
                    changedPathname += `/${pathname.split('/')[3]}`
                }


                if(pathname !== changedPathname && !payload.isInitial && !pathname.includes("currencies")) {
                    history.push(changedPathname)
                }
                WEB_SOCKET.send(Req, (data) =>{
                    next(getRateSuccess(data.rate))});
                next(action);
                break;
            }

            case CHANGE_CURRENCY_LANDING : {
                const Req = {
                    "cmd": "exchange",
                    "from": payload.cur,
                    "to": payload.cur2,
                    "auxiliary": ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                        "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR"]
                };

                const pathname = history.location.pathname;
                const state = store.getState();
                let changedPathname = `/${state.language}/landing-canada/${payload.cur.toLowerCase()}-${payload.cur2.toLowerCase()}`;
                if(pathname.split('/').length === 5){
                    changedPathname += `/${pathname.split('/')[4]}`
                }
                if(pathname !== changedPathname && !payload.isInitial && !pathname.includes("currencies")) {
                    history.push(changedPathname)
                }
                WEB_SOCKET.send(Req, (data) =>{
                    next(getRateSuccess(data.rate))});
                next(action);
                break;
            }

            case CHANGE_CURRENCY_CRYPTO : {
                const Req = {
                    "cmd": "exchange",
                    "from": payload.curCrypto,
                    "to": payload.curCrypto2,
                    "auxiliary": ["BTC", "USD", "EUR", "GBP", "CAD","AUD"]
                };

                const pathname = history.location.pathname;
                const state = store.getState();
                let changedPathname = `/${state.language}/crypto-converter/${payload.curCrypto.toLowerCase()}-${payload.curCrypto2.toLowerCase()}`;
                if(pathname.split('/').length === 5){
                    changedPathname += `/${pathname.split('/')[4]}`
                }
                if(pathname !== changedPathname && !payload.isInitial && !pathname.includes("currencies")) {
                    history.push(changedPathname)
                }
                WEB_SOCKET.send(Req, (data) =>{
                    next(getRateSuccess(data.rate))});
                next(action);
                break;
            }

            case GET_EXCHANGE : {
                const req = {
                    "cmd": "quote",
                    "symbol": store.getState().availableCouple && store.getState().availableCouple.toUpperCase()
                };

                WEB_SOCKET.send(req, (data) =>{
                    const state = store.getState()
                    const rate = state.cur + state.cur2;
                    const rateReverted = state.cur2 + state.cur;
                    if(data){
                        if(data.symbol === rate || data.symbol === rateReverted){
                            next(getSuccessExchange(data));
                        }
                    }

                });
                break;
            }
            case GET_EXCHANGE_CRYPTO : {
                const req = {
                    "cmd": "quote",
                    "symbol": store.getState().availableCoupleCrypto && store.getState().availableCoupleCrypto.toUpperCase()
                };

                WEB_SOCKET.send(req, (data) =>{
                    const state = store.getState()
                    const rate = state.curCrypto + state.curCrypto2;
                    const rateReverted = state.curCrypto2 + state.curCrypto;
                    if(data){
                        if(data.symbol === rate || data.symbol === rateReverted){
                            next(getSuccessExchangeCrypto(data));
                        }
                    }
                });
                break;
            }

            case GET_VOLATILITY : {
                const state = store.getState()
                let now =  Date.now();
                const req = {
                    "period": 1440,
                    "from": Math.round((now) / 1000) - (130 * 60 * 1440),
                    "to": Math.round((now) / 1000),
                    "cmd": "history",
                    "symbol": state.availableCoupleCrypto
                };
                const WEB = webs(WEB_SOCKET_URL);
                WEB.send(req, (data) =>{
                    if(data){
                        next(getVolatilitySuccess(data));
                    }
                });
                break;
            }

            case CURRENCY_CONVERTER : {
                const Req = {
                    "cmd": "exchange",
                    "from": payload.cur,
                    "to": payload.cur2,
                };
                const WEB = webs(WEB_SOCKET_URL);
                WEB.send(Req, (data) => {
                    next(CurrencyConverteSuccess(data))
                });
                break;
            }
            case CURRENCY_CONVERTER_CRYPTO : {
                const Req = {
                    "cmd": "exchange",
                    "from": payload.curCrypto,
                    "to": payload.curCrypto2,
                };
                const WEB = webs(WEB_SOCKET_URL);
                WEB.send(Req, (data) => {
                    next(CurrencyConverteSuccessCrypto(data))
                });
                break;
            }

            case GET_CURS_DATA : {
                const Req = {
                    "period": 1440,
                    "from": Math.round((Date.now()) / 1000) - (3 * 1440 * 60),
                    "to": Math.round((Date.now()) / 1000),
                    "cmd": "history",
                    "symbol": store.getState().calculatorInitialCouple
                };
                WEB_SOCKET.send(Req, (data) => {
                    next(getCursDataSuccess(data))
                });
                break;
            }

            case GET_TOP_GAIN_LOSS : {
                const Req = {cmd: "gainlose", lang: "en", category: 1};


                // this.setState({ up: chartData.up, down: chartData.down }, ()=>  CfdSocket.close());
                WEB_SOCKET.send(Req, (data) => {
                    next(getTopGainLossSuccess(data));
                    // WEB_SOCKET.closeAll();
                });
                break;
            }
            case GET_EXCHANGE_RATE_TODAY : {

                const newReq = {
                    "cmd": "exchange",
                    "from": payload.cur,
                    "to": payload.cur2,
                    "auxiliary": ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                        "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR"]
                };
                WEB_SOCKET.send(newReq, (data) => {
                    next(getExchangeRateTodayRateSuccess(data));
                    // WEB_SOCKET.closeAll();
                });
                break;
            }

            case GET_EXCHANGE_RATE_TODAY_CRYPTO : {
                const newReq = {
                    "cmd": "exchange",
                    "from": payload.curCrypto,
                    "to": payload.curCrypto2,
                    "auxiliary": ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                        "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR"]
                };
                const WEB = webs(WEB_SOCKET_URL);
                WEB.send(newReq, (data) => {
                    next(getExchangeRateTodayRateSuccessCrypto(data))
                });
                break;
            }

            case GET_TOP_CRYPTO_PAIRS_RATE : {
                const newReq = {
                    "cmd": "exchange",
                    "from": payload.curCrypto,
                    "to": payload.curCrypto2,
                    "auxiliary": ["BTC", "USD", "EUR", "GBP", "CAD", "AUD"]
                };
                const WEB = webs(WEB_SOCKET_URL);
                WEB.send(newReq, (data) => {
                    next(getTopCryptoPairsRateSuccess(data))
                });
                break;
            }
            case GET_INITIAL_CURS_DATA : {
                const Req = {
                    "cmd": "exchange",
                    "from": payload.cur,
                    "to": payload.cur2,
                };
                const WEB = webs(WEB_SOCKET_URL);
                WEB.send(Req, (data) => {
                    next(getInitialCursDataSuccess(data.rate))
                });
                break;
            }
            case GET_INITIAL_CURS_DATA_CRYPTO : {
                const Req = {
                    "cmd": "exchange",
                    "from": payload.curCrypto,
                    "to": payload.curCrypto2,
                };
                const WEB = webs(WEB_SOCKET_URL);
                WEB.send(Req, (data) => {
                    next(getInitialCursDataSuccessCrypto(data.rate))
                });
                break;
            }

            case GET_INITIAL_CURS_DATA_LANDING : {
                const Req = {
                    "cmd": "exchange",
                    "from": payload.cur,
                    "to": payload.cur2,
                };
                const WEB = webs(WEB_SOCKET_URL);
                WEB.send(Req, (data) => {
                    next(getInitialCursDataSuccessLanding(data.rate))
                });
                break;
            }

            // case GET_AVAILABLE_COUPLES: {
            //     API.getAvailableCurrenciesCouples()
            //         .then(availableCouples => next(getAvailableCouplesSuccess(availableCouples.map(e => e.name))))
            //     break;
            // }

            case LANGUAGE_CHANGE: {
                const pathname = history.location.pathname;
                // alert(payload.language)
                const state = store.getState();
                let routeChanged = false;

                if(pathname.includes("currencies-and-abbreviations")){
                    routeChanged= true;
                    history.push(`/${payload.language}/currencies-and-abbreviations`)
                } else if (pathname.includes("forex-calculator/profitloss-calculation")) {
                    routeChanged= true;
                    history.push(`/${payload.language}/forex-calculator/profitloss-calculation`)
                }
                else if(pathname.includes("forex-calculator")){
                    routeChanged= true;
                    history.push(`/${payload.language}/forex-calculator`)
                }
                else if(pathname.includes("crypto-converter")){
                    if(pathname.split("/")[3]) {
                        history.push(`/${payload.language}/crypto-converter/${state.curCrypto.toLowerCase()}-${state.curCrypto2.toLowerCase()}`)
                    }else{
                        history.push(`/${payload.language}/crypto-converter`)
                    }
                    routeChanged = true;
                }
                else if(pathname.includes("landing-canada")){
                    if(pathname.split("/")[3]) {
                        history.push(`/${payload.language}/landing-canada/${state.cur.toLowerCase()}-${state.cur2.toLowerCase()}`)
                    }else{
                        history.push(`/${payload.language}/landing-canada`)
                    }
                    routeChanged = true;
                }
                else if (pathname.includes("forex-calculator/margin-calculation")) {
                    routeChanged= true;
                    history.push(`/${payload.language}/forex-calculator/margin-calculation`)
                } else if(pathname.length<5){
                    history.push(`/${payload.language}`)
                    console.log(store, "store");
                }
                else {
                    const data = store.getState()
                    history.push(`/${payload.language}/${data.cur.toLowerCase()}-${data.cur2.toLowerCase()}`)
                }


                if(!pathname.includes("forex") || !pathname.includes('currencies')){
                    routeChanged = true;
                }

                if (!routeChanged){
                    history.push(`/${payload.language}/${state.cur.toLowerCase()}-${state.cur2.toLowerCase()}`)
                }

                next(action)
                return
            }

            default:
                next(action)
        }
    };
};

export default middleWare;
