import {Helmet} from "react-helmet";
import React, {Component} from "react";
import ExchangeRatetoday from "./ExchangeRatetoday";
import TopLossLeader from "./TopLossLeader";
import Chart2 from "../chart/Chart2";
import {connect} from "react-redux";
import {getExchange} from "../../redux/actions/userActions";
import {translate} from "react-i18next";
import NewExchangeRate from "./NewExchangeRate";
import BuySell from "./BuySell";
// import GoogleAd from "../Banners/GoogleAd";


const getAvailab = () => {
    return ["AUDCAD","AUDCHF","AUDJPY","AUDNZD","AUDUSD","CADCHF","CADJPY","CHFJPY","EURAUD","EURCAD","EURCHF","EURCZK","EURGBP","EURHKD","EURJPY","EURMXN","EURNZD","EURPLN","EURRUB","EURSEK","EURTRY","EURUSD","EURZAR","GBPAUD","GBPCAD","GBPCHF","GBPJPY","GBPNZD","GBPSEK","GBPUSD","NZDCAD","NZDCHF","NZDJPY","NZDUSD","USDCAD","USDCHF","USDCNH","USDCZK","USDDKK","USDHKD","USDJPY","USDMXN","USDNOK","USDPLN","USDRUB","USDSEK","USDSGD","USDTRY","USDZAR"]
}

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</span>);

class HomePage extends Component {

    constructor(props) {
        super(props);
        //console.log("home",props);
        this.state = {
            phrases: "",
            chart_text: "",
            quotes_text: "",
            banner_url: `https://www.ifcmarkets.com/${this.props.language}/trading/stocks`,
            lang: ["en", "cs", "fr", "hi", "it", "ja", "ms", "pt", "ru", "tr", "vi", "zh", "zh_cn", "ar", "ID", "fa"]
        };

    }

    componentDidMount() {
        document.body.dir = `${(this.props.language === 'ar' || this.props.language === 'fa') ? 'rtl' : "ltr"}`;
        getAvailab();
        // this.props.getAvailableCouples();
        this.props.getExchange(this.props.cur, this.props.cur2);
        this.getCurrectLink()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language){
            this.getCurrectLink()
        }
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.cur !== nextProps.cur) || (this.props.cur2 !== nextProps.cur2) || (this.props.language !== nextProps.language)
    }

    getCurrectLink = () => {
        switch (this.props.language) {
            case "en":
            case "cs":
            case "fr":
            case "hi":
            case "it":
            case "ja":
            case "ms":
            case "pt":
            case "ru":
            case "tr":
            case "vi":
            case "zh":
            case "zh_cn":
                this.setState({banner_url: `https://www.ifcmarkets.com/${this.props.language}/trading/stocks`});
                break;
            case "ar":
                this.setState({banner_url: "https://www.ifcmarkets.net/trading/stocks"});
                break;
            case "ID":
                this.setState({banner_url: "https://www.ifcmarkets.co.id/trading/stocks"});
                break;
            case "fa":
                this.setState({banner_url: `https://www.ifcmiran.asia/${this.props.language}/trading/stocks`});
                break;
            default:
                this.setState({banner_url: `https://www.ifcmarkets.com/${this.props.language}/trading/stocks`});
        }
    }

    render() {
        const path = window.location.pathname.split('/')[2] ? window.location.pathname.split('/')[2] : ''
        const {cur, cur2, availableCouple, number} = this.props;
        const {t} = this.props;
        const ifc_domain = `$_IFC_DOMAIN`;
        return (
            <div>
                <Helmet>
                    <html lang={this.props.language} />
                    <title>{`${(t( '$_NEW_CURRENCY_CONVERTOR_TITLE', {
                        CUR1:  `${cur}`,
                        CUR2: `${cur2}`,
                        CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                        CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
                        CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                        CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
                        CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                        CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                        CUR1_TRANS: (t(`${cur}`)),
                        CUR2_TRANS: (t(`${cur2}`)),
                        AMOUNT: number
                    } ))} | CFDRATES`}</title>
                    <meta property="og:title" content={`${(t( '$_NEW_CURRENCY_CONVERTOR_TITLE', {
                        CUR1:  `${cur}`,
                        CUR2: `${cur2}`,
                        CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                        CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
                        CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                        CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
                        CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                        CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                        CUR1_TRANS: (t(`${cur}`)),
                        CUR2_TRANS: (t(`${cur2}`)),
                        AMOUNT: number
                    } ))} | CFDRATES`} />
                    <link rel="canonical" href={window.location.href} />
                    <meta name="description" content={`${(t( '$_CURRENCY_CONVERTOR_DESC', {
                        CUR1:  `${cur}`,
                        CUR2: `${cur2}`,
                        CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                        CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
                        CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                        CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
                        CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                        CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                        CUR1_TRANS: (t(`${cur}`)),
                        CUR2_TRANS: (t(`${cur2}`)),
                        AMOUNT: number
                    } ))}`} />
                </Helmet>
                <div className="content">
                 {
                        this.state.lang.map((e, i) => {
                            return  <link  rel="alternate" href={window.location.origin + '/' + e + '/' + path} hrefLang={e}/>
                        })
                 }
                    <div className={`${availableCouple ? "my_wrapper" : ""}`}>
                        <div className="curr_cont">
                            {
                                availableCouple
                                    ?
                                    <div className="one">

                                        <div className="chart_content dir-ltr">
                                            <h2 className="text-center">
                                                {
                                                    renderHtml(t('$_NEW_CURRENCY_STANDART_OTHER', {
                                                        CUR1:  `${cur}`,
                                                        CUR2: `${cur2}`,
                                                        CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                                                        CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
                                                        CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                                                        CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
                                                        CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                                                        CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                                                        CUR1_TRANS: (t(`${cur}`)),
                                                        CUR2_TRANS: (t(`${cur2}`)),
                                                        AMOUNT: number
                                                    }))
                                                }
                                            </h2>
                                            <Chart2 cur={cur} cur2={cur2} availableCouple={availableCouple}/>
                                            <div className="see customBtn d-none d-sm-block">
                                                {renderHtml(t('$_NEW_FOREX_CURRENCIES_LIVE', {
                                                    IFC_DOMAIN: (t(ifc_domain)),
                                                    CUR1_CUR2: availableCouple
                                                }))}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }


                            <div className={`${availableCouple ? "two" : "twos"}`}>
                                <div className="buy_sell">
                                    {
                                        availableCouple ? <BuySell /> : null
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="conversion row">
                        <NewExchangeRate/>
                        <div className={'d-none d-sm-block'}>
                            {
                                (this.props.language !== 'fa')
                                    ?
                                    <div className="advertising">
                                        {/*<div className="ads">*/}
                                        {/*    <a*/}
                                        {/*        // href={`https://www.ifcmarkets.com/${this.props.language}/${(this.props.language==='es')?'webinar-landing':'landing-NY'}?utm_source=cfdrates&utm_medium=banner_${this.props.language}`}*/}
                                        {/*        href={`https://www.ifcmarkets.com/${this.props.language}/universal-program-promo`}*/}
                                        {/*        target="_blank"*/}
                                        {/*        rel="noopener noreferrer"*/}
                                        {/*    >*/}
                                        {/*        <i className="d-none">Universal program promo</i>*/}
                                        {/*        <div loading="lazy" className={`bannerImage up`}/>*/}
                                        {/*    </a>*/}
                                        {/*</div>*/}
                                        <div className="ads">
                                            <a
                                                // href={`https://www.ifcmarkets.com/${this.props.language}/${(this.props.language==='es')?'webinar-landing':'landing-NY'}?utm_source=cfdrates&utm_medium=banner_${this.props.language}`}
                                                href={`https://www.ifcmarkets.com/${this.props.language}/ifcm-invest-landing`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="Boots your profits with IFCM Invest"
                                            >
                                                <i className="d-none">ifcm invest landing</i>
                                                <div loading="lazy" className={`bannerImage invest-banner-EN`}/>
                                            </a>
                                        </div>
                                        {/*<div className="ads">*/}
                                        {/*    <a*/}
                                        {/*        // href={`https://www.ifcmarkets.com/${this.props.language}/${(this.props.language==='es')?'webinar-landing':'landing-NY'}?utm_source=cfdrates&utm_medium=banner_${this.props.language}`}*/}
                                        {/*        href={`https://www.ifcmarkets.com/${this.props.language}/trading/stocks`}*/}
                                        {/*        target="_blank"*/}
                                        {/*        rel="noopener noreferrer"*/}
                                        {/*    >*/}
                                        {/*        /!*<div className={`bannerImage banner_${(this.props.language).toUpperCase()}`}/>*!/*/}
                                        {/*        <div className="bannerImage new_banner"/>*/}
                                        {/*    </a>*/}
                                        {/*</div>*/}
                                    </div>
                                    :
                                    <div className="advertising">
                                        {/*<div className="ads">*/}
                                        {/*    <a*/}
                                        {/*        // href={`https://www.ifcmarkets.com/${this.props.language}/${(this.props.language==='es')?'webinar-landing':'landing-NY'}?utm_source=cfdrates&utm_medium=banner_${this.props.language}`}*/}
                                        {/*        href={`https://www.ifcmiran.asia/${this.props.language}/universal-program-promo`}*/}
                                        {/*        target="_blank"*/}
                                        {/*        rel="noopener noreferrer"*/}
                                        {/*    >*/}
                                        {/*        <i className="d-none">Universal program promo</i>*/}
                                        {/*        <div loading="lazy" className={`bannerImage up-rtl`}/>*/}
                                        {/*    </a>*/}
                                        {/*</div>*/}

                                        <div className="ads">
                                            <a
                                                href={`https://www.ifcmiran.asia/${this.props.language}/ifcm-invest-landing`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label="Boots your profits with IFCM Invest"
                                            >
                                                <i className="d-none">ifcm invest landing</i>
                                                <div loading="lazy"  className={`bannerImage invest-banner-FA`}/>
                                            </a>
                                        </div>
                                    </div>
                            }
                            {
                               // <GoogleAd
                               //    client="ca-pub-7838140187159395"
                               //     slot="7765769871"
                               //     format="auto"
                               //     format="rectangle"
                               //     wrapperDivStyle={style}
                               // />
                            }
                        </div>
                    </div>

                    {
                        (this.props.language === 'fa')? renderHtml(t('MARKET_IS_DAILY',{IFC_DOMAIN: ('https://www.ifcmiran.asia'),})):   renderHtml(t('MARKET_IS_DAILY',{IFC_DOMAIN: (t(ifc_domain)),}))
                    }
                    <ExchangeRatetoday/>
                </div>
                <TopLossLeader/>
            </div>
        )
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        data: state.data,
        availableCouple: state.availableCouple,
        number: state.number,
        language: state.language

    }),
    dispatch => ({
        getExchange: (cur, cur2) => dispatch(getExchange(cur, cur2)),
    })
)(translate('common')(HomePage));
