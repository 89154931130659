import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import {getTopGainLoss, otherCurrancy} from "../../redux/actions/userActions";
import {translate} from "react-i18next";

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props}}>
</span>);

class TopLossLeader extends Component {
    constructor(props){
        super(props);
        this.state = {};
        this.scrolling = this.scrolling.bind(this);
    }
    scrolling(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    componentDidMount() {
        this.props.getTopGainLoss();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !this.props.topGainLoss || (this.props.language !== nextProps.language)
    }


    render() {
        const { t, language, topGainLoss, otherCurrancy, history } = this.props;

        return (
            <div className={'d-none d-sm-block'}>
                <h2 className="top_lose_gain_text">{renderHtml(t('$_FINANCIAL_MARKET_TODAY'))}</h2>
                <div className="losers_leaders lead">
                    <h3 className="top_lose_gain_text"> {renderHtml(t('$_LEADERS_PER_DAY'))}</h3>
                    <div className="data-leaders-cont __top_gainers">
                        {
                            (topGainLoss && topGainLoss.up.length)?
                                topGainLoss.up.slice(5).reverse().map((item,index) => {
                                    return   <div className="one_top_los" key={index}>
                                        <div className="top_type">
                                            <div className="cur__name">
                                                <div
                                                    onClick={()=>{
                                                        otherCurrancy(item.symbol.slice(0,3), item.symbol.slice(3,6));
                                                        history.push(`/${language}/${item.symbol.slice(0,3).toLowerCase()}-${item.symbol.slice(3,6).toLowerCase()} `)
                                                        this.scrolling();
                                                    }}>
                                                    {item.symbol}
                                                </div>
                                            </div>
                                            <div className="cur__type">{item.sub_group_name}</div>
                                        </div>
                                        <div className="bottom_type">
                                            <div className="flag_type">
                                                <span className={`flag_24 ${item.volume_currency}`}/>
                                                <span className={`flag_24 ${item.pipscurrency}`}/>
                                            </div>
                                            <div className="cur_rate">
                                                <span className="arr_rate to_up_sp"/>
                                                {item.percent}%
                                            </div>
                                        </div>
                                    </div>
                                })
                                :
                                [1,2,3,4,5].map((item,index) => {
                                    return   <div className="one_top_los" key={index}>
                                        <div className="bottom_type">
                                            {renderHtml(t('NO_DATA_AVAILABLE'))}
                                        </div>
                                    </div>
                                } )
                        }
                    </div>
                </div>

                <div className="losers_leaders los">
                    <h3 className="top_lose_gain_text">{renderHtml(t('$_LOSERS_PER_DAY'))}</h3>
                    <div className="data-leaders-cont data-leaders-cont-red __top_losers">
                        {
                            (topGainLoss && topGainLoss.down.length)
                                ?
                                topGainLoss.down.slice(0, 5).map((item, index) => {
                                    return <div className="one_top_los" key={index}>
                                        <div className="top_type">
                                            <div className="cur__name">
                                                <div
                                                    onClick={() => {
                                                        otherCurrancy(item.symbol.slice(0, 3), item.symbol.slice(3, 6));
                                                        history.push(`/${language}/${item.symbol.slice(0, 3).toLowerCase()}-${item.symbol.slice(3, 6).toLowerCase()}`)
                                                        this.scrolling();
                                                    }}>
                                                    {item.symbol}
                                                </div>
                                            </div>
                                            <div className="cur__type">{item.sub_group_name}</div>
                                        </div>
                                        <div className="bottom_type">
                                            <div className="flag_type">
                                                <span className={`flag_24 ${item.volume_currency}`}/>
                                                <span className={`flag_24 ${item.pipscurrency}`}/>
                                            </div>
                                            <div className="cur_rate">
                                                <span className="arr_rate to_down_sp"/>
                                                {item.percent}%
                                            </div>
                                        </div>
                                    </div>
                                })
                                :
                                [1, 2, 3, 4, 5].map((item, index) => {
                                    return <div className="one_top_los" key={index}>
                                        <div className="bottom_type">
                                            {renderHtml(t('NO_DATA_AVAILABLE'))}
                                        </div>
                                    </div>
                                })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        language: state.language,
        topGainLoss: state.topGainLoss
    }),
    dispatch => ({
        otherCurrancy: (cur, cur2) => dispatch(otherCurrancy(cur, cur2)),
        getTopGainLoss: () => dispatch(getTopGainLoss())
    })
)(translate('common')(withRouter(TopLossLeader)));
