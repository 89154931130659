import React from 'react';
import {translate} from "react-i18next";
import RegisterForm2 from "./RegisterForm2";
import {connect} from "react-redux";
import HomePageCrypto from "./HomePage/HomePageCrypto";

class DashBoardCrypto extends React.Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.language !== nextProps.language)
    }
    render(){
        return(
            <div>
                <RegisterForm2 />
                <HomePageCrypto/>
            </div>
        )
    }
}
// export default  (translate('common')(DashBoard));
export default connect(
    state => ({
        language: state.language,
    }))((translate('common')(DashBoardCrypto)));