import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import {connect} from "react-redux";


const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);



class DescList extends Component {
    constructor(props){
        super(props);
        this.state= {
            see: "",
            hide: "none",
            slide: "slideNone",
        }
    }

    render() {

        const { t } = this.props;

        return (
            <div>
                <div className="head_text">
                    <h2>{renderHtml(t('$_WORLD_ABBR'))}</h2>
                </div>
                <div className="currency_symbols">
                    {renderHtml(t('$_TABLE_BELOW_TEXT'))}
                    <ul id="__more_ul">
                        {renderHtml(t('$_TABLE_BELOW_FIRST'))}
                        <div className={this.state.slide}>
                            {renderHtml(t('$_TABLE_BELOW_SECOND'))}
                        </div>
                    </ul>
                    <div className="more_less"  onClick={()=> {
                        if(this.state.see === ""){
                            this.setState({see: "none", hide: "", slide: `slide_${this.props.language}`})} else this.setState({see: "", hide: "none", slide: "slideNone"})
                    }}>
                        <div className="line"/>
                        <div className="arrow_down" style={{display: this.state.see}}/>
                        <div className="__types"  style={{display: this.state.see}}>
                            {renderHtml(t('$_SEE_MORE'))}
                        </div>
                        <div className="__types" style={{display: this.state.hide}}>
                            {renderHtml(t('HIDE_CLOSEOPENBOX'))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        language: state.language
    }),
)(withRouter(translate('common')(DescList)));
