import React from 'react';
import WordCurrenciesItem from "./WordCurrenciesItem";
import {translate} from "react-i18next";

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

class WorldCurrenciesAbbreviations extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            infoCurrency: [],
        }
    }

    componentDidMount() {
        fetch(` https://www.ifcm.co.uk:2083/instrument/currencies?all=true`, {
            method: 'get',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then((res) => res.json())
            .then((data) => {
                    this.setState({infoCurrency: data})
                }
            )
            .catch((err)=>console.log(err));
    }


    render(){

        const { t } = this.props;

        if(this.state.name === this.props.searchText.toUpperCase()){
            setTimeout(
                function() {
                    var elm = document.getElementById("red");
                    elm.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                }
                    .bind(this),
                10
            );
        }
        return(

            <div>
                <table id="equities_table" className="" data-currenciesgroup="2">
                    <thead>
                    <tr>
                        <td className="col-sort">
                            {renderHtml(t('NAME'))}
                        </td>
                        <td className="col-sort">
                            {renderHtml(t('ALPHABETICCODE'))}
                        </td>
                        <td className="col-sort">
                            {renderHtml(t('ISOCODE'))}
                        </td>
                        <td>
                            {renderHtml(t('CURRENCYSYMBOL'))}
                        </td>
                        <td>
                            {renderHtml(t('CALLBACK_NAME'))}
                        </td>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        this.state.infoCurrency.map((item,index) => {
                            return <WordCurrenciesItem  name = {item.name}
                                                          abbreviation = {item.name}
                                                          code = {item.iso_code}
                                                          symbol = {item.symbol}
                                                          index = {index}
                                                          key = {index}
                                                          searchText = {this.props.searchText}
                            />
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}
export default (translate('common')(WorldCurrenciesAbbreviations))