import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from 'react-i18next';
import {changeCurencyCrypto, getExchangeRateTodayRateCrypto} from "../../redux/actions/userActions";
import {withRouter} from "react-router-dom";

const renderHtml = (props) => (<h2
    dangerouslySetInnerHTML={{__html: props}}>
</h2>);


class ExchangeRatetodayCrypto extends Component {
    constructor(props) {
        super(props);
        this.scrolling = this.scrolling.bind(this);
    };


    scrolling() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };



    componentDidMount() {
        this.props.getExchangeRateTodayRateCrypto(this.props.curCrypto,this.props.curCrypto2)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.curCrypto !== this.props.curCrypto) || (prevProps.curCrypto2 !== this.props.curCrypto2) || (prevProps.language !== this.props.language)) {
            this.props.getExchangeRateTodayRateCrypto(this.props.curCrypto, this.props.curCrypto2);
        }
    }

    render() {
        const {t, curCrypto, curCrypto2, language, exchangeRateTodayRateCrypto} = this.props;

        console.log(curCrypto,curCrypto2);

        const myCur_first = `${curCrypto}`;
        const myCur_second = `${curCrypto2}`;
        const myCur_first_plural = `${curCrypto}_PLURAL`;
        const myCur_second_plural = `${curCrypto2}_PLURAL`;
        const myCur_first_dative = `${curCrypto}_DATIVE`;
        const myCur_second_dative = `${curCrypto2}_DATIVE`;
        const myCur_first_multiple = `${curCrypto}_MULTIPLE`;
        const myCur_second_multiple = `${curCrypto2}_MULTIPLE`;

        return (
            <div className="rates_table">
                <div className="rates_today">
                    <div className="left_rate">
                        {
                            renderHtml(t('$_CRYPTO_RATE', {
                                CUR1: myCur_first,
                                CUR2: myCur_second,
                                CUR1_PLURAL: (t(myCur_first_plural)),
                                CUR2_PLURAL: (t(myCur_second_plural)),
                                CUR1_DATIVE: (t(myCur_first_dative)),
                                CUR2_DATIVE: (t(myCur_second_dative)),
                                CUR1_MULTIPLE: (t(myCur_first_multiple)),
                                CUR2_MULTIPLE: (t(myCur_second_multiple)),
                                CUR1_TRANS: (t(myCur_first)),
                                CUR2_TRANS: (t(myCur_second)),
                            }))
                        }
                        <div className="fulllist">
                            {
                                exchangeRateTodayRateCrypto && exchangeRateTodayRateCrypto.auxiliary.map((item, index) => {
                                    return (item.cur !== curCrypto)?<div className="all_rates" key={index}>
                                        <div className="exchange-rate"
                                             onClick={() => {
                                                 this.props.onCurrencyChangedCrypto(this.props.curCrypto, item.cur);
                                                 this.scrolling();
                                                 this.props.history.push(`/${language}/crypto-converter/${curCrypto.toLowerCase()}-${item.cur.toLowerCase()}`)
                                             }
                                             }>
                                            <span className={`flag_24 ${curCrypto}`}/>
                                            <span>1</span>
                                            <span className="__CAD __rate">{curCrypto}</span>
                                        </div>
                                        <div className="equal-symbol">{` = `}</div>
                                        <div className="exchange-rate-right">
                                            <div>
                                                <span className={`flag_24 ${item.cur || item}`}/>
                                                {
                                                    (curCrypto===item.cur)?<span className="__CAD __rate">1</span>: <span className="__CAD __rate">{item.rate || "---"}</span>
                                                }
                                                <span>{item.cur || item}</span>
                                            </div>
                                        </div>
                                    </div>:null
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        curCrypto: state.curCrypto,
        curCrypto2: state.curCrypto2,
        numberCrypto: state.numberCrypto,
        language: state.language,
        exchangeRateTodayRateCrypto: state.exchangeRateTodayRateCrypto
    }),
    dispatch => ({
        onCurrencyChangedCrypto: (curCrypto, curCrypto2) => dispatch(changeCurencyCrypto(curCrypto, curCrypto2)),
        getExchangeRateTodayRateCrypto: (cur, cur2) => dispatch(getExchangeRateTodayRateCrypto(cur, cur2)),
    })
)(translate('common')(withRouter(ExchangeRatetodayCrypto)))