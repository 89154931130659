import {Helmet} from "react-helmet";
import React from "react";
import { withRouter,} from "react-router-dom";
import { translate } from 'react-i18next';

import {openMobileMenu} from "../redux/actions/userActions"
import { connect } from "react-redux";
import {onLanguageChange} from "../redux/actions/userActions";

class LangMenu extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
            cur: 0,
            show: "none",
            show2: "none",
            // common: "en",
            data: [
                {
                    name: "United Kingdom",
                    lang: "flag_24 en",
                    href: "https://cfdrates.com/en",
                    common: "en"
                },
                {
                    name: "Russia",
                    lang: "flag_24 ru",
                    href: "https://cfdrates.com/ru",
                    common: "ru"
                },
                {
                    name: "Spain",
                    lang: "flag_24 es",
                    href: "https://cfdrates.com/es",
                    common: "es"
                },
                {
                    name: "Portugal",
                    lang: "flag_24 pt",
                    href: "https://cfdrates.com/pt",
                    common: "pt"
                },
                {
                    name: "Saudi Arabia",
                    lang: "flag_24 ar",
                    href: "https://cfdrates.com/ar",
                    common: "ar"
                },
                {
                    name: "Turkey",
                    lang: "flag_24 tr",
                    href: "https://cfdrates.com/tr",
                    common: "tr"
                },
                {
                    name: "Iran",
                    lang: "flag_24 fa",
                    href: "https://cfdrates.com/fa",
                    common: "fa"
                },
                {
                    name: "Vietnam",
                    lang: "flag_24 vi",
                    href: "https://cfdrates.com/vi",
                    common: "vi"
                },
                {
                    name: "Japan",
                    lang: "flag_24 ja",
                    href: "https://cfdrates.com/ja",
                    common: "ja"
                },
                {
                    name: "Taiwan",
                    lang: "flag_24 zh",
                    href: "https://cfdrates.com/zh",
                    common: "zh"
                },
                {
                    name: "China",
                    lang: "flag_24 zh_cn",
                    href: "https://cfdrates.com/zh_cn",
                    common: "zh_cn"
                },
                {
                    name: "Indonesia",
                    lang: "flag_24 ID",
                    href: "https://cfdrates.com/ID",
                    common: "ID"
                },
                {
                    name: "France",
                    lang: "flag_24 fr",
                    href: "https://cfdrates.com/fr",
                    common: "fr"
                },
                {
                    name: "Czech Republic",
                    lang: "flag_24 cs",
                    href: "https://cfdrates.com/cs",
                    common: "cs"
                },
                {
                    name: "India",
                    lang: "flag_24 hi",
                    href: "https://cfdrates.com/hi",
                    common: "hi"
                },
                {
                    name: "Italy",
                    lang: "flag_24 it",
                    href: "https://cfdrates.com/it",
                    common: "it"
                },
                {
                    name: "Malaysia",
                    lang: "flag_24 ms",
                    href: "https://cfdrates.com/ms",
                    common: "ms"
                }
            ]

        };
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    getOpenNewTabPathName = () => {
        const {pathname} = this.props.location;
        if(pathname.length <= 5){
            return ""
        }
        let newPathName = "";
        pathname.split("/").map((path, i) => {
            if(i>2) newPathName += "/";
            if(i === 1) {
                // newPathName += common
            } else {
                newPathName +=path;
            }
        });

        return newPathName;
    };


    showMenu(event) {
        event.preventDefault();
        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu() {
        this.setState({ showMenu: false }, () => {
            document.removeEventListener('click', this.closeMenu);
        });
    }

    componentDidMount() {
        this.hydrateStateWithLocalStorage();
        this.checkIfLangChanged();
    }

    checkIfLangChanged(){
        if(this.props.location.pathname !== "/"){
            const pathArray = this.props.location.pathname.split("/");
            const language = pathArray[1];
            if (language !== this.props.language){
                this.props.i18n.changeLanguage(language);
                document.body.dir = `${(language === 'ar' || language === 'fa') ? 'rtl' : "ltr"}` ;
                this.setState({cur: 0, common: language});
                // localStorage.setItem (index, index);
                localStorage.setItem("cur", JSON.stringify(0));
                localStorage.setItem("language", language)
                this.props.onLanguageChange(language)
            }
        }
    }

    hydrateStateWithLocalStorage() {
        // for all items in state
        for (let key in this.state) {
            // if the key exists in localStorage
            if (localStorage.hasOwnProperty(key)) {
                // get the key's value from localStorage
                let value = localStorage.getItem(key);
                // parse the localStorage string and setState
                try {
                    value = JSON.parse(value);
                    this.setState({ [key]: value });
                } catch (e) {
                    // handle empty string
                    this.setState({ [key]: value });
                }
            }
        }
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.state.showMenu !== nextState.showMenu) || (this.state.cur !== nextState.cur) || (this.state.show !== nextState.show) || (this.state.show2 !== nextState.show2)
    }

    render(){
        const { i18n, location: {pathname}, language} = this.props;
        this.checkIfLangChanged();
        // const ifc_domain = `$_IFC_DOMAIN`;

        return (
            <li className="flag" onClick={this.showMenu} >
                <span className={`flag_24 ${language}`}/>
                <span className="flag_text">{language}</span>
                <span  className="flag_arrow"/>

                {
                    this.state.showMenu ? (
                        <div className="flag_none" style={{display: "block"}} >
                            {
                                this.state.data.map((item,index)=>{
                                    return   <div className=" " key={index}   onClick={() => {
                                        this.props.onLanguageChange(item.common);
                                        const pathsArray = pathname.split("/");
                                        let newPathName = "";
                                        pathsArray.forEach((e, i) => {
                                            newPathName += "/";
                                            if(i === 1){
                                                newPathName += item.common
                                            } else {
                                                newPathName += e;
                                            }
                                        });
                                        i18n.changeLanguage(item.common);
                                        document.body.dir = `${(item.common === 'ar' || item.common === 'fa') ?'rtl' : "ltr"}` ;
                                        this.setState({cur: index, common: item.common});
                                        // localStorage.setItem (index, index);
                                        localStorage.setItem("cur", JSON.stringify(index));
                                        localStorage.setItem("language",item.common);
                                        // localStorage.setItem("common", JSON.stringify(index));
                                    }
                                    }>
                                        <a href={`${item.href}/${this.getOpenNewTabPathName(item.common)}`}
                                           className="local_href"
                                        >
                                            <span className={item.lang}/>
                                            {item.name}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    ):null
                }

            </li>
        );
    }
}

export default connect(
    state => ({
        language: state.language
    }),
    dispatch => ({
        openMobileMenu: () => dispatch(openMobileMenu()),
        onLanguageChange: (language) => dispatch(onLanguageChange(language))
    })
)(translate('common')(withRouter(LangMenu)))
