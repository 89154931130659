import React from "react";
import {
    MAIN_ROUTE,
    CALCULATOR_ROUTE_MAKER,
    CURRENCY_ROUTE_MAKER
} from "./../helpers/constants";
import {translate} from "react-i18next";

import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {onChangeActiveIndex} from "../redux/actions/userActions";

let dt = new Date();
const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);


class Footer extends React.Component  {

    scrolling(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.language !== nextProps.language)
    }

    render(){
        const { t, language, changedTab } = this.props;

        return (
            <footer className="footer">
                <div className="footer_black">
                    <div className="social">
                        <div className="riskw_footer">
                            <div className="m_foo">
                                <div className="foo_menu">
                                    <ul>
                                        <li onClick={()=>{this.scrolling(); changedTab(0)}}>
                                            <Link to={MAIN_ROUTE}>
                                                {renderHtml(t('CURRENCY_CONVERTER'))}
                                            </Link>
                                        </li>
                                        <li onClick={()=>{this.scrolling(); changedTab(1)}}>
                                            <Link to={CALCULATOR_ROUTE_MAKER(language)}>
                                                {renderHtml(t('$_TRADING_CALCULATORS'))}
                                            </Link>
                                        </li>
                                        <li onClick={()=>{this.scrolling(); changedTab(2)}}>
                                            <Link to={CURRENCY_ROUTE_MAKER(language)}>
                                                {renderHtml(t('SECTOR_CURRENCIES'))}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact_us">
                                    {renderHtml(t('$_CONTACT_US'))}
                                </div>
                                <form>
                                    <div className="inputs">
                                        <input type="text"
                                               placeholder={`${t('CALLBACK_NAME')}*`}
                                               name="name"/>
                                        <input type="email"
                                               placeholder={`${t('$_EMAIL_ADDRESS')}*`}
                                               name="email"/>
                                    </div>
                                    <div className="area">
                                        <textarea name="message"
                                                  placeholder={`${t('$_MESSAGE')}*`}
                                        />
                                    </div>
                                    <div className="send">
                                        <input type="submit"
                                               className="button_gray btn-md"
                                               value={(t('EMAIL_SEND'))}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">© {dt.getFullYear()} CFDRATES</div>
            </footer>
        )
    }
}
export default connect(
    state => ({
        language: state.language
    }),
    dispatch => ({
        changedTab: (activeIndex) => dispatch(onChangeActiveIndex(activeIndex))
    })
)(withRouter(translate('common')(Footer)));