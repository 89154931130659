import {Helmet} from "react-helmet";
import React, {Component} from "react";
import Chart2 from "../chart/Chart2";
import {connect} from "react-redux";
import {getExchangeCrypto} from "../../redux/actions/userActions";
import {translate} from "react-i18next";
import BuySellCrypto from "./BuySellCrypto";
import ExchangeRatetodayCrypto from "./ExchangeRatetodayCrypto";
import TopCryptoPairs from "./TopCryptoPairs";
import Volatility from "./Volatility";

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</span>);

const renderHtmlDiv = (props) => (<div
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</div>);

class HomePageCrypto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phrases: "",
            chart_text: "",
            quotes_text: "",
            banner_url: `https://www.ifcmarkets.com/${this.props.language}/trading/stocks`,
            lang: ["en", "cs", "fr", "hi", "it", "ja", "ms", "pt", "ru", "tr", "vi", "zh", "zh_cn", "ar", "ID", "fa"]
        };

    }

    componentDidMount() {
        document.body.dir = `${(this.props.language === 'ar' || this.props.language === 'fa') ? 'rtl' : "ltr"}`;
        this.props.getExchangeCrypto(this.props.curCrypto, this.props.curCrypto2);
        this.getCurrectLink()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language){
            this.getCurrectLink()
        }
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.curCrypto !== nextProps.curCrypto) || (this.props.curCrypto2 !== nextProps.curCrypto2) || (this.props.language !== nextProps.language)
    }

    getCurrectLink = () => {
        switch (this.props.language) {
            case "en":
            case "cs":
            case "fr":
            case "hi":
            case "it":
            case "ja":
            case "ms":
            case "pt":
            case "ru":
            case "tr":
            case "vi":
            case "zh":
            case "zh_cn":
                this.setState({banner_url: `https://www.ifcmarkets.com/${this.props.language}/trading/stocks`});
                break;
            case "ar":
                this.setState({banner_url: "https://www.ifcmarkets.net/trading/stocks"});
                break;
            case "ID":
                this.setState({banner_url: "https://www.ifcmarkets.co.id/trading/stocks"});
                break;
            case "fa":
                this.setState({banner_url: `https://www.ifcmiran.asia/${this.props.language}trading/stocks`});
                break;
            default:
                this.setState({banner_url: `https://www.ifcmarkets.com/${this.props.language}/trading/stocks`});
        }
    }

    render() {
        const path = window.location.pathname.split('/')[2] ? window.location.pathname.split('/')[2] : ''
        const {curCrypto, curCrypto2, availableCoupleCrypto, numberCrypto} = this.props;
        const {t} = this.props;
        const ifc_domain = `$_IFC_DOMAIN`;
        return (
            <div>
                <Helmet>
                    <html lang={this.props.language} />
                    <title>{`${(t( '$_CRYPTO_TITLE', {
                        CUR1:  `${curCrypto}`,
                        CUR2: `${curCrypto2}`,
                        CUR1_PLURAL: (t(`${curCrypto}_PLURAL`)),
                        CUR2_PLURAL: (t(`${curCrypto2}_PLURAL`)),
                        CUR1_DATIVE: (t(`${curCrypto}_DATIVE`)),
                        CUR2_DATIVE: (t(`${curCrypto2}_DATIVE`)),
                        CUR1_MULTIPLE: (t(`${curCrypto}_MULTIPLE`)),
                        CUR2_MULTIPLE: (t(`${curCrypto2}_MULTIPLE`)),
                        CUR1_TRANS: (t(`${curCrypto}`)),
                        CUR2_TRANS: (t(`${curCrypto2}`)),
                        AMOUNT: numberCrypto
                    } ))} | CFDRATES`}</title>
                    <meta property="og:title" content={`${(t( '$_CRYPTO_TITLE', {
                        CUR1:  `${curCrypto}`,
                        CUR2: `${curCrypto2}`,
                        CUR1_PLURAL: (t(`${curCrypto}_PLURAL`)),
                        CUR2_PLURAL: (t(`${curCrypto2}_PLURAL`)),
                        CUR1_DATIVE: (t(`${curCrypto}_DATIVE`)),
                        CUR2_DATIVE: (t(`${curCrypto2}_DATIVE`)),
                        CUR1_MULTIPLE: (t(`${curCrypto}_MULTIPLE`)),
                        CUR2_MULTIPLE: (t(`${curCrypto2}_MULTIPLE`)),
                        CUR1_TRANS: (t(`${curCrypto}`)),
                        CUR2_TRANS: (t(`${curCrypto2}`)),
                        AMOUNT: numberCrypto
                    } ))} | CFDRATES`} />
                    <link rel="canonical" href={window.location.href} />
                    <meta name="description" content={`${(t( '$CRYPTO_DESCRIPTION', {
                        CUR1:  `${curCrypto}`,
                        CUR2: `${curCrypto2}`,
                        CUR1_PLURAL: (t(`${curCrypto}_PLURAL`)),
                        CUR2_PLURAL: (t(`${curCrypto2}_PLURAL`)),
                        CUR1_DATIVE: (t(`${curCrypto}_DATIVE`)),
                        CUR2_DATIVE: (t(`${curCrypto2}_DATIVE`)),
                        CUR1_MULTIPLE: (t(`${curCrypto}_MULTIPLE`)),
                        CUR2_MULTIPLE: (t(`${curCrypto2}_MULTIPLE`)),
                        CUR1_TRANS: (t(`${curCrypto}`)),
                        CUR2_TRANS: (t(`${curCrypto2}`)),
                        AMOUNT: numberCrypto
                    } ))}`} />
                </Helmet>
                <div className="content">
                 {
                        this.state.lang.map((e, i) => {
                            return  <link  rel="alternate" href={window.location.origin + '/' + e + '/' + path} hrefLang={e}/>
                        })
                 }
                    <div className={`${availableCoupleCrypto ? "my_wrapper" : ""}`}>
                        <div className="curr_cont">
                            {
                                availableCoupleCrypto
                                    ?
                                    <div className="one">
                                        <div className="chart_content dir-ltr">
                                            <h2 className="text-center">
                                                {
                                                    renderHtml(t('$_CHART_H2', {
                                                        CUR1:  `${curCrypto}`,
                                                        CUR2: `${curCrypto2}`,
                                                        CUR1_PLURAL: (t(`${curCrypto}_PLURAL`)),
                                                        CUR2_PLURAL: (t(`${curCrypto2}_PLURAL`)),
                                                        CUR1_DATIVE: (t(`${curCrypto}_DATIVE`)),
                                                        CUR2_DATIVE: (t(`${curCrypto2}_DATIVE`)),
                                                        CUR1_MULTIPLE: (t(`${curCrypto}_MULTIPLE`)),
                                                        CUR2_MULTIPLE: (t(`${curCrypto2}_MULTIPLE`)),
                                                        CUR1_TRANS: (t(`${curCrypto}`)),
                                                        CUR2_TRANS: (t(`${curCrypto2}`)),
                                                        AMOUNT: numberCrypto
                                                    }))
                                                }
                                            </h2>
                                            <Chart2 cur={curCrypto} cur2={curCrypto2} availableCouple={availableCoupleCrypto}/>
                                            <div className="see customBtn mb30 d-none d-sm-block">
                                                {renderHtml(t('$_SEE_OTHER_BUTTON', {
                                                    IFC_DOMAIN: (t(ifc_domain)),
                                                    CUR1_CUR2: availableCoupleCrypto
                                                }))}
                                            </div>
                                            <p className="mb30">
                                                {
                                                    renderHtmlDiv(t('$_TEXT2', {
                                                        CUR1: `${curCrypto}`,
                                                        CUR2: `${curCrypto2}`,
                                                        CUR1_PLURAL: (t(`${curCrypto}_PLURAL`)),
                                                        CUR2_PLURAL: (t(`${curCrypto2}_PLURAL`)),
                                                        CUR1_DATIVE: (t(`${curCrypto}_DATIVE`)),
                                                        CUR2_DATIVE: (t(`${curCrypto2}_DATIVE`)),
                                                        CUR1_MULTIPLE: (t(`${curCrypto}_MULTIPLE`)),
                                                        CUR2_MULTIPLE: (t(`${curCrypto2}_MULTIPLE`)),
                                                        CUR1_TRANS: (t(`${curCrypto}`)),
                                                        CUR2_TRANS: (t(`${curCrypto2}`)),
                                                        AMOUNT: numberCrypto
                                                    }))
                                                }
                                            </p>
                                            <div className="mini_text">
                        <span className={`flag_24 ${curCrypto}`} title= {
                            renderHtml(t('EXCHANGE_RATE_TITLE', {
                                CUR1: `${curCrypto}`,
                                CUR1_PLURAL: (t(`${curCrypto}_PLURAL`)),
                                CUR1_DATIVE: (t(`${curCrypto}_DATIVE`)),
                                CUR1_MULTIPLE: (t(`${curCrypto}_MULTIPLE`)),
                                CUR1_TRANS: (t(`${curCrypto}`)),
                            })).props.dangerouslySetInnerHTML.__html
                        }/>
                                                {renderHtmlDiv(t(`${curCrypto}_F_PROFILE`))}
                                            </div>
                                            <div className="mini_text">
                        <span className={`flag_24 ${curCrypto2}`} title={
                            renderHtml(t('EXCHANGE_RATE_TITLE', {
                                CUR1: `${curCrypto2}`,
                                CUR1_PLURAL: (t(`${curCrypto2}_PLURAL`)),
                                CUR1_DATIVE: (t(`${curCrypto2}_DATIVE`)),
                                CUR1_MULTIPLE: (t(`${curCrypto2}_MULTIPLE`)),
                                CUR1_TRANS: (t(`${curCrypto2}`)),
                            })).props.dangerouslySetInnerHTML.__html
                        }/>
                                                <div>
                                                    {renderHtmlDiv(t(`${curCrypto2}_F_PROFILE`))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }


                            <div className={`${availableCoupleCrypto ? "two" : "twos"}`}>
                                <div className="buy_sell">
                                    {
                                        availableCoupleCrypto ? <BuySellCrypto /> : null
                                    }
                                </div>
                                {
                                    availableCoupleCrypto
                                        ?
                                        <div>
                                            <div className="click_div ga__start_trading_cfd">
                                                <a id="trading_conditions_click" className="btnn btn-md button_outline_blue"
                                                   href={`${(t(ifc_domain))}/trading-conditions/crypto/${availableCoupleCrypto.toLowerCase()}`}
                                                >
                                                    {renderHtml(t('TRADING_CONDITION', {CUR1: curCrypto, CUR2: curCrypto2}))}
                                                </a>
                                            </div>
                                            <div className="click_div">
                                                {
                                                    this.props.language === 'fa'
                                                        ? <a
                                                            target="_blank"
                                                            href={`https://www.ifcmiran.asia/${this.props.language}/open-demo-account`}
                                                            rel="noopener noreferrer"
                                                        >
                                                            <button
                                                                className="btnn btn-lg button_green"
                                                                type={"submit"}
                                                                style={{width: '100%'}}
                                                            >
                                                                {t('REG_BANNER_HEADER')}
                                                            </button>
                                                        </a>
                                                        : <a
                                                            target="_blank"
                                                            href={`${t('REGISTER_NOW_LANG')}`}
                                                            rel="noopener noreferrer"
                                                        >
                                                            <button
                                                                className="btnn btn-lg button_green"
                                                                type={"submit"}
                                                                style={{width: '100%'}}
                                                            >
                                                                {t('REG_BANNER_HEADER')}
                                                            </button>
                                                        </a>
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>

                        </div>

                    </div>
                    {
                         (this.props.language === 'fa')? renderHtml(t('MARKET_IS_DAILY',{IFC_DOMAIN: ('https://www.ifcmiran.asia'),})):   renderHtml(t('MARKET_IS_DAILY',{IFC_DOMAIN: (t(ifc_domain)),}))
                    }
                    <ExchangeRatetodayCrypto/>
                    <h2>{renderHtml(t('$_TOP_CRYPTOS'))}</h2>
                    <TopCryptoPairs/>
                    <Volatility/>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        curCrypto: state.curCrypto,
        curCrypto2: state.curCrypto2,
        data: state.data,
        availableCoupleCrypto: state.availableCoupleCrypto,
        numberCrypto: state.numberCrypto,
        language: state.language

    }),
    dispatch => ({
        getExchangeCrypto: (curCrypto, curCrypto2) => dispatch(getExchangeCrypto(curCrypto, curCrypto2)),
    })
)(translate('common')(HomePageCrypto));
