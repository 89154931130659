import React, {Component} from "react";
// import {webs} from "../../Socket";
import {connect} from "react-redux";
import {translate} from 'react-i18next';
import {changeCurency, changeNumber} from "../../redux/actions/userActions";
import {Link, withRouter} from "react-router-dom";

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props}}>
</span>);
const renderHtml_div = (props) => (<div
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</div>);

const renderHtml_2 = (props) => (<div
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</div>);
const numbers = [1, 10, 25, 50, 100, 150, 200, 250, 500, 1000, 5000, 10000, 50000];


class NewExchangeRate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auxiliary: ["AUD", "CAD", "CHF", "CNH", "CZK", "DKK", "EUR", "GBP", "HKD", "JPY",
                "MXN", "NOK", "NZD", "PLN", "RUB", "SEK", "SGD", "TRY", "USD", "ZAR", "BRL"],
            rate: "",
            invert: "",
            $_amount_h2: ""
        };
        this.scrolling = this.scrolling.bind(this);
    };


    scrolling() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (!this.props.exchangeRateTodayRate || (this.props.language !== nextProps.language))
    }

    render() {
        const {t, cur, cur2, language, onNumberChanged, history, onCurrencyChanged, exchangeRateTodayRate, number} = this.props;

        const transObj = {
            CUR1: `${cur}`,
            CUR2: `${cur2}`,
            CUR1_PLURAL: (t(`${cur}_PLURAL`)),
            CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
            CUR1_DATIVE: (t(`${cur}_DATIVE`)),
            CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
            CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
            CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
            CUR1_TRANS: (t(`${cur}`)),
            CUR2_TRANS: (t(`${cur2}`)),
            AMOUNT: number
        };


        return (
            <div className="three ">

                    {/*<p className="mb30">*/}
                    {/*    {*/}
                    {/*        renderHtml_div(t('TOU_CAN_CONVERT', {*/}
                    {/*            CUR1: `${cur}`,*/}
                    {/*            CUR2: `${cur2}`,*/}
                    {/*            CUR1_PLURAL: (t(`${cur}_PLURAL`)),*/}
                    {/*            CUR2_PLURAL: (t(`${cur2}_PLURAL`)),*/}
                    {/*            CUR1_DATIVE: (t(`${cur}_DATIVE`)),*/}
                    {/*            CUR2_DATIVE: (t(`${cur2}_DATIVE`)),*/}
                    {/*            CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),*/}
                    {/*            CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),*/}
                    {/*            CUR1_TRANS: (t(`${cur}`)),*/}
                    {/*            CUR2_TRANS: (t(`${cur2}`)),*/}
                    {/*            AMOUNT: number*/}
                    {/*        }))*/}
                    {/*    }*/}
                    {/*</p>*/}
                <div className="rates_today">
                    <div className="right_rate">
                        <h2>
                            {
                                renderHtml(t('$_CURRENCY_STANGART', transObj))
                            }
                        </h2>
                        <div className="vs_cures">
                            <div className="cur1_vs_cur2" role="presentation">
                                <div className="some_values head_name">
                                    <span className="cur1">{cur}</span>
                                    <span/>
                                    <span className="cur2">{cur2}</span>
                                </div>
                                {
                                    exchangeRateTodayRate && exchangeRateTodayRate.rate && numbers.map((item, index) => {
                                        return <div className="some_values" key={index}>
                                                    <span className="exchange-rate"
                                                          onClick={() => {
                                                              this.scrolling();
                                                              onNumberChanged(item);
                                                              history.push(`/${language}/${cur.toLowerCase()}-${cur2.toLowerCase()}${item === 1 ? '' : '/' + item}`)
                                                          }
                                                          }
                                                    >
                                                    {item} &nbsp; <span>{cur}</span>
                                                    </span>
                                            <span className="horizontal_line">=</span>
                                            <span className="cur2 __sums_block" revers="false">
                                                    <span className="__sum">
                                                        {
                                                            ((item * exchangeRateTodayRate.rate) === 0.00)
                                                                ?
                                                                "---"
                                                                :
                                                                `${((item * exchangeRateTodayRate.rate).toFixed(2))} ${cur2}`
                                                        }
                                                    </span>
                                                </span>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="cur1_vs_cur2" role="presentation">
                                <div>
                                    <div className="some_values head_name">
                                        <span className="cur1">{cur2}</span>
                                        <span/>
                                        <span className="cur2">{cur}</span>
                                    </div>
                                    {
                                        exchangeRateTodayRate && exchangeRateTodayRate.rate && numbers.map((item, index) => {
                                            return <div className="some_values" key={index}>
                                                <span className="exchange-rate"

                                                      onClick={() => {
                                                          this.scrolling();
                                                          onCurrencyChanged(this.props.cur2, this.props.cur);
                                                          onNumberChanged(item)
                                                          history.push(`/${language}/${cur2.toLowerCase()}-${cur.toLowerCase()}${item === 1 ? '' : '/' + item}`)
                                                      }
                                                      }

                                                >
                                                    {item} &nbsp; <span>{cur2}</span>
                                                </span>
                                                <span className="horizontal_line">=</span>
                                                <span className="cur2 __sums_block" revers="false">
                                                <span className="__sum">
                                                    {
                                                        ((item * (1 / exchangeRateTodayRate.rate)) === Infinity) ? "---" :
                                                            <div>{(item * (1 / exchangeRateTodayRate.rate)).toFixed(2)}
                                                                <span>{cur}</span>
                                                            </div>
                                                    }
                                                </span>
                                            </span>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cur_mini_text">
                    <div className="mini_text">
                        <span className={`flag_24 ${cur}`} title= {
                            renderHtml(t('EXCHANGE_RATE_TITLE', {
                                CUR1: `${cur}`,
                                CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                                CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                                CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                                CUR1_TRANS: (t(`${cur}`)),
                            })).props.dangerouslySetInnerHTML.__html
                        }/>
                        {renderHtml_2(t(`${cur}_F_PROFILE`))}
                    </div>
                    <div className="mini_text">
                        <span className={`flag_24 ${cur2}`} title={
                            renderHtml(t('EXCHANGE_RATE_TITLE', {
                                CUR1: `${cur2}`,
                                CUR1_PLURAL: (t(`${cur2}_PLURAL`)),
                                CUR1_DATIVE: (t(`${cur2}_DATIVE`)),
                                CUR1_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                                CUR1_TRANS: (t(`${cur2}`)),
                            })).props.dangerouslySetInnerHTML.__html
                        }/>
                        <div>
                            {renderHtml_2(t(`${cur2}_F_PROFILE`))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        number: state.number,
        language: state.language,
        exchangeRateTodayRate: state.exchangeRateTodayRate
    }),
    dispatch => ({
        onCurrencyChanged: (cur, cur2) => dispatch(changeCurency(cur, cur2)),
        onNumberChanged: (item) => dispatch(changeNumber(item)),
    })
)(translate('common')(withRouter(NewExchangeRate)))