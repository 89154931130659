import React from "react";
import {connect} from "react-redux";
import {changeCurency, currencyConverte, getInitialCursData, numberValue} from "../redux/actions/userActions"
import {withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import {translate} from "react-i18next";
import SelectComp from "../components/SelectComp";

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

const renderHtml_div = (props) => (<div className="p2"
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "")}}>
</div>);

const renderHtml_2 = (props) => (<h1 className="h1_title"
    dangerouslySetInnerHTML={{__html: props}}>
</h1>);


class DemoMiniHomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rate: ""
        }
    }

    handleTextChanged = (cur, cur2) => {
        this.props.onCurrencyChanged(cur, cur2)
        this.props.currencyConverte(cur, cur2)
    }

    componentDidMount() {
        this.props.getInitialCursData(this.props.cur, this.props.cur2);

        const currenciesArrayFromHistory = this.props.match.params.curencies && this.props.match.params.curencies.split("-");
        const currenciesAmount = this.props.match.params.amount;
        if (currenciesAmount) {
            this.props.onInitialNumberValue(currenciesAmount)
        }
        if (currenciesArrayFromHistory &&  currenciesArrayFromHistory[0] !== undefined &&  currenciesArrayFromHistory[1] !== undefined) {
            this.props.onCurrencyChanged(currenciesArrayFromHistory[0].toUpperCase(), currenciesArrayFromHistory[1].toUpperCase(), true);
        } else {
            this.props.onCurrencyChanged(this.props.cur, this.props.cur2, true);

        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.language !== nextProps.language)
            || (this.props.cur !== nextProps.cur)
            || (this.props.cur2 !== nextProps.cur2)
            || (this.props.rate !== nextProps.rate)
            || (this.props.number !== nextProps.number)
    }

    render() {

        const {activeOption1, activeOption2} = this.state;
        const {cur, cur2, changedAmount, onCurrencyChanged, rate, number} = this.props;
        const {t} = this.props;

        const myCur_first = `${cur}`;
        const myCur_second = `${cur2}`;
        const myCur_first_plural = `${cur}_PLURAL`;
        const myCur_second_plural = `${cur2}_PLURAL`;
        const myCur_first_dative = `${cur}_DATIVE`;
        const myCur_second_dative = `${cur2}_DATIVE`;
        const myCur_first_multiple = `${cur}_MULTIPLE`;
        const myCur_second_multiple = `${cur2}_MULTIPLE`;
        const myCur_first_trans = `${cur}_TRANS`;
        const myCur_second_trans = `${cur2}_TRANS`;
        const ifc_domain = `$_IFC_DOMAIN`;

        return (
            <>
                {/*<Helmet>*/}
                {/*    <title>*/}
                {/*        {`${this.props.number} ${this.props.cur} to ${this.props.cur2} | ${this.props.cur} to ${this.props.cur2} Calculator | ${this.props.cur} ${this.props.cur2} Conversion | CFDRATES`}*/}
                {/*    </title>*/}
                {/*    <meta name="description" content="Todos on steroid!" />*/}
                {/*    <meta name="theme-color" content="#008f68" />*/}
                {/*</Helmet>*/}
                <div className="currency_box">

                    <div className="left_box">
                        <div className="left_text">
                            {renderHtml_2(t('$_CURRENCY_CONVERTER', {
                                    AMOUNT: number,
                                    CUR1: myCur_first,
                                    CUR2: myCur_second,
                                    CUR1_PLURAL: (t(myCur_first_plural)),
                                    CUR2_PLURAL: (t(myCur_second_plural)),
                                    CUR1_DATIVE: (t(myCur_first_dative)),
                                    CUR2_DATIVE: (t(myCur_second_dative)),
                                    CUR1_MULTIPLE: (t(myCur_first_multiple)),
                                    CUR2_MULTIPLE: (t(myCur_second_multiple)),
                                    CUR1_TRANS: (t(myCur_first_trans)),
                                    CUR2_TRANS: (t(myCur_second_trans)),
                                }
                            ))}
                            {/*<div className="p2">{renderHtml(t('$_Real_TIME_LIVE'))}</div>*/}
                            {
                                renderHtml_div(t('TOU_CAN_CONVERT', {
                                    CUR1: `${cur}`,
                                    CUR2: `${cur2}`,
                                    CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                                    CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
                                    CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                                    CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
                                    CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                                    CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                                    CUR1_TRANS: (t(`${cur}`)),
                                    CUR2_TRANS: (t(`${cur2}`)),
                                    AMOUNT: number
                                }))
                            }
                            <div className="select_div"
                                 id="select_wrapper"
                                 data-cur1={cur.toUpperCase()}
                                 data-cur2={cur2.toUpperCase()}>
                                <SelectComp onCurrencyChanged={this.handleTextChanged}/>
                            </div>
                            <div className="select_text" dir="ltr">
                                {
                                    (number > 0 || number < 0) ?
                                        <span className="amount" id="cur1_val">{number}</span>
                                        : <span className="amount" id="cur1_val">0</span>
                                }
                                <span className="cur1" data-currency={cur.toLowerCase()}>{` ${cur}`}</span>
                                {` = `}
                                {
                                    ((number * (1 * rate)).toFixed(6) > 0) ? <span id="fz">
                                <span className="result" id="_calc_wrap">{changedAmount}</span>
                                <span className="cur2"
                                      data-currency={cur2.toLowerCase()}>{`${(number * (1 * rate)).toFixed(6)} ${cur2}`}</span>
                                </span>
                                        :
                                        `${cur2}`
                                }
                            </div>

                        </div>
                        <div className="p2-currency">
                            <div className="select_text" dir="ltr">
                                {
                                    <span className="amount" id="cur1_val">1</span>
                                }
                                <span className="cur1" data-currency={cur.toLowerCase()}>{` ${cur}`}</span>
                                <span className="equal">{` = `}</span>
                                {
                                    <span id="fz">
                                <span className="result" id="_calc_wrap">{changedAmount}</span>
                                <span className="cur2 cur2-color"
                                      data-currency={cur2.toLowerCase()}>{`${((1 * rate)).toFixed(2)} `}</span>
                                   <span className="cur2">{`${cur2}`}</span>
                                </span>
                                }
                                <span className="slash">{` / `}</span>
                                {
                                    <span className="amount" id="cur1_val">1</span>
                                }
                                <span className="cur1" data-currency={cur.toLowerCase()}>{` ${cur2}`}</span>
                                <span className="equal">{` = `}</span>
                                {
                                    <span id="fz">
                                <span className="result" id="_calc_wrap">{changedAmount}</span>
                                <span className="cur2 cur2-color"
                                      data-currency={cur2.toLowerCase()}>{`${((1 / rate)).toFixed(2)} `}</span>
                                   <span className="cur2" data-currency={cur2.toLowerCase()}>{`${cur}`}</span>
                                </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        rate: state.rate,
        number: state.number,
        language: state.language
    }),
    dispatch => ({
        onCurrencyChanged: (cur, cur2, isInitial) => dispatch(changeCurency(cur, cur2, isInitial)),
        onNumberValue: (e) => dispatch(numberValue(e.target.value)),
        onInitialNumberValue: (num) => dispatch(numberValue(num)),
        currencyConverte: (cur, cur2) => dispatch(currencyConverte(cur, cur2)),
        getInitialCursData: (cur, cur2) => dispatch(getInitialCursData(cur, cur2)),

    })
)(translate('common')(withRouter(DemoMiniHomePage)))

