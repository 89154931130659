import React from "react";
import {translate} from "react-i18next";

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

class OtherMini extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    render(){
        const { t } = this.props;
        const ifc_domain = `$_IFC_DOMAIN`;


        return (
            <div className={"currency_box pdb"}>
                <div className="all-page-head">
                    <h1>{renderHtml(t('$_WANT_TO_EARN'))}</h1>
                    {renderHtml(t('$_OPEN_FOREX',{IFC_DOMAIN: (t(ifc_domain)),}))}
                    {renderHtml(t('$_FREE_DEMO_AVAILABLE', {IFC_DOMAIN: (t(ifc_domain)),}))}
                </div>
            </div>
        );
    }
}

export default (translate('common')(OtherMini));
