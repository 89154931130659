import React from "react";
import {Link, Route, Switch, withRouter,} from "react-router-dom";
import { translate } from 'react-i18next';

import {onChangeActiveIndex, openMobileMenu} from "../redux/actions/userActions"
import OtherMini from "../MiniComponents/OtherMini";
import { connect } from "react-redux";
import {
    MAIN_ROUTE,
    CALCULATOR_ROUTE,
    CURRENCY_ROUTE,
    MARGIN_ROUTE,
    PROFIT_LOSS_ROUTE,
    CALCULATOR_ROUTE_MAKER,
    MAIN_ROUTE_BY_LANGUAGE,
    CURRENCY_ROUTE_MAKER,
    MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES,
    MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUT,
    CRYPTO_ROUTE,
    CRYPTO_ROUTE_MAKER,
    MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_CRYPTO,
    MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUNT_CRYPTO,
    MAIN_ROUTE_BY_LANGUAGE__FUNC,
    LANDING_ROUTE, LANDING_ROUTE_WITH_LANGUAGE_AND_CURRENCIES, LANDING_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUNT
} from "./../helpers/constants";
// import NotFound from "./NotFound";
import {onLanguageChange} from "../redux/actions/userActions";
import LangMenu from "./LangMenu";
import DemoMiniHomePage from "../MiniComponents/DemoMiniHomePage";
import DemoMiniHomePageCrypto from "../MiniComponents/DemoMiniHomePageCrypto";
import DemoMiniHomePageLanding from "../MiniComponents/DemoMiniHomePageLanding";




const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);
const toggleClass = (el, className) => el.classList.toggle(className);


class Header extends React.Component  {
    constructor(props){
        super(props);
        this.state = {
            cur: 0
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.activeIndex !== nextProps.activeIndex || this.props.language !== nextProps.language);
    }

    render(){
        const { t, language, activeIndex} = this.props;

        return (
            <header>
                <div className="navbar navbar-default site-header">
                    <div className="container-fluid">
                        <div className="navbar-header" onClick={()=>this.props.onChangeActiveIndex(0)}>
                            <Link to={MAIN_ROUTE_BY_LANGUAGE__FUNC(this.props.language)} className="logo-ifc"  aria-label={'CFD Rates'}>
                                <div className="cfd-logo"></div>
                                <div className="cfd-logo-mobile"></div>
                            </Link>
                        </div>
                        <ul className="nav navbar-nav pull-right">
                            <li className={(activeIndex===0)?"active":""}  onClick={()=>this.props.onChangeActiveIndex(0)}>
                                <Link to={MAIN_ROUTE}>
                                    <div dangerouslySetInnerHTML={{__html: t('CURRENCY_CONVERTER')}}/>
                                </Link>
                            </li>
                            <li className={(activeIndex===3)?"active":""} onClick={()=>this.props.onChangeActiveIndex(3)}>
                                <Link to={CRYPTO_ROUTE_MAKER(language)}>
                                    {renderHtml(t('$_CRYPTO_MENU'))}
                                </Link>
                            </li>
                            <li className={(activeIndex===1)?"active":""} onClick={()=>this.props.onChangeActiveIndex(1)}>
                                <Link to={CALCULATOR_ROUTE_MAKER(language)}>
                                    {renderHtml(t('$_TRADING_CALCULATORS'))}
                                </Link>
                            </li>
                            <li className={(activeIndex===2)?"active":""} onClick={()=>this.props.onChangeActiveIndex(2)}>
                                <Link to={CURRENCY_ROUTE_MAKER(language)}>
                                    {renderHtml(t('SECTOR_CURRENCIES'))}
                                </Link>
                            </li>
                            <LangMenu />
                            <li>
                                <span className={`mobile_menu `}
                                      onClick={() => {this.props.openMobileMenu();
                                          toggleClass(document.body, 'bodyOverflow')} }/>
                            </li>
                        </ul>
                    </div>
                    <div className="cutout"/>
                    <div className="clear"/>
                    <Switch>
                        <Route path={MAIN_ROUTE} exact component={DemoMiniHomePage} />
                        <Route  path={MAIN_ROUTE_BY_LANGUAGE} exact component={DemoMiniHomePage} />
                        <Route  path={CRYPTO_ROUTE} exact component={DemoMiniHomePageCrypto} />
                        <Route  path={LANDING_ROUTE} exact component={DemoMiniHomePageLanding} />
                        <Route  path={MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_CRYPTO} exact component={DemoMiniHomePageCrypto} />
                        <Route  path={LANDING_ROUTE_WITH_LANGUAGE_AND_CURRENCIES} exact component={DemoMiniHomePageLanding} />
                        <Route  path={MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUNT_CRYPTO} exact component={DemoMiniHomePageCrypto} />
                        <Route  path={MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES} exact component={() => {
                            if(this.props.location.pathname.includes("forex") || this.props.location.pathname.includes("currencies")){
                                return <OtherMini/>
                            }else {
                                return <DemoMiniHomePage/>
                            }
                        }} />
                        <Route  path={MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUT} exact component={() => {
                            if(this.props.location.pathname.includes("forex") || this.props.location.pathname.includes("currencies")){
                                return <OtherMini/>
                            }else {
                                return <DemoMiniHomePage/>
                            }
                        }} />
                        <Route  path={LANDING_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUNT} exact component={DemoMiniHomePageLanding} />
                        <Route exact path={CALCULATOR_ROUTE} component={OtherMini} />
                        <Route exact path={CURRENCY_ROUTE} component={OtherMini} />
                        <Route exact path={PROFIT_LOSS_ROUTE} component={OtherMini} />
                        <Route path={MARGIN_ROUTE} component={OtherMini} />
                    </Switch>
                </div>
            </header>
        )
    }
}
export default connect(
    state => ({
        language: state.language,
        activeIndex: state.activeIndex
    }),
    dispatch => ({
        openMobileMenu: () => dispatch(openMobileMenu()),
        onLanguageChange: (language) => dispatch(onLanguageChange(language)),
        onChangeActiveIndex: (index) => dispatch(onChangeActiveIndex(index))
    })
)(translate('common')(withRouter(Header)))
