import React from 'react';
import {translate} from "react-i18next";
// import RegisterForm from "./RegisterForm";
import RegisterForm2 from "./RegisterForm2";
import HomePage from "./HomePage/HomePage";
import {connect} from "react-redux";

class DashBoard extends React.Component{


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.language !== nextProps.language)
    }

    render(){
        console.log(this.props.language, "lang");

        return(
            <div>
                {/*<RegisterForm />*/}
                <RegisterForm2 />
                <HomePage/>
            </div>
        )
    }
}
// export default  (translate('common')(DashBoard));
export default connect(
    state => ({
        language: state.language,
    }))((translate('common')(DashBoard)));