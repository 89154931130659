import React from 'react';
import {translate} from "react-i18next";
import {connect} from "react-redux";
import HomePageLanding from "./HomePage/HomePageLanding";
import RegisterForm2 from "./RegisterForm2";

class DashBoardLanding extends React.Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.language !== nextProps.language)
    }
    render(){
        return(
            <div className={"landing"}>
                <RegisterForm2 />
                <HomePageLanding/>
            </div>
        )
    }
}
export default connect(
    state => ({
        language: state.language,
    }))((translate('common')(DashBoardLanding)));