import React from 'react';
import LiveExchangeRates from "./LiveExchangeRates";
import {Helmet} from "react-helmet";
import {translate} from "react-i18next";

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);


const num = 0;
class ProfitLossCalcForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: "forex",
            selectedInstrument: "",
            buy_sell: "sell",
            data: {
                base_unit: "AUD",
                name: "AUDCAD",
                pippervalue: 0.0001,
                size_of_lot: 100000,
            },
            errorMessage: "none",
            volume: "",
            openPrice: "",
            closePrice:"",
            error: "",
            error_descr: "",
            pip_val_in_jpy: 0,
            pip_val_in_quote_curr: 0,
            pip_val_in_usd: 0,
            profitloss_in_jpy: 0,
            profitloss_in_quote_curr: 0,
            profitloss_in_usd: 0
        }
    }

    componentDidMount() {

        const self = this;
        fetch("https://www.ifcm.co.uk:2083/calculation/instruments-data?group=forex")
            .then(res => res.json())
            .then(instruments => {
                self.setState({instruments});
            });
        self.getInstrumentDataByName('AUDCAD');
    }

    selectCategory(e){
        this.setState({selectedCategory: e.target.value});
        const self = this;
            fetch("https://www.ifcm.co.uk:2083/calculation/instruments-data?group=" + e.target.value)
                .then(res => res.json())
                .then(instruments => {
                    self.setState({instruments});
                    this.getInstrumentDataByName(instruments[0]['name']);
                })
        }

        selectInstrument(e){
            const name = e.target.value;
            this.setState({selectedInstrument: e.target.value});
            const data = this.state.instruments.find(e=> e.name === name);
            this.setState({ data });
            this.getInstrumentDataByName(name);

        }

        getInstrumentDataByName(name){
            fetch("https://www.ifcm.co.uk:2083/instrument/get-quote?name='" +  encodeURIComponent(name) + "'")
                .then(res => res.json())
                .then(data => {

                    console.log(data);
                    this.setState( {openPrice : Number(data.ask).toFixed(2)});
                    this.setState( {closePrice : Number( data.bid).toFixed(2)});
                })
        }

        calculate(){
            if(this.state.value !=="" && this.state.closePrice !=="" && this.state.openPrice !==""){
                this.setState({errorMessage: "none"});
                const query = {
                    'platform': this.props.platform,
                    'instrument': this.state.data.name,
                    'volume': this.state.volume,
                    'open_price': this.state.openPrice,
                    'close_price': this.state.closePrice,
                };

                const searchParams = Object.keys(query).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
                }).join('&');

                fetch("https://www.ifcm.co.uk:2083/calculation/profitloss", {
                    method: 'post',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: searchParams
                }).then((res) => res.json())
                    .then((data) =>   this.setState({
                        pip_val_in_jpy: data.pip_val_in_jpy ,
                        pip_val_in_quote_curr: data.pip_val_in_quote_curr ,
                        pip_val_in_usd: data.pip_val_in_usd ,
                        profitloss_in_jpy: data.profitloss_in_jpy ,
                        profitloss_in_quote_curr: data.profitloss_in_quote_curr ,
                        profitloss_in_usd: data.profitloss_in_usd ,
                    })
)
                    .catch((err)=>console.log(err));
            } else {
                this.setState({
                    errorMessage: "",
                    pip_val_in_jpy: 0,
                    pip_val_in_quote_curr: 0,
                    pip_val_in_usd: 0,
                    profitloss_in_jpy: 0,
                    profitloss_in_quote_curr: 0,
                    profitloss_in_usd: 0
                });
            }
        };


    render(){
        const { t } = this.props;
        const { platform} = this.props;
        const {instruments, data} = this.state;

        const mg_category_select = [
            {
                value: "forex",
                name: "Currency Pairs"
            },
            {
                value: "indices",
                name: "Indices"
            },
            {
                value: "commodities",
                name: "Commodities"
            },
            {
                value: "stocks",
                name: "Stocks"
            },
            {
                value: "precious-metals",
                name: "Precious Metals"
            },
            {
                value: "gold-trading",
                name: "Gold Instruments"
            },
            {
                value: "personal-instrument-pci",
                name: "PCI Library"
            },
            {
                value: "cfd-etf",
                name: "ETF"
            },
            {
                value: "crypto",
                name: "Cryptocurrency CFDs"
            }
        ];
        // console.error("POPOPOPOPOPOPOPO", this.state);


        return(
            <div>
                <Helmet>
                    <title>{`${(t('&_PROFITLOSS_CALCULATOR'))} | CFDRATES`}</title>
                    <meta name="description" content={(t('&_PROFITLOSS_CALCULATOR_TXT'))} />
                    <meta name="theme-color" content="#008f68" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
            <div>
                <div>
                    <div className={`__main_form ${this.props.platform}`}>
                            <div className="filer_wrap">
                                <div className="partss for_profit_section">
                                    <label className="mini_label">
                                        {renderHtml(t('SELECT_TYPE'))}
                                    </label>
                                    <div className="div_select">
                                        <div className="select_icon"/>
                                        <select id="pl_category_select"
                                                className="for_profit_section"
                                                onChange={e => this.selectCategory(e)}
                                        >
                                            {
                                                mg_category_select.map((item,index) => {
                                                    return <option key={index} value={item.value} >{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="partss for_profit_section">
                                    <label className="mini_label">
                                        {renderHtml(t('SELECT_NAME'))}
                                    </label>
                                    <div className="div_select">
                                        <div className="select_icon"/>
                                        <select id="profit_loss_calc_form_instrument"
                                                onChange={e => this.selectInstrument(e)}
                                                className="fleft for_profit_section">
                                            {
                                                 instruments && instruments.map((item,index) => {
                                                    return  <option value={item.value} key={index}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="partss disi">
                                    <label className="mini_label">
                                        {renderHtml(t('PIPPERVALUE'))}
                                    </label>
                                    <div className="bid_ask_wrapper for_profit_section">
                                        <span className="pl_pippervalue">
                                            {data.pippervalue}
                                        </span>
                                    </div>
                                    <label className="mini_label pl_pippervalue_tech under_label">
                                        {`{${data.pippervalue}}`}
                                    </label>
                                </div>
                                <div className="__only-mt4 partss" style={{display: this.props.hideBuySell ? "none": ""}}>
                                    <label className="mini_label">
                                        {renderHtml(t('SIZE_OF_ONE_LOT'))}
                                    </label>
                                    <div className="bid_ask_wrapper for_profit_section">
                                        <span className="pl_mt4_lotsize">{data.size_of_lot} {data.base_unit}</span>
                                    </div>
                                </div>
                            </div>
                            <div id="deal_dir_wrap"
                                 className="__only-mt4"
                                 style={{display: this.props.hideBuySell ? "none": ""}}
                            >
                                <div className="calc_radio_buy">
                                    <label className="customs">
                                        <span className="top_to">
                                           {renderHtml(t('BUY'))}
                                        </span>
                                        <input type="radio"
                                               name="deal_dir_front"
                                               value="buy"
                                               onChange={(e)=> this.setState({buy_sell: e.target.value})}
                                        />
                                        <span className="checkmark"> </span>
                                    </label>
                                </div>
                                <div className="calc_radio_sell">
                                    <label htmlFor="" className="customs">
                                        <span className="top_to">
                                            {renderHtml(t('SELL'))}
                                        </span>
                                        <input type="radio"
                                               name="deal_dir_front"
                                               value="sell"
                                               checked={true}
                                               onChange={(e)=> this.setState({buy_sell: e.target.value})}
                                        />
                                        <span className="checkmark"/>
                                    </label>
                                </div>
                            </div>
                            <div className="filer_wrap nd_wrap">
                                <div className="partss">
                                    <label htmlFor="volume" className="mini_label">
                                        {renderHtml(t('$_UNITS_OF_BASE'))}
                                        <span className="__only-nttx" >
                                        <span className="curr_only">
                                            {renderHtml(t('$_UNITS_OF_BASE'))}
                                        </span>
                                        <span className="non_curr_only">
                                            {renderHtml(t('CALCULATOR_VOLUME_NONCURR'))}
                                        </span>
                                        </span>
                                        <span  style={{display: "none"}}>
                                            {renderHtml(t('CALCULATOR_VOLUME_MT4'))}
                                        </span>
                                    </label>
                                    <div className="for_unit">
                                        <input type="number"
                                               className="big_select"
                                               onChange={(e) => {this.setState( {volume : e.target.value})} }
                                        />
                                        <label className="pl_vol_unit mini_label">
                                            {
                                                (platform === "NTX") ? data.name : "lot"
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="partss">
                                    <label htmlFor="volume" className="mini_label">

                                            {renderHtml(t('OPENING'))}
                                    </label>

                                    <input type="number"
                                           className="big_select"
                                           value={Number(this.state.openPrice).toFixed(2)}
                                           onChange={(e) => {this.setState( {openPrice : e.target.value})} }
                                    />
                                    <label className="mini_label curprice curprice_open under_label">
                                        {Number(this.state.openPrice).toFixed(2)}
                                    </label>
                                </div>
                                <div className="partss">
                                    <label htmlFor="volume" className="mini_label">
                                        {renderHtml(t('CALCULATOR_CLOSEPRICE'))}
                                    </label>
                                    <input  type="number"
                                            className="big_select"
                                            value={Number(this.state.closePrice).toFixed(2)}
                                            onChange={(e) => {this.setState( {closePrice : e.target.value})} }
                                    />
                                    <label className="mini_label curprice curprice_close under_label">
                                        {Number(this.state.closePrice).toFixed(2)}
                                    </label>
                                </div>
                            </div>
                            <div id="confirmBoxWrapper"
                                 className="confirm_calculate"
                                 style={{display: this.state.errorMessage }}>
                                {renderHtml(t('CALC_NO_DATA_ERROR'))}
                            </div>
                            <div className="marg_button">
                                <button id="calcprofit"
                                        className="button_green big_button"
                                        onClick={this.calculate.bind(this)}
                                >
                                    {renderHtml(t('CALCULATOR_CALCULATE'))}
                                </button>
                            </div>
                            <div className="four_components">
                                <div className="prls">
                                    <div className="flagpl pip_val_in_quote_curr_flagpl">
                                        <span className="flag_24 null"/>
                                    </div>
                                    <div className="all_together">
                                        <div className="firsttt">
                                            <span className="label">
                                                {renderHtml(t('CALCULATOR_POINTINCC'))}
                                            </span>
                                            <span className="num pip_val_in_quote_curr">{this.state.pip_val_in_quote_curr}</span>
                                        </div>
                                        <div className="fleft">
                                            <span className="label">
                                                {renderHtml(t('CALCULATOR_PLINCC'))}
                                            </span>
                                            <span className="num profitloss_in_quote_curr">{this.state.profitloss_in_quote_curr}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="prls">
                                    <div className="flagpl">
                                        <span className="flag_24 USD"/>
                                    </div>
                                    <div className="all_together">
                                        <div className="firsttt">
                                            <span className="label">
                                                {renderHtml(t('CALCULATOR_POINTINUSD'))}
                                            </span>
                                            <div>
                                                <span className="num pip_val_in_usd">{this.state.pip_val_in_usd}</span>
                                                <span className="unit">USD</span>
                                            </div>
                                        </div>
                                        <div className="fleft">
                                            <span className="label">
                                                {renderHtml(t('CALCULATOR_PLINUSD'))}
                                            </span>
                                            <div>
                                                <span className="num profitloss_in_usd">{this.state.profitloss_in_usd}</span>
                                                <span className="unit">USD</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="prls">
                                    <div className="flagpl">
                                        <span className="flag_24 JPY"/>
                                    </div>
                                    <div className="all_together">
                                        <div className="firsttt">
                                            <span className="label">
                                                {renderHtml(t('CALCULATOR_POINTINJPY'))}
                                            </span>
                                            <div>
                                                <span className="num pip_val_in_jpy">{this.state.pip_val_in_jpy}</span>
                                                <span className="unit">JPY</span>
                                            </div>
                                        </div>
                                        <div className="fleft">
                                            <span className="label">
                                                {renderHtml(t('CALCULATOR_PLINJPY'))}
                                            </span>
                                            <div>
                                                <span className="num profitloss_in_jpy">{this.state.profitloss_in_jpy}</span>
                                                <span className="unit">JPY</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <LiveExchangeRates/>
                </div>
            </div>
            </div>
        )
    }
}
export default (translate('common')(ProfitLossCalcForm));
