import React from 'react';
import Currenciesdecscomp from "./Currenciesdecscomp";
import {translate} from "react-i18next";

const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>)


class CurrenciesInIFCMarkets extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            moreInfo: "none",
            arrow: "",
            search: this.props.searchText,
            infoCurrency: [],
        }
    }

    componentDidMount() {
        fetch(`https://www.ifcm.co.uk:2083/instrument/currencies`, {
            method: 'get',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then((res) => res.json())
            .then((data) => {
                this.setState({infoCurrency: data})
                }
            )
            .catch((err)=>console.log(err));
    }

    render(){
        const { t } = this.props;

        return(
            <div className={"scroll_content"}>
            <table id="equities_table" className="" >
                <thead>
                <tr>
                    <td className="col-sort">
                        {renderHtml(t('NAME'))}
                        <span className="icon-notice_hover notice_hover What-this What-this_1"
                              id="notice_alphabetcode"
                              text="Currency name is a three-letter acronym. The first two letters indicate the name of the country. The last letter is the first letter of the currency name of the given country."
                        > </span>
                    </td>
                    <td className="col-sort">
                        {renderHtml(t('ALPHABETICCODE'))}
                        <span  className="icon-notice_hover notice_hover What-this What-this_1"
                               id="notice_alphabetcode"
                               text="Abbreviation or Alphabetic Currency Code defines the currency name and consists of three letters. The first two letters indicate the two-letter country code defined by ISO 3166 which are followed by the initial letter of the currency name."
                        > </span>
                    </td>
                    <td className="col-sort">
                        {renderHtml(t('ISOCODE'))}
                        <span className="icon-notice_hover notice_hover What-this What-this_1"
                              id="notice_iso"
                              text="Numeric currency code is a three-digit numeric code used in the countries which do not use the letters of the Latin alphabet. It usually coincides with country's digital code."
                        > </span>
                    </td>
                    <td>
                        {renderHtml(t('CURRENCYSYMBOL'))}
                        <span className="icon-notice_hover notice_hover What-this What-this_1"
                              id="notice_alphabetcode"
                              text="Currency symbols are graphic symbols used for a brief description of monetary units."
                        > </span>
                    </td>
                    <td className="tgl_btn_col">
                        {renderHtml(t('EXCHANGE_RATES'))}
                    </td>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.infoCurrency.map((item,index) => {
                        return <Currenciesdecscomp name={item.name}
                                                     abbreviation = {item.name}
                                                     code = {item.iso_code}
                                                     symbol = {item.symbol}
                                                     index={index}
                                                     searchText={this.props.searchText}
                                                     key={index}
                        />
                        })
                    }
                </tbody>
                </table>
            </div>
        )
            }
}
export default (translate('common')(CurrenciesInIFCMarkets))