import React from "react";
import {connect} from "react-redux";
import {options} from "./../helpers/CountryList";
import {withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import {numberValue} from "./../redux/actions/userActions";
import Select from 'react-select';

import "./SelectComp.css";

class SelectComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            secondOption: null
        };
    }

    handleChange = selectedOption => {
        this.setState({selectedOption: selectedOption}, () => this.props.onCurrencyChanged(selectedOption.value, this.props.cur2));
    };
    handleChange2 = secondOption => {
        this.setState({secondOption: secondOption}, () => this.props.onCurrencyChanged(this.props.cur, secondOption.value,));
    };


    numberChanged(e){
        if(e.target.value.length <= 10){
            this.props.onNumberValue(e)} else {return ""}
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.cur !== nextProps.cur) || (this.props.cur2 !== nextProps.cur2) || (this.props.number!==nextProps.number)
    }


    render() {
        const { number, cur, cur2, onCurrencyChanged} = this.props;

        return (
            <div className='selectCur'>


                <label for="__frst_conve_val" className="sections sec1">
                    <input id="__frst_conve_val"
                           type="number"
                           name="amount"
                           value={number}
                           aria-label="Value"
                           className="magicLabels"
                           onChange={(e) => this.numberChanged(e)}
                        // onKeyPress={(e)=>{ if (e.which === 69) {
                        //     e.preventDefault();
                        // }}}
                    />
                </label>

                <Select
                    className={"sections sec2"}
                    value={{value: cur,
                        label: <><span
                            className={`flag_24 ${cur}`}/><span>{cur}</span></>
                    }}
                    aria-label="Currency"
                    onChange={this.handleChange}
                    options={options}
                />

                <div className="sections sec3"
                     onClick={() => onCurrencyChanged(cur2, cur)}>
                    <button id="replasecur" className="center-block converter_replasecur" aria-label="convert">
                        <span/>
                    </button>
                </div>

                <Select
                    className={"sections sec4"}
                    value={{value: cur2,
                        label: <><span
                            className={`flag_24 ${cur2}`}/><span>{cur2}</span></>
                    }}
                    aria-label="Currency"
                    onChange={this.handleChange2}
                    options={options}
                />

            </div>);
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        rate: state.rate,
        number: state.number,
        language: state.language
    }),
    dispatch => ({
        // onCurrencyChanged: (cur, cur2, isInitial) => dispatch(changeCurency(cur, cur2, isInitial)),
        onInitialNumberValue: (num) => dispatch(numberValue(num)),
        onNumberValue: (e) => dispatch(numberValue(e.target.value))
    })
)(translate('common')(withRouter(SelectComp)))

