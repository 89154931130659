import React from 'react';
import {connect} from "react-redux";
import {getAvailableCouples, getExchange} from "../../redux/actions/userActions";
import {translate} from "react-i18next";
import Modal from './Modal';

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props}}>
</span>);

const getAvailab = () => {
    return ["AUDCAD", "AUDCHF", "AUDJPY", "AUDNZD", "AUDUSD", "CADCHF", "CADJPY", "CHFJPY", "EURAUD", "EURCAD", "EURCHF", "EURCZK", "EURGBP", "EURHKD", "EURJPY", "EURMXN", "EURNZD", "EURPLN", "EURRUB", "EURSEK", "EURTRY", "EURUSD", "EURZAR", "GBPAUD", "GBPCAD", "GBPCHF", "GBPJPY", "GBPNZD", "GBPSEK", "GBPUSD", "NZDCAD", "NZDCHF", "NZDJPY", "NZDUSD", "USDCAD", "USDCHF", "USDCNH", "USDCZK", "USDDKK", "USDHKD", "USDJPY", "USDMXN", "USDNOK", "USDPLN", "USDRUB", "USDSEK", "USDSGD", "USDTRY", "USDZAR"]
}

class BuySell extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            continueTradeActions: false,
            isModalOpen: false,
            lastProfit: 0,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleFinalCalculateModal = this.handleFinalCalculateModal.bind(this);
    }

    handleCancelClick() {
        this.setState({
            continueTradeActions: false,
        });
    }

    handleClick(event, dealDir) {
        if(this.state.volume != '')
        document.querySelector('.buy_sell_buttons_cont').classList.add('d-none');
        document.querySelector('.profit-loss-block').classList.remove('d-none');
        const {data} = this.props;
        let startPrice;

        if (dealDir === 'buy') {
            startPrice = data.ask;
        } else {
            startPrice = data.bid;
        }

        const originalAmount = 100000;
        const volume = originalAmount / startPrice;
        this.setState({
            continueTradeActions: true,
            dealDir: dealDir,
            volume: Math.round(volume),
            startPrice: startPrice,
        });
    }

    handleFinalCalculateModal() {
        this.setState({
            isModalOpen: true,
        });
    }

    componentDidMount() {
        document.body.dir = `${(this.props.language === 'ar' || this.props.language === 'fa') ? 'rtl' : "ltr"}`;
        getAvailab();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.cur !== this.props.cur) || (prevProps.cur2 !== this.props.cur2) || (prevProps.language !== this.props.language)) {
            this.props.getExchange(this.props.cur, this.props.cur2);
        }

        if (this.state.isModalOpen && !prevState.isModalOpen) {
            this.setState({ continueTradeActions: false });
        }
    }

    profitLossCalculate = (dealDir, volume, priceOpen, priceClose, cur2) => {

        //console.log(dealDir, volume, priceOpen, priceClose);
        const { data } = this.props;
        let profit;
        const quoteCurrency = { quotes: {} };
        if (cur2 === 'USD') {
            quoteCurrency.quotes = {
                ask: 1,
                bid: 1,
                mid: 1,
            };
        } else {
            if (!data) return 0;
            quoteCurrency.quotes = {
                ask: +data.ask,
                bid: +data.bid,
                mid: (Number(data.ask) + Number(data.bid)) / 2,
            };
        }

        let zVal = 1 / quoteCurrency.quotes.mid;
        let profitQuote = (priceClose - priceOpen) * volume * (dealDir === 'buy' ? 1 : -1);
        profit = profitQuote * zVal;

        if (Number.isNaN(profit)) profit = 0;
        let roundedProfit =  Math.round(profit * 10) / 10;

        if (this.state.continueTradeActions && roundedProfit !== this.state.lastProfit) {
            this.setState({
                lastProfit: roundedProfit,
            });
        }

         return roundedProfit;
    };


    closeModal = () => {
        this.setState({isModalOpen: false});
    };

    render() {

        const { cur, cur2, data, number, t } = this.props;
        let profit = 0;
        let className = '';
        let symbol = '';

        if (this.state.continueTradeActions && this.state.volume != 'Infinity') {
            profit = this.profitLossCalculate(this.state.dealDir, +this.state.volume, +this.state.startPrice, this.state.dealDir === 'buy' ? +data.ask : +data.bid, cur2);
            if (profit >= 0) {
                className = 'green';
                symbol = '+';
                document.querySelector('.profit-actions').classList.remove('d-none');
                document.querySelector('.loss-actions').classList.add('d-none');
            } else {
                className = 'red';
                symbol = '-';
                document.querySelector('.loss-actions').classList.remove('d-none');
                document.querySelector('.profit-actions').classList.add('d-none');
            }
        }

        let calculatedAmountClass = `calculated-amount ${className}`;

        let numberLength = profit.toString().length;
        let calculatedAmountStyle = {};
        if (numberLength >= 6) {
            calculatedAmountStyle.minHeight = '150px';
            calculatedAmountStyle.minWidth = '150px';
            calculatedAmountStyle.fontSize = '25px';
        } else {
            calculatedAmountStyle.minHeight = '140px';
            calculatedAmountStyle.minWidth = '140px';
        }
        let calculatedAmountText = symbol + Math.abs(profit);

        return (
            <div className="buy-sel-comp">
                <h2>
                    {
                        renderHtml(t('$_QUOTE_PRICE_DATA_CURRENCY_CONVERTER', {
                            CUR1: `${cur}`,
                            CUR2: `${cur2}`,
                            CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                            CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
                            CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                            CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
                            CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                            CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                            CUR1_TRANS: (t(`${cur}`)),
                            CUR2_TRANS: (t(`${cur2}`)),
                            AMOUNT: number
                        }))
                    }
                </h2>
                <div className="display_of_button">
                    <div className={this.state.continueTradeActions ? 'profit-loss-block' : 'profit-loss-block d-none'}>
                        <div className="amount-profit">
                            <span style={calculatedAmountStyle} className={calculatedAmountClass}>
                              {calculatedAmountText}
                            </span>
                            <div className="initial-amount d-flex flex-column-reverse mb-30 text-center">
                                <div className="calculation-base">
                                    <div>
                                        {renderHtml(t('CALCULATOR_LEVERAGE'))}
                                        <span className="mx-10">1:100</span>
                                    </div>
                                    <div>
                                        {renderHtml(t('MARGIN'))}
                                        <span className="mx-10">1000</span>
                                    </div>
                                </div>
                                <div className="mb-10">
                                    {renderHtml(t('CALCULATION_BASE'))}
                                </div>
                            </div>
                            <div className="after-click-actions mb-20">
                                <div className="profit-actions d-none">
                                    <button className="btn btn-success mb-10" onClick={this.handleFinalCalculateModal}>
                                        {renderHtml(t('TAKE_PROFIT'))}
                                    </button>
                                    <button className="btn-trans btn cancel mb-10" onClick={this.handleCancelClick}>
                                        {renderHtml(t('CANCEL'))}
                                    </button>
                                </div>
                                <div className="loss-actions d-none">
                                    <button className="btn btn-danger" onClick={this.handleFinalCalculateModal}>
                                        {renderHtml(t('CLOSE_POS'))}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={this.state.continueTradeActions ? 'buy_sell_buttons_cont d-none' : 'buy_sell_buttons_cont'}>
                        <button data-action="sell" className="sell-item ga__sell_cfd mb-20" rel="noopener" onClick={(event) => this.handleClick(event, "sell")}>
                            <span className="mb-10">
                                Sell
                            </span>
                            <div className="inst">{data.bid || "---"}</div>
                        </button>
                        <button data-action="buy" className="buy-item  ga__buy" rel="noopener" onClick={(event) => this.handleClick(event, "buy")}>
                            <span className="mb-10">
                                Buy
                            </span>
                            <div className="inst">{data.ask || "---"}</div>
                        </button>
                    </div>
                </div>
                <div className="stat_changes">
                    <div>
                        <div className="pci_status_trading">
                                                            <span>
                                                                {renderHtml(t('TRADE_STATUS'))}
                                                                :&nbsp;</span>
                            <span className="live_closed" style={{display: "none"}}>
                                                        <span>
                                                            {renderHtml(t('LIVE_CLOSED'))}
                                                        </span>
                                                    </span>
                            <span className="live_trading to_up"
                                  style={{display: "inline-block"}}>
                                                        <span>
                                                            <span/>
                                                            {renderHtml(t('LIVE_TRADING'))}
                                                        </span>
                                                    </span>
                        </div>
                        <div className="pci_change_value">
                            <span>
                            {renderHtml(t('CHANGE'))}
                                :&nbsp;
                            </span>
                            <span
                                className={`__changes to_${data.state}`}>{data.percent}%</span>
                        </div>
                    </div>
                    <div className="max_min">
                        <div className="max_min_blocks">
                            <div className="lbox">{renderHtml(t('PREV_CLOSING'))}</div>
                            <div className="rbox __close_bid">{data.close}</div>
                        </div>
                        <div className="max_min_blocks">
                            <div className="lbox">{renderHtml(t('OPENING'))}</div>
                            <div className="rbox __open_bid">{data.open}</div>
                        </div>
                        <div className="max_min_blocks">
                            <div className="lbox">{renderHtml(t('MAX_PRICE'))}</div>
                            <div className="rbox __max_bid to_up">{data.high}</div>
                        </div>
                        <div className="max_min_blocks">
                            <div className="lbox">{renderHtml(t('MIN_PRICE'))}</div>
                            <div className="rbox __min_bid to_down">{data.low}</div>
                        </div>
                    </div>
                </div>
                {this.state.isModalOpen && (
                    <Modal
                        profit={this.state.lastProfit}
                        lang={this.props.language}
                        onCloseModal={this.closeModal}
                    />
                )}

            </div>
        )
    }
}


export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        data: state.data,
        number: state.number,
        language: state.language

    }),
    dispatch => ({
        getExchange: (cur, cur2) => dispatch(getExchange(cur, cur2)),
        getAvailableCouples: () => dispatch(getAvailableCouples())
    })
)(translate('common')(BuySell));

