import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from 'react-i18next';
import {getVolatility} from "../../redux/actions/userActions";
import {withRouter} from "react-router-dom";

const renderHtml = (props) => (<h2
    dangerouslySetInnerHTML={{__html: props}}>
</h2>);


class Volatility extends Component {
    constructor(props) {
        super(props);
    };

    componentDidMount() {
        this.props.getVolatility()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.curCrypto !== this.props.curCrypto) || (prevProps.curCrypto2 !== this.props.curCrypto2) || (prevProps.language !== this.props.language)) {
            this.props.getVolatility();
        }
    }

    getAverage(array) {

        let averageClose = 0;
        let volatility = 0;
        let sum = 0;
        let count = 0;
        let sumPercentage = 0;
        let averagePercentage = 0;
        let sumAverageDiff = 0;
        let priceArr = [];
        let PricePercentage = [];

        array.forEach(function (item) {
            sum+=Number(item.close);
            priceArr.push(item.close);
            count+=1;
        });
        averageClose = Math.floor(sum/count);

        for (let i = 0; i < priceArr.length -1; i++) {
            let percentItem = 100*(priceArr[i +1] - priceArr[i])/priceArr[i];
            PricePercentage.push(percentItem);
            sumPercentage += percentItem;
        }
        averagePercentage = sumPercentage/PricePercentage.length;
        for (let i = 0; i < PricePercentage.length; i++) {
            sumAverageDiff += (PricePercentage[i] - averagePercentage)*(PricePercentage[i] - averagePercentage);
        }
        volatility = Math.sqrt(sumAverageDiff/PricePercentage.length).toFixed(2);

        return {averageClose: averageClose, volatility: volatility};
    }


    render() {
        let last30Days = {high: null, low: null, average: null, volatility:null};
        let last90Days = {high: null, low: null, average: null, volatility:null};

        const {t, volatility,curCrypto, curCrypto2} = this.props;
        const myCur_first = `${curCrypto}`;
        const myCur_second = `${curCrypto2}`;
        if(volatility && volatility.list) {
            let arr30 = [];
            let arr90 = [];
            let l30Days = volatility.list.slice(-30);
            let l90Days = volatility.list.slice(-60);
            l90Days.forEach(function (item) {
                arr90.push(item);
                last90Days.high =  Number(item.high);
                last90Days.low =  Number(item.low);
            });
            l30Days.forEach(function (item) {
                arr30.push(item);
                last30Days.high = last30Days.high && last30Days.high > Number(item.high) ? last30Days.high : Number(item.high);
                last30Days.low = last30Days.low && last30Days.low < Number(item.low) ? last30Days.low : Number(item.low);

            });

            let data30 = this.getAverage(arr30);
            let data90 = this.getAverage(arr90);

            last30Days.average = data30.averageClose;
            last30Days.volatility = data30.volatility;
            last90Days.volatility = data90.volatility;
            last90Days.average = data90.averageClose;
        }

        return (

            <div>
                <h2>{renderHtml(t('$_RATE_STATISTICS', {
                    CUR1: myCur_first,
                    CUR2: myCur_second,
                    CUR1_TRANS: (t(myCur_first)),
                    CUR2_TRANS: (t(myCur_second)),
                }))}</h2>
                <div className="crypto-rate-stat">
                    <table className="table table-striped table-dark topCryptoTable">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Last 30 Days</th>
                            <th>Last 60 Days</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="status">High</td>
                            <td>{last30Days.high}</td>
                            <td>{last90Days.high}</td>
                        </tr>
                        <tr>
                            <td className="status">Low</td>
                            <td>{last30Days.low}</td>
                            <td>{last90Days.low}</td>

                        </tr>
                        <tr>
                            <td className="status">Average</td>
                            <td>{last30Days.average}</td>
                            <td>{last90Days.average}</td>
                        </tr>
                        <tr>
                            <td className="status">Average</td>
                            <td>{last30Days.volatility + '%'}</td>
                            <td>{last90Days.volatility + '%'}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        curCrypto: state.curCrypto,
        curCrypto2: state.curCrypto2,
        language: state.language,
        volatility: state.volatility
    }),
    dispatch => ({
        getVolatility: () => dispatch(getVolatility()),
    })
)(translate('common')(withRouter(Volatility)))