import React, {Component} from "react";
import {connect} from "react-redux";
import {translate} from 'react-i18next';
import {changeCurency, getExchangeRateTodayRate} from "../../redux/actions/userActions";
import {withRouter} from "react-router-dom";

const renderHtml = (props) => (<h2
    dangerouslySetInnerHTML={{__html: props}}>
</h2>);
// const number = [1, 10, 25, 50, 100, 150, 200, 250, 500, 1000, 5000, 10000, 50000];


class ExchangeRatetoday extends Component {
    constructor(props) {
        super(props);
        this.scrolling = this.scrolling.bind(this);
    };


    scrolling() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };


    componentDidMount() {
        this.props.getExchangeRateTodayRate(this.props.cur,this.props.cur2)
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !this.props.exchangeRateTodayRate || (this.props.language !== nextProps.language)
    }

    render() {
        const {t, cur, cur2, language, exchangeRateTodayRate} = this.props;

        const myCur_first = `${cur}`;
        const myCur_second = `${cur2}`;
        const myCur_first_plural = `${cur}_PLURAL`;
        const myCur_second_plural = `${cur2}_PLURAL`;
        const myCur_first_dative = `${cur}_DATIVE`;
        const myCur_second_dative = `${cur2}_DATIVE`;
        const myCur_first_multiple = `${cur}_MULTIPLE`;
        const myCur_second_multiple = `${cur2}_MULTIPLE`;


        return (
            <div className="rates_table">
                <div className="rates_today">
                    <div className="left_rate">
                            {
                                    renderHtml(t('$_EXCHANGE_RATE_TODAY', {
                                        CUR1: myCur_first,
                                        CUR2: myCur_second,
                                        CUR1_PLURAL: (t(myCur_first_plural)),
                                        CUR2_PLURAL: (t(myCur_second_plural)),
                                        CUR1_DATIVE: (t(myCur_first_dative)),
                                        CUR2_DATIVE: (t(myCur_second_dative)),
                                        CUR1_MULTIPLE: (t(myCur_first_multiple)),
                                        CUR2_MULTIPLE: (t(myCur_second_multiple)),
                                        CUR1_TRANS: (t(myCur_first)),
                                        CUR2_TRANS: (t(myCur_second)),
                                    }))
                            }
                        <div className="fulllist">
                            {
                                exchangeRateTodayRate && exchangeRateTodayRate.auxiliary.map((item, index) => {
                                    return (item.cur !== cur)?<div className="all_rates" key={index}>
                                        <div className="exchange-rate"
                                             onClick={() => {
                                                 this.props.onCurrencyChanged(this.props.cur, item.cur);
                                                 this.scrolling();
                                                 this.props.history.push(`/${language}/${cur.toLowerCase()}-${item.cur.toLowerCase()}`)
                                             }
                                             }>
                                            <span className={`flag_24 ${cur}`}/>
                                            <span>1</span>
                                            <span className="__CAD __rate">{cur}</span>
                                        </div>
                                        <div className="equal-symbol">{` = `}</div>
                                        <div className="exchange-rate-right">
                                            <div>
                                            <span className={`flag_24 ${item.cur || item}`}/>
                                            {
                                                (cur===item.cur)?<span className="__CAD __rate">1</span>: <span className="__CAD __rate">{item.rate || "---"}</span>
                                            }
                                            <span>{item.cur || item}</span>
                                            </div>
                                        </div>
                                    </div>:null
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        number: state.number,
        language: state.language,
        exchangeRateTodayRate: state.exchangeRateTodayRate
    }),
    dispatch => ({
        onCurrencyChanged: (cur, cur2) => dispatch(changeCurency(cur, cur2)),
        getExchangeRateTodayRate: (cur, cur2) => dispatch(getExchangeRateTodayRate(cur, cur2)),
    })
)(translate('common')(withRouter(ExchangeRatetoday)))