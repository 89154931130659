export const MAIN_ROUTE = "/";
export const MAIN_ROUTE_BY_LANGUAGE = "/:language";
export const MAIN_ROUTE_BY_LANGUAGE__FUNC = language => `/${language}`;

export const CRYPTO_ROUTE = "/:language/crypto-converter";
export const CRYPTO_ROUTE_MAKER = language => `/${language}/crypto-converter`;

export const MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_CRYPTO = "/:language/crypto-converter/:curencies";
export const MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUNT_CRYPTO = "/:language/crypto-converter/:curencies/:amount";


export const LANDING_ROUTE = "/:language/landing-canada";
export const LANDING_ROUTE_MAKER = language => `/${language}/crypto-converter`;

export const LANDING_ROUTE_WITH_LANGUAGE_AND_CURRENCIES = "/:language/landing-canada/:curencies";
export const LANDING_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUNT = "/:language/landing-canada/:curencies/:amount";


export const CALCULATOR_ROUTE = "/:language/forex-calculator";
export const CALCULATOR_ROUTE_MAKER = language => `/${language}/forex-calculator`;

export const CURRENCY_ROUTE = "/:language/currencies-and-abbreviations";
export const CURRENCY_ROUTE_MAKER = language => `/${language}/currencies-and-abbreviations`;

export const PROFIT_LOSS_ROUTE = "/:language/forex-calculator/profitloss-calculation";
export const PROFIT_LOSS_ROUTE_MAKER = language => `/${language}/forex-calculator/profitloss-calculation`;

export const MARGIN_ROUTE = "/:language/forex-calculator/margin-calculation";
export const MARGIN_ROUTE_MAKER = language => `/${language}/forex-calculator/margin-calculation`;

export const MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES = "/:language/:curencies";
export const MAIN_ROUTE_WITH_LANGUAGE_AND_CURRENCIES_AND_AMOUT = "/:language/:curencies/:amount";

export const WEB_SOCKET_URL = "wss://q.ifccd.net";
