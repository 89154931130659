import React from "react";
import {Link, withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import countryList from "react-select-country-list";
import axios from "axios";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import Select from "react-select";
import './../helpers/CountryList';
import {countysList} from "../helpers/CountryList";

const PhoneInputStyle = {
    border: "1px solid red"
};

const renderHtml = (props) => (<h2
    dangerouslySetInnerHTML={{__html: props}}>
</h2>);
const renderHtml2 = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);


class RegisterForm2 extends React.Component {
    constructor(props) {

        super(props);
        this.options = countryList;

        this.state = {
            fields: {},
            errors: {},
            countryName: '',
            countryCode: '',
            flag: "",
            phone: "",
            rawPhone: "",
            check: "",
            options: this.options,
            value: null,
            isPhoneReadOnly: true

        }
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);

    }

    getGeoInfo = () => {
        const storedData = localStorage.getItem('geoData');
        if (storedData) {
            const data = JSON.parse(storedData);
            this.setState({
                countryName: data.country_name,
                flag: data.country,
                countryCode: data.country_calling_code,
                value: { value: data.country, label: data.country_name },
            });
        } else {
            axios
                .get('https://ipapi.co/json/')
                .then((response) => {
                    const data = response.data;
                    localStorage.setItem('geoData', JSON.stringify(data));
                    this.setState({
                        countryName: data.country_name,
                        flag: data.country,
                        countryCode: data.country_calling_code,
                        value: { value: data.country, label: data.country_name },
                    });
                })
                .catch((error) => {
                    console.log(error);
                    fetch('https://geolocation-db.com/json/')
                        .then(response => response.json())
                        .then(data => {
                            const countryCode = data.country_code;
                            fetch(`https://restcountries.com/v2/alpha/${countryCode}`)
                                .then(response => response.json())
                                .then(data => {
                                    const countryName = data.name;
                                    const callingCodes = data.callingCodes;

                                    if (callingCodes.length > 0) {
                                        const callingCode = callingCodes[0];
                                        this.setState({
                                            countryName: countryName,
                                            flag: countryCode,
                                            countryCode: "+"+ callingCode,
                                            value: { value: countryCode, label: countryName },
                                        });

                                    } else {
                                        console.log('No calling code found for the country.');
                                    }
                                })
                                .catch(error => {
                                    console.error('Error:', error);
                                });
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });

                });
        }
    };


    componentDidMount(){
        this.getGeoInfo();
        this.setState({value: {value: this.state.flag, label: this.state.countryName}})
    }

    changeHandler = value => {
        //     if(this.state.value.label == "Åland Islands"){
        //     return alert("hooop");
        // } else
        this.setState({ value, rawPhone: "", phone: "" }, ()=>{console.error(this.state.value.label, "this.state.value.label")})

    };

    handleOnChange = (value, data) => {
        this.setState({ phone: value,  rawPhone: value.replace(/[^0-9]+/g,'').slice(data.dialCode.length), isPhoneReadOnly: false })
    };

    handleChange(e) {
        let fields = this.state.fields;
            fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }

    submituserReview() {
        const query = {
            "first_name": this.state.fields.firstName,
            "surname": this.state.fields.lastName,
            "email_address": this.state.fields.emailid,
            "password": this.state.fields.password,
            "country": this.state.countryName,
            "phone": this.state.phone
        };
        const queryParams = Object.keys(query).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
        }).join('&');
        fetch("https://privatedev.ifcmarkets.com/en/registration_demo", {
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: queryParams
        }).then((res) => {
            console.log('res = ', res);

            // alert("Review added successfully");
            this.props.attemptGetLatestReviews(this.props.sysname, this.props.countPerPage);
        })
            .catch((err)=>console.log(err));
    }

    submituserRegistrationForm(e) {
        console.warn("this.state",this.state)
        e.preventDefault();
        if (this.validateForm()) {
            let fields = {};
            fields["firstName"] = "";
            fields["lastName"] = "";
            fields["emailid"] = "";
            fields["password"] = "";
            fields["phoneNumber"] = "";
            // fields["massage"] = "";
            this.setState({fields: fields, phone: "", rawPhone:""});
            alert(`
        firstname-----------${this.state.fields.firstName}
        lastName------------${this.state.fields.lastName}
        email---------------${this.state.fields.emailid}
        password------------${this.state.fields.password}
        country-------------${this.state.countryName}
        phoneNumber---------${this.state.phone}
               `)

        }

    }

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "*Please enter your username.";
        }
        if (typeof fields["firstName"] !== "undefined") {
            if (!fields["firstName"].match(/[a-zA-Z ]*$/) || fields["firstName"].length > 20) {
                formIsValid = false;
                errors["firstName"] = "*Please enter more shortly name version.";
            }
        }
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "*Please enter massage.";
        }
        if (typeof fields["lastName"] !== "undefined") {
            if (!fields["lastName"].match(/[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["lastName"] = "*Please enter some text only.";
            }
        }
        if (!fields["emailid"]) {
            formIsValid = false;
            errors["emailid"] = "*Please enter your email-ID.";
        }
        if (typeof fields["emailid"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["emailid"])) {
                formIsValid = false;
                errors["emailid"] = "*Please enter valid email-ID.";
            }
        }
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "*Please enter massage.";
        }
        if (typeof fields["password"] !== "undefined") {
            if (!fields["password"].match(/[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["password"] = "*Please enter your password.";
            }
        }
        if (!this.state.rawPhone) {
            formIsValid = false;
            errors["phoneNumber"] = "*Please enter your phoneNumber.";
        }
        if (typeof this.state.rawPhone !== "undefined") {
            if (!(this.state.rawPhone && this.state.rawPhone.length)>0) {
                formIsValid = false;
                errors["phoneNumber"] = "*Please enter your phoneNumber.";
            }
        }
        // if(!this.state.ratingChanged){
        //     formIsValid = false;
        //     this.setState({rateMassage: ""})
        // }
        // if(formIsValid){
        //     this.setState({rateMassage: "none"});
        //     this.submituserReview()}
        // else {this.setState({rateMassage: ""})}



        if(formIsValid){
            this.submituserReview()
        }

        this.setState({
            errors: errors,
            // ratingChanged: 0
        });



        return formIsValid;


    }

    render() {

        let landingCase = false;

        const { t, language, platform } = this.props;

        let phraseName = '$_WANT_TO_EARN_OPEN';
        if(window.location.pathname.includes('crypto-converter')){
            phraseName = '$_CRYPTO_FORM_H2'
        }
        if(window.location.pathname.includes('landing-canada')){
            landingCase = true;
            phraseName = '$_WANT_TO_EARN_LANDING';
        }

        const isPhoneReadOnly = this.state.isPhoneReadOnly;

        console.log(isPhoneReadOnly);


        return (
            <div className="content">
                <div className="open_trd_acc row">

                    {renderHtml(t(`${phraseName}`))}

                    <a href={`https://private.ifcmarkets.com/${this.props.language}/login`}
                       className="btn btn-md button_green ga__register_form_cfd d-block d-sm-none mb-30"
                    >
                        {t('REGISTER_NOW')}
                    </a>
                    <div className="trd_acc_right_box d-none d-sm-block">
                        <form action={`https://private.ifcmarkets.com/${this.props.language}/registration_demo`} method="POST">
                            <div className="form_body">
                                <div className="form-group">
                                    <label className="field">
                                        {t('FIRST_NAME_REG')} <i className="required">*</i>
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="first_name"
                                            aria-label="first_name"
                                            value={this.state.fields.firstName}
                                            className={`${(this.state.errors.firstName)? "form-control-red":"form-control"}`}
                                            onChange={this.handleChange}
                                            required="required"/>
                                        <div className="errorMsg errortext">{this.state.errors.firstName}</div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="field">
                                        {t('LAST_NAME')} <i className="required">*</i>
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="surname"
                                            aria-label="surname"
                                            value={this.state.fields.lastName}
                                            className={`${(this.state.errors.lastName)? "form-control-red":"form-control"}`}
                                            onChange={this.handleChange}
                                            required="required"/>
                                        <div className="errorMsg errortext">{this.state.errors.lastName}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="field">
                                        {t('&_EMAIL_LOGIN')} <i className="required">*</i>
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="email_address"
                                            aria-label="email_address"
                                            value={this.state.fields.emailid}
                                            onChange={this.handleChange}
                                            className={`${(this.state.errors.emailid)? "form-control-red":"form-control"}`}
                                            required="required"/>
                                        <div className="errorMsg errortext">{this.state.errors.emailid}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="field">
                                        {t('$_PASSWORD')} <i className="required">*</i>
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="password"
                                            name="password"
                                            aria-label="password"
                                            value={this.state.fields.password}
                                            onChange={this.handleChange}
                                            className={`${(this.state.errors.password)? "form-control-red":"form-control"}`}
                                            required="required"/>
                                        <div className="errorMsg errortext">{this.state.errors.password}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="field">
                                        {t('COUNTRY')} <i className="required">*</i>

                                    </label>
                                    <div className="input-group">
                                        <Select
                                            options={countysList}
                                            value={this.state.value}
                                            aria-label="ցօւնտռը"
                                            onChange={this.changeHandler}
                                            name={"country"}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="field" name="phone">
                                        {t('IBAPPLICATION_PHONE')} <i className="required">*</i>
                                    </label>
                                    <div className="input-group">
                                        {
                                            (this.state.errors.phoneNumber)
                                                ?
                                                <ReactPhoneInput
                                                    inputExtraProps={{
                                                        name: "phone",
                                                        readOnly: isPhoneReadOnly,
                                                    }}
                                                    containerStyle={PhoneInputStyle}
                                                    defaultCountry={`${
                                                        this.state.value &&
                                                        this.state.value.value.toString().toLowerCase()
                                                    }`}
                                                    value={this.state.phone}
                                                    onChange={this.handleOnChange}
                                                    id={"phone"}
                                                />
                                                :
                                                <ReactPhoneInput
                                                    inputExtraProps={{
                                                        name: "phone",
                                                        readOnly: isPhoneReadOnly,
                                                    }}
                                                    defaultCountry={`${
                                                        this.state.value &&
                                                        this.state.value.value.toString().toLowerCase()
                                                    }`}
                                                    value={this.state.phone}
                                                    onChange={this.handleOnChange}
                                                    id={"phone"}
                                                />
                                        }

                                        <div className="errorMsg errortext">{this.state.errors.phoneNumber}</div>
                                    </div>
                                </div>

                                {/*<div className="form-group row" id="wants_to_subscribe_corporate">*/}
                                {/*    <div className="field row">*/}
                                {/*        <div className="input">*/}
                                {/*            <input type="checkbox"*/}
                                {/*                   name="wants_to_subscribe"*/}
                                {/*                   onClick={()=> {if(this.state.check.length>1){*/}
                                {/*                       this.setState({check: ""})} else {*/}
                                {/*                           this.setState({check: "checked"})*/}
                                {/*                       }*/}
                                {/*                   }}*/}
                                {/*                   className="input-block-level checkbox_for_slider float_right"*/}
                                {/*                   checked={this.state.check}*/}
                                {/*                   id="wants_to_subscribe"*/}
                                {/*                   value={`${(this.state.check.length)?"on":""}`}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*        <div className="input_info"><label*/}
                                {/*            className="control-label label_for_slider widelabel"*/}
                                {/*            htmlFor="wants_to_subscribe">*/}
                                {/*            {t('&_AGREEMENT_NEWS')}*/}
                                {/*            /!*I agree to receive news &amp; analytics*!/*/}
                                {/*            </label>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="form-group" id="wants_to_subscribe_corporate">
                                    <label></label>
                                    <div className="field">
                                        <button
                                            className="btn btn-md button_green ga__register_form_cfd"
                                            type={"submit"}
                                        >
                                            {/*Register Now*/}
                                            {t('REGISTER_NOW')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <input type={"hidden"} name={"website"} value={"1"}/>
                        </form>
                    </div>
                    <div className="trd_acc_left_box">
                        <div className="trd_acc_head row">
                            {!landingCase && <img src="/assets/img/logo.svg" alt="IFC Markets Forex Broker" width="117" height="51" className={'d-none d-sm-block'}/> }

                            {!landingCase ? (
                                renderHtml2(t('$_GLOBAL_REGULATED'))

                            ) : (
                                renderHtml2(t('$_GLOBAL_REGULATED_2'))
                            )}

                            {/*<div className="title_acc_head">*/}
                            {/*/!*{renderHtml2(t('$_GLOBAL_REGULATED'))}*!/*/}
                            {/*<span>IFC Markets</span>*/}
                            {/*<span>Global Regulated Forex & CFD Broker </span>*/}
                            {/*</div>*/}
                        </div>
                        <div>
                            <ul className="ul-tick">
                                <li>
                                    {t('$_138K_CLIENTS')}
                                    {/*138K Clients from 80 Countries*/}
                                </li>
                                <li>
                                    {t('$_13_YEARS_HISTORY')}
                                    {/*13 years history in Financial services*/}
                                </li>
                                <li>
                                    {t('$_600_TRADING_INSTRUMENTS')}
                                    {/*600+ Trading Instruments*/}
                                </li>
                                <li>
                                    {t('$_ONLINE_SUPPORT')}
                                    {/*Online 24/5 Support in 18 Languages*/}
                                </li>
                                <li>
                                    {t('$_LOW_SPREADS')}
                                    {/*Instant Execution Low Spreads from 0.4 pip*/}
                                </li>
                                <li>
                                    {t('&_REGULATED_BY')}
                                    {/*Regulated by BVI FSC*/}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        language: state.language,
    }),
    // dispatch => ({
    //     onPlatformChange: (platform) => dispatch(onChangePlatformType(platform)),
    // })
)(withRouter(translate('common')(RegisterForm2)));