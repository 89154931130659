import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {webs} from "../../Socket";
import {WEB_SOCKET_URL} from "../../helpers/constants";
const TopCryptos = ["BTC", "ETH", "LTC", "XRP", "BCH"];
const Auxiliary =  ["BTC", "USD", "EUR", "GBP", "CAD", "AUD"]
class TopCryptoPairs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cryptoData: {
                "BTC":[],
                "ETH":[],
                "LTC":[],
                "XRP":[],
                "BCH":[],
            },
        };
    };

    componentDidMount() {
        this.getCryptoPairs();
    }

    getRate(symbol) {
        let cryptoData = this.state.cryptoData;
        const newReq = {
            "cmd": "exchange",
            "from": symbol,
            "to": "USD",
            "auxiliary": Auxiliary
        };
        const WEB = webs(WEB_SOCKET_URL);
        WEB.send(newReq, (data) => {
            cryptoData[symbol] = data.auxiliary;
            this.setState({cryptoData: cryptoData});
        });
    }

    getCryptoPairs() {
        TopCryptos.forEach((item) => {
            this.getRate(item);
        });

    }

    render() {
        return (
            <div className={"overflow mb30"}>
            <table className={"table table-bordered topCryptoTable"}>
                <thead>
                <th></th>
                    { Auxiliary.map((item, index) => {
                        return <th  className={` ${item}`} key={index}>
                            <div className={"d-flex align-center"}>
                                <span title={`${item} Exchange Rate`}>{item}</span>
                                <span className={`flag_image flag_24 ${item}`}></span>
                            </div>
                        </th>
                    })
                    }
                </thead>
                <tbody>
                {
                    Object.values(this.state.cryptoData).map((item, index) => {
                        let currName = Object.keys(this.state.cryptoData)[index];
                        return <tr  key={index}>
                            <td>

                                <div className={"d-flex align-center"}>
                                    <span className={`flag_image flag_24 ${currName}`}></span>
                                    <span className="mr-2" title={`${currName} Exchange Rate`}>{currName}</span>
                                </div>
                                </td>
                            { item.map((item2, index2) => {
                                    return <td  key={index2}>
                                        {Number(item2['rate']).toFixed(2)}
                                    </td>
                                })
                            }
                        </tr>
                    })
                }
                </tbody>
            </table>
            </div>
        )
    }
}

export default withRouter(TopCryptoPairs)



