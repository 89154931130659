import React from 'react';
import {webs} from "../Socket";
import {Helmet} from "react-helmet";
import {translate} from "react-i18next";
import {WEB_SOCKET_URL} from "../helpers/constants";


const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props}}>
</span>);


const mg_category_select = [
    {
        value: "forex",
        name: "Currency Pairs"
    },
    {
        value: "indices",
        name: "Indices"
    },
    {
        value: "commodities",
        name: "Commodities"
    },
    {
        value: "stocks",
        name: "Stocks"
    },
    {
        value: "precious-metals",
        name: "Precious Metals"
    },
    {
        value: "gold-trading",
        name: "Gold Instruments"
    },
    {
        value: "personal-instrument-pci",
        name: "PCI Library"
    },
    {
        value: "cfd-etf",
        name: "ETF"
    }
];

class MarginCalcForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selectedCategory: "forex",
            selectedInstrument: "",
            buy_sell: "sell",
            leverage: "100",
            data: {
                base_unit: "AUD",
                name: "AUDCAD",
                pippervalue: 0.0001,
                size_of_lot: 100000,
            },
            errorMessage: "none",
            value: "",
            error: "",
            error_descr: "",
            margin_in_base_curr: 0,
            margin_in_eur: 0,
            margin_in_jpy: 0,
            margin_in_usd: 0,
            socketData: "---/---",
        }
    }

    componentDidMount() {
        const self = this;
        fetch("https://www.ifcm.co.uk:2083/calculation/instruments-data?group=forex")
            .then(res => res.json())
            .then(instruments => {
                self.setState({instruments});
            })

        const newReq ={
            "cmd": "quote",
            "symbol": this.state.data.name
        };

        const web_sock = webs(WEB_SOCKET_URL);
        web_sock.send(newReq, (data) => this.setState({socketData: `${data.ask}/${data.bid}` }));


    }
    selectCategory(e){
        this.setState({selectedCategory: e.target.value});
        const self = this;
        fetch("https://www.ifcm.co.uk:2083/calculation/instruments-data?group=" + e.target.value)
            .then(res => res.json())
            .then(instruments => {
                // console.error("instruments", instruments);
                self.setState({instruments});
            })
    }

    selectInstrument(e){
        const name = e.target.value;
        this.setState({selectedInstrument: e.target.value});
        const data = this.state.instruments.find(e=> e.name === name);
        this.setState({ data });
        const newReq ={
            "cmd": "quote",
            "symbol": this.state.data.name
        };

        const web_sock = webs(WEB_SOCKET_URL);
        web_sock.send(newReq, (data) =>{this.setState({socketData: `${data.ask}/${data.bid}` });
        });

    }

    selectLeverage(e){
        this.setState({leverage: e.target.value})
    }

    calculate(){
        if(this.state.value !==""){
            this.setState({errorMessage: "none"});

            const query = {
                'platform': this.props.platform,
                'instrument': this.state.data.name,
                'volume': this.state.value,
                'leverage': this.state.leverage,
            }

            const searchParams = Object.keys(query).map((key)=> {
                return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
            }).join('&');

            fetch("https://www.ifcm.co.uk:2083/calculation/margin", {
                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: searchParams
            }).then((res) => res.json())
                .then((data) =>  this.setState({
                    margin_in_base_curr: data.margin_in_base_curr,
                    margin_in_eur: data.margin_in_eur,
                    margin_in_jpy: data.margin_in_jpy,
                    margin_in_usd: data.margin_in_usd,

                }))
                .catch((err)=>console.log(err));
        } else {
            this.setState({
                errorMessage: "",
                margin_in_base_curr: 0,
                margin_in_eur: 0,
                margin_in_jpy: 0,
                margin_in_usd: 0,
            });

        };

    };

    render(){
        const {instruments} = this.state;
        const { t } = this.props;

        return (
            <div>
                <Helmet>
                    <title>{`${(t('&_MARGIN_CALCULATOR'))} | CFDRATES`}</title>
                    <meta name="description" content={(t('&_MARGIN_CALCULATOR_TXT'))} />
                    <meta name="theme-color" content="#008f68" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="__main_form">
                <div className="filer_wrap">
                    <div className="partss">
                        <label className="mini_label">
                            {renderHtml(t('SELECT_TYPE'))}
                        </label>
                        <div className="div_select">
                            <div className="select_icon"/>
                            <select id="pl_category_select" className="for_profit_section"
                                    onChange={e =>this.selectCategory(e)}
                            >
                                {
                                   mg_category_select.map((item,index) => {
                                        return <option key={index} value={item.value} >{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="partss">
                        <label className="mini_label">
                            {renderHtml(t('SELECT_NAME'))}
                        </label>
                        <div className="div_select">
                            <div className="select_icon"/>
                            <select id="profit_loss_calc_form_instrument"
                                    onChange={e => this.selectInstrument(e)}
                                    className="fleft for_profit_section">
                                {
                                    instruments && instruments.map((item,index) => {
                                        return  <option value={item.value} key={index}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="partss">
                        <label className="mini_label">
                            Bid / Ask
                        </label>
                        <div className="bid_ask_wrapper">
                            <div className="bid_ask">{this.state.socketData}</div>
                            <div className="bid_ask_unit"/>
                        </div>
                    </div>
                </div>
                <div id="deal_dir_wrap"  style={{display: this.props.hideBuySell ? "none": ""}}>
                    <div className="calc_radio_buy">
                        <label className="customs">
                            <span className="top_to">
                                {renderHtml(t('BUY'))}
                                {/*BUY*/}
                            </span>
                            <input type="radio"
                                   name="deal_dir_front"
                                   value="buy"
                                   onChange={(e)=> this.setState({buy_sell: e.target.value})}
                            />
                            <span className="checkmark"/>
                        </label>
                    </div>
                    <div className="calc_radio_sell">
                        <label htmlFor="" className="customs">
                            <span className="top_to">
                                {/*SELL*/}
                                {renderHtml(t('SELL'))}
                            </span>
                            <input type="radio"
                                   name="deal_dir_front"
                                   value="sell"
                                   // checked={true}
                                   defaultChecked={true}
                                   onChange={(e)=> this.setState({buy_sell: e.target.value})}
                            />
                            <span className="checkmark"/>
                        </label>
                    </div>
                </div>
                <div className="calc_input_wrap">
                    <div className="partss padd">
                        <label htmlFor="leverage" className="mini_label">
                            <span>{renderHtml(t('CALCULATOR_LEVERAGE'))} </span>
                        </label>
                        <select onChange={this.selectLeverage.bind(this)}
                                id="mg_leverage"
                                name="leverage"
                                className="big_select"
                                value={this.state.leverage}
                                defaultValue={this.state.leverage}
                        >
                                 <option value='1'> 1:1 </option>
                                 <option value='4'> 1:4 </option>
                                 <option value='10'> 1:10 </option>
                                 <option value='20'> 1:20 </option>
                                 <option value='25'> 1:25 </option>
                                 <option value='50'> 1:50 </option>
                                 <option value='100'> 1:100 </option>
                                 <option value='200'> 1:200 </option>
                                 <option value='300'> 1:300 </option>
                                 <option value='400'> 1:400 </option>
                        </select>
                        </div>
                    <div className="partss padd">
                        <label htmlFor="volume" className="mini_label">
                            <span className="__only-nttx" style={{display:"none"}} >
                                <span className="curr_only">
                                    {/*Volume (units of base currency)*/}
                                    {renderHtml(t('$_UNITS_OF_BASE'))}
                                </span>
                            </span>
                            <span className="non_curr_only" >
                                {renderHtml(t('CALCULATOR_VOLUME_NONCURR'))}
                                {/*Position volume <br/> (asset unit)*/}
                            </span>
                            <span className="__only-mt4" >
                                 {renderHtml(t('CALCULATOR_VOLUME_MT4'))}
                                {/*{`Deal volume `}*/}
                            </span>
                        </label>
                        <input type="number"
                               name="volume"
                               className="big_select"
                               onChange={(e) => {this.setState( {value : e.target.value})} }
                        />
                    </div>
                </div>
                <div id="confirmBoxWrapper"
                     className="confirm_calculate"
                     style={{display: this.state.errorMessage}}
                >
                    {renderHtml(t('CALC_NO_DATA_ERROR'))}
                </div>
                <div className="marg_button">
                    <button id="calcprofit"
                            className="button_green big_button"
                            onClick={this.calculate.bind(this)}
                    >
                        {renderHtml(t('CALCULATOR_CALCULATE'))}
                    </button>
                </div>
                <div className="four_components">
                    <div className="one_component">
                        <div className="mobile_component">
                            <div className="fflag margin_in_base_curr_flagpl">
                                <span className="flag_24 AUD"/>
                            </div>
                            <div className="label_text">
                                {renderHtml(t('CALCULATOR_MARGININBC'))}
                            </div>
                        </div>
                        <div className="for_margin">
                            <span className="num margin_in_base_curr">{this.state.margin_in_base_curr}</span>
                            <span className="unit margin_in_base_curr_unit">{` AUD `}</span>
                        </div>
                    </div>
                    <div className="one_component">
                        <div className="mobile_component">
                            <div className="fflag">
                                <span className="flag_24 USD"/>
                            </div>
                            <div className="label_text">
                                {renderHtml(t('CALCULATOR_MARGININUSD'))}
                            </div>
                        </div>
                        <div className="for_margin">
                            <span className="num margin_in_usd">{this.state.margin_in_usd}</span>
                            <span className="unit">{` USD `}</span>
                        </div>
                    </div>
                    <div className="one_component">
                        <div className="mobile_component">
                            <div className="fflag">
                                <span className="flag_24 JPY"/>
                            </div>
                            <div className="label_text">
                                {renderHtml(t('CALCULATOR_MARGININJPY'))}
                            </div>
                        </div>
                        <div className="for_margin">
                            <span className="num margin_in_jpy">{this.state.margin_in_jpy}</span>
                            <span className="unit">{` JPY `}</span>
                        </div>
                    </div>
                    <div className="one_component">
                        <div className="mobile_component">
                            <div className="fflag">
                                <span className="flag_24 EUR"/>
                            </div>
                            <div className="label_text">
                                {renderHtml(t('CALCULATOR_MARGININEUR'))}
                            </div>
                        </div>
                        <div className="for_margin">
                            <span className="num margin_in_eur">{this.state.margin_in_eur}</span>
                            <span className="unit">{` EUR `}</span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}
export default (translate('common')(MarginCalcForm));
