import React from 'react';
import {connect} from "react-redux";
import { getAvailableCouplesCrypto, getExchangeCrypto} from "../../redux/actions/userActions";
import {translate} from "react-i18next";

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props}}>
</span>);

const getAvailab = () => {
    return ["BCHUSD","BTCUSD","ETHUSD","LTCUSD","XRPUSD"]
}



class BuySellCrypto extends React.Component{


    componentDidMount() {
        document.body.dir = `${(this.props.language === 'ar' || this.props.language === 'fa') ? 'rtl' : "ltr"}`;
        getAvailab();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.curCrypto !== this.props.curCrypto) || (prevProps.curCrypto2 !== this.props.curCrypto2) || (prevProps.language !== this.props.language)) {
            this.props.getExchangeCrypto(this.props.curCrypto2, this.props.curCrypto2);
        }
    }

    render(){
        const {curCrypto, curCrypto2, dataCrypto, numberCrypto} = this.props;
        const {t} = this.props;
        const ifc_domain = `$_IFC_DOMAIN`;
        return(
            <div>
                <h2>
                    {
                        renderHtml(t('$_QUOTE_H2', {
                            CUR1:  `${curCrypto}`,
                            CUR2: `${curCrypto2}`,
                            CUR1_PLURAL: (t(`${curCrypto}_PLURAL`)),
                            CUR2_PLURAL: (t(`${curCrypto2}_PLURAL`)),
                            CUR1_DATIVE: (t(`${curCrypto}_DATIVE`)),
                            CUR2_DATIVE: (t(`${curCrypto2}_DATIVE`)),
                            CUR1_MULTIPLE: (t(`${curCrypto}_MULTIPLE`)),
                            CUR2_MULTIPLE: (t(`${curCrypto2}_MULTIPLE`)),
                            CUR1_TRANS: (t(`${curCrypto}`)),
                            CUR2_TRANS: (t(`${curCrypto2}`)),
                            AMOUNT: numberCrypto
                        }))
                    }
                </h2>
                <div className="display_of_button ga__sell_cfd">
                    <a href={`${(t(ifc_domain))}/open-trading-account`}
                       rel="noopener noreferrer"
                       target="_blank"
                    >
                        <div className="buy_text b_s_p button_dark_red">
                            <div className="opacity_div">
                                {renderHtml(t('SELL'))}
                            </div>
                        </div>
                    </a>
                    <div className="cur_cur b_s_p ga__buy_cfd">{curCrypto}/{curCrypto2}</div>
                    <a href={`${(t(ifc_domain))}/open-trading-account`}
                       rel="noopener noreferrer"
                       target="_blank"
                    >
                        <div className="buy_text b_s_p button_dark_green">
                            <div className="opacity_div">
                                {renderHtml(t('BUY'))}
                            </div>
                        </div>
                    </a>
                    <div
                        className="bt current_instrument_bid">{dataCrypto.bid || "---"}</div>
                    <div
                        className="bt current_instrument_ask">{dataCrypto.ask || "---"}</div>
                </div>
                <div className="stat_changes">
                    <div className="pci_status_trading">
                                                            <span>
                                                                {renderHtml(t('TRADE_STATUS'))}
                                                                :&nbsp;</span>
                        <span className="live_closed" style={{display: "none"}}>
                                                        <span>
                                                            {renderHtml(t('LIVE_CLOSED'))}
                                                        </span>
                                                    </span>
                        <span className="live_trading to_up"
                              style={{display: "inline-block"}}>
                                                        <span>
                                                            <span/>
                                                            {renderHtml(t('LIVE_TRADING'))}
                                                        </span>
                                                    </span>
                    </div>
                    <div className="pci_change_value">
                        {renderHtml(t('CHANGE'))}
                        :&nbsp;
                        <span
                            className={`__changes to_${dataCrypto.state}`}>{dataCrypto.percent}%</span>
                    </div>
                    <div className="max_min">
                        <div className="max_min_blocks">
                            <div className="lbox">{renderHtml(t('PREV_CLOSING'))}</div>
                            <div className="rbox __close_bid">{dataCrypto.close}</div>
                        </div>
                        <div className="max_min_blocks">
                            <div className="lbox">{renderHtml(t('OPENING'))}</div>
                            <div className="rbox __open_bid">{dataCrypto.open}</div>
                        </div>
                        <div className="max_min_blocks">
                            <div className="lbox">{renderHtml(t('MAX_PRICE'))}</div>
                            <div className="rbox __max_bid to_up">{dataCrypto.high}</div>
                        </div>
                        <div className="max_min_blocks">
                            <div className="lbox">{renderHtml(t('MIN_PRICE'))}</div>
                            <div className="rbox __min_bid to_down">{dataCrypto.low}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(
    state => ({
        curCrypto: state.curCrypto,
        curCrypto2: state.curCrypto2,
        dataCrypto: state.dataCrypto,
        numberCrypto: state.numberCrypto,
        language: state.language

    }),
    dispatch => ({
        getExchangeCrypto: (curCrypto, curCrypto2) => dispatch(getExchangeCrypto(curCrypto, curCrypto2)),
        getAvailableCouplesCrypto: () => dispatch(getAvailableCouplesCrypto())
    })
)(translate('common')(BuySellCrypto));

