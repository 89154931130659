import React from 'react';
import {translate} from "react-i18next";
import {connect} from "react-redux";


const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);


class WordCurrenciesItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            moreInfo: "none",
            arrow: "",
            search: this.props.searchText,
            description: "",
            instruments: [],
            bid: "---",
            ask: "---"
        };
    }

    showCurDesc(){
        if(this.state.arrow === ""){
            this.setState({arrow: "u_turn", moreInfo: "table-row"})

            fetch(`https://www.ifcm.co.uk:2083/instrument/currency/description?currency=${this.props.abbreviation}`, {
                method: 'get',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).then((res) => res.json())
                .then((data) => {
                        if(data.instruments.length < 3) {
                            this.setState({
                                description: data.description,
                                instruments: data.instruments,
                            })
                        } else this.setState({
                            description: data.description,
                            instruments: data.instruments.slice(0,4),
                        })
                    }
                )
                .catch((err)=>console.log(err));
        }
        else
            this.setState({
                arrow: "",
                moreInfo: "none",
                description: "",
                instruments: [],
            })
    }




    render(){
        const {name, code, symbol, index } = this.props;
        const { t } = this.props;

        if(this.props.abbreviation === this.props.searchText.toUpperCase()){
            setTimeout(
                function() {
                    let elm = document.getElementById("red");
                    elm.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                }
                    .bind(this),
                10
            );
        }

        return[
         <tr key={index}
                   className={`${(index % 2 === 1) ? "odd" : "even"} curr_${name} __curow `}
                   id={`${(name === this.props.searchText.toUpperCase()) ? "red" : "none"}`}>
            <td className="__currencies_name" data-currency={name}>
                                    <span className={`flag_24 ${name}`}
                                          title={`${name} Currency Name, ISO Code and Symbol`}>
                                    </span>
                {(t(`${name}`))}
            </td>
            <td><span title={`${name} Currency Abbreviation`}>{name} </span></td>
            <td><span title={`${name} Currency Code`}>{code} </span></td>
            <td><span title={`${name} Currency Symbol`}>{symbol} </span></td>
            <td><span>
                <a href={`https://www.cfdrates.com/${this.props.language}/${name.toLowerCase()}-usd`}>
                    {renderHtml(t('X_USD', {
                        cur1: `${name}`,
                    }))}
                </a>
            </span></td>
        </tr>
        ]
    }
}
export default connect(
    state => ({
        language: state.language,
    }))(translate('common')(WordCurrenciesItem));
// export default  (translate('common')(WordCurrenciesItem));