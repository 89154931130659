import React from "react";

export const countysList = [
    {value: "AF", label: "Afghanistan"},
    // {value: "AX", label: "Åland Islands"},
    {value: "AL", label: "Albania"},
    {value: "DZ", label: "Algeria"},
    {value: "AS", label: "American Samoa"},
    {value: "AD", label: "Andorra"},
    {value: "AO", label: "Angola"},
    {value: "AI", label: "Anguilla"},
    // {value: "AQ", label: "Antarctica"},
    {value: "AG", label: "Antigua and Barbuda"},
    {value: "AR", label: "Argentina"},
    {value: "AM", label: "Armenia"},
    {value: "AW", label: "Aruba"},
    {value: "AU", label: "Australia"},
    {value: "AT", label: "Austria"},
    {value: "AZ", label: "Azerbaijan"},
    {value: "BS", label: "Bahamas"},
    {value: "BH", label: "Bahrain"},
    {value: "BD", label: "Bangladesh"},
    {value: "BB", label: "Barbados"},
    {value: "BY", label: "Belarus"},
    {value: "BE", label: "Belgium"},
    {value: "BZ", label: "Belize"},
    {value: "BJ", label: "Benin"},
    {value: "BM", label: "Bermuda"},
    {value: "BT", label: "Bhutan"},
    {value: "BO", label: "Bolivia, Plurinational State of"},
    {value: "BQ", label: "Bonaire, Sint Eustatius and Saba"},
    {value: "BA", label: "Bosnia and Herzegovina"},
    {value: "BW", label: "Botswana"},
    {value: "BV", label: "Bouvet Island"},
    {value: "BR", label: "Brazil"},
    {value: "IO", label: "British Indian Ocean Territory"},
    {value: "BN", label: "Brunei Darussalam"},
    {value: "BG", label: "Bulgaria"},
    {value: "BF", label: "Burkina Faso"},
    {value: "BI", label: "Burundi"},
    {value: "KH", label: "Cambodia"},
    {value: "CM", label: "Cameroon"},
    {value: "CA", label: "Canada"},
    {value: "CV", label: "Cape Verde"},
    {value: "KY", label: "Cayman Islands"},
    {value: "CF", label: "Central African Republic"},
    {value: "TD", label: "Chad"},
    {value: "CL", label: "Chile"},
    {value: "CN", label: "China"},
    // {value: "CX", label: "Christmas Island"},
    // {value: "CC", label: "Cocos (Keeling) Islands"},
    {value: "CO", label: "Colombia"},
    {value: "KM", label: "Comoros"},
    {value: "CG", label: "Congo"},
    {value: "CD", label: "Congo, the Democratic Republic of the"},
    {value: "CK", label: "Cook Islands"},
    {value: "CR", label: "Costa Rica"},
    {value: "CI", label: "Côte d'Ivoire"},
    {value: "HR", label: "Croatia"},
    {value: "CU", label: "Cuba"},
    {value: "CW", label: "Curaçao"},
    {value: "CY", label: "Cyprus"},
    {value: "CZ", label: "Czech Republic"},
    {value: "DK", label: "Denmark"},
    {value: "DJ", label: "Djibouti"},
    {value: "DM", label: "Dominica"},
    {value: "DO", label: "Dominican Republic"},
    {value: "EC", label: "Ecuador"},
    {value: "EG", label: "Egypt"},
    {value: "SV", label: "El Salvador"},
    {value: "GQ", label: "Equatorial Guinea"},
    {value: "ER", label: "Eritrea"},
    {value: "EE", label: "Estonia"},
    {value: "ET", label: "Ethiopia"},
    {value: "FK", label: "Falkland Islands (Malvinas)"},
    {value: "FO", label: "Faroe Islands"},
    {value: "FJ", label: "Fiji"},
    {value: "FI", label: "Finland"},
    {value: "FR", label: "France"},
    {value: "GF", label: "French Guiana"},
    {value: "PF", label: "French Polynesia"},
    // {value: "TF", label: "French Southern Territories"},
    {value: "GA", label: "Gabon"},
    {value: "GM", label: "Gambia"},
    {value: "GE", label: "Georgia"},
    {value: "DE", label: "Germany"},
    {value: "GH", label: "Ghana"},
    {value: "GI", label: "Gibraltar"},
    {value: "GR", label: "Greece"},
    {value: "GL", label: "Greenland"},
    {value: "GD", label: "Grenada"},
    {value: "GP", label: "Guadeloupe"},
    {value: "GU", label: "Guam"},
    {value: "GT", label: "Guatemala"},
    // {value: "GG", label: "Guernsey"},
    {value: "GN", label: "Guinea"},
    {value: "GW", label: "Guinea-Bissau"},
    {value: "GY", label: "Guyana"},
    {value: "HT", label: "Haiti"},
    // {value: "HM", label: "Heard Island and McDonald Islands"},
    // {value: "VA", label: "Holy See (Vatican City State)"},
    {value: "HN", label: "Honduras"},
    {value: "HK", label: "Hong Kong"},
    {value: "HU", label: "Hungary"},
    {value: "IS", label: "Iceland"},
    {value: "IN", label: "India"},
    {value: "ID", label: "Indonesia"},
    {value: "IR", label: "Iran, Islamic Republic of"},
    {value: "IQ", label: "Iraq"},
    {value: "IE", label: "Ireland"},
    // {value: "IM", label: "Isle of Man"},
    {value: "IL", label: "Israel"},
    {value: "IT", label: "Italy"},
    {value: "JM", label: "Jamaica"},
    {value: "JP", label: "Japan"},
    // {value: "JE", label: "Jersey"},
    {value: "JO", label: "Jordan"},
    {value: "KZ", label: "Kazakhstan"},
    {value: "KE", label: "Kenya"},
    {value: "KI", label: "Kiribati"},
    {value: "KP", label: "Korea, Democratic People's Republic of"},
    {value: "KR", label: "Korea, Republic of"},
    {value: "KW", label: "Kuwait"},
    {value: "KG", label: "Kyrgyzstan"},
    {value: "LA", label: "Lao People's Democratic Republic"},
    {value: "LV", label: "Latvia"},
    {value: "LB", label: "Lebanon"},
    {value: "LS", label: "Lesotho"},
    {value: "LR", label: "Liberia"},
    {value: "LY", label: "Libya"},
    {value: "LI", label: "Liechtenstein"},
    {value: "LT", label: "Lithuania"},
    {value: "LU", label: "Luxembourg"},
    {value: "MO", label: "Macao"},
    {value: "MK", label: "Macedonia, the Former Yugoslav Republic of"},
    {value: "MG", label: "Madagascar"},
    {value: "MW", label: "Malawi"},
    {value: "MY", label: "Malaysia"},
    {value: "MV", label: "Maldives"},
    {value: "ML", label: "Mali"},
    {value: "MT", label: "Malta"},
    {value: "MH", label: "Marshall Islands"},
    {value: "MQ", label: "Martinique"},
    {value: "MR", label: "Mauritania"},
    {value: "MU", label: "Mauritius"},
    // {value: "YT", label: "Mayotte"},
    {value: "MX", label: "Mexico"},
    {value: "FM", label: "Micronesia, Federated States of"},
    {value: "MD", label: "Moldova, Republic of"},
    {value: "MC", label: "Monaco"},
    {value: "MN", label: "Mongolia"},
    {value: "ME", label: "Montenegro"},
    {value: "MS", label: "Montserrat"},
    {value: "MA", label: "Morocco"},
    {value: "MZ", label: "Mozambique"},
    {value: "MM", label: "Myanmar"},
    {value: "NA", label: "Namibia"},
    {value: "NR", label: "Nauru"},
    {value: "NP", label: "Nepal"},
    {value: "NL", label: "Netherlands"},
    {value: "NC", label: "New Caledonia"},
    {value: "NZ", label: "New Zealand"},
    {value: "NI", label: "Nicaragua"},
    {value: "NE", label: "Niger"},
    {value: "NG", label: "Nigeria"},
    {value: "NU", label: "Niue"},
    {value: "NF", label: "Norfolk Island"},
    {value: "MP", label: "Northern Mariana Islands"},
    {value: "NO", label: "Norway"},
    {value: "OM", label: "Oman"},
    {value: "PK", label: "Pakistan"},
    {value: "PW", label: "Palau"},
    {value: "PS", label: "Palestine, State of"},
    {value: "PA", label: "Panama"},
    {value: "PG", label: "Papua New Guinea"},
    {value: "PY", label: "Paraguay"},
    {value: "PE", label: "Peru"},
    {value: "PH", label: "Philippines"},
    // {value: "PN", label: "Pitcairn"},
    {value: "PL", label: "Poland"},
    {value: "PT", label: "Portugal"},
    {value: "PR", label: "Puerto Rico"},
    {value: "QA", label: "Qatar"},
    {value: "RE", label: "Réunion"},
    {value: "RO", label: "Romania"},
    {value: "RU", label: "Russian Federation"},
    {value: "RW", label: "Rwanda"},
    {value: "BL", label: "Saint Barthélemy"},
    {value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha"},
    {value: "KN", label: "Saint Kitts and Nevis"},
    {value: "LC", label: "Saint Lucia"},
    {value: "MF", label: "Saint Martin (French part)"},
    {value: "PM", label: "Saint Pierre and Miquelon"},
    {value: "VC", label: "Saint Vincent and the Grenadines"},
    {value: "WS", label: "Samoa"},
    {value: "SM", label: "San Marino"},
    {value: "ST", label: "Sao Tome and Principe"},
    {value: "SA", label: "Saudi Arabia"},
    {value: "SN", label: "Senegal"},
    {value: "RS", label: "Serbia"},
    {value: "SC", label: "Seychelles"},
    {value: "SL", label: "Sierra Leone"},
    {value: "SG", label: "Singapore"},
    {value: "SX", label: "Sint Maarten (Dutch part)"},
    {value: "SK", label: "Slovakia"},
    {value: "SI", label: "Slovenia"},
    {value: "SB", label: "Solomon Islands"},
    {value: "SO", label: "Somalia"},
    {value: "ZA", label: "South Africa"},
    {value: "GS", label: "South Georgia and the South Sandwich Islands"},
    {value: "SS", label: "South Sudan"},
    {value: "ES", label: "Spain"},
    {value: "LK", label: "Sri Lanka"},
    {value: "SD", label: "Sudan"},
    {value: "SR", label: "Surilabel"},
    {value: "SJ", label: "Svalbard and Jan Mayen"},
    {value: "SZ", label: "Swaziland"},
    {value: "SE", label: "Sweden"},
    {value: "CH", label: "Switzerland"},
    {value: "SY", label: "Syrian Arab Republic"},
    {value: "TW", label: "Taiwan, Province of China"},
    {value: "TJ", label: "Tajikistan"},
    {value: "TZ", label: "Tanzania, United Republic of"},
    {value: "TH", label: "Thailand"},
    {value: "TL", label: "Timor-Leste"},
    {value: "TG", label: "Togo"},
    {value: "TK", label: "Tokelau"},
    {value: "TO", label: "Tonga"},
    {value: "TT", label: "Trinidad and Tobago"},
    {value: "TN", label: "Tunisia"},
    {value: "TR", label: "Turkey"},
    {value: "TM", label: "Turkmenistan"},
    {value: "TC", label: "Turks and Caicos Islands"},
    {value: "TV", label: "Tuvalu"},
    {value: "UG", label: "Uganda"},
    {value: "UA", label: "Ukraine"},
    {value: "AE", label: "United Arab Emirates"},
    {value: "GB", label: "United Kingdom"},
    {value: "US", label: "United States"},
    // {value: "UM", label: "United States Minor Outlying Islands"},
    {value: "UY", label: "Uruguay"},
    {value: "UZ", label: "Uzbekistan"},
    {value: "VU", label: "Vanuatu"},
    {value: "VE", label: "Venezuela, Bolivarian Republic of"},
    {value: "VN", label: "Viet Nam"},
    {value: "VG", label: "Virgin Islands, British"},
    {value: "VI", label: "Virgin Islands, U.S."},
    {value: "WF", label: "Wallis and Futuna"},
    // {value: "EH", label: "Western Sahara"},
    {value: "YE", label: "Yemen"},
    {value: "ZM", label: "Zambia"},
    {value: "ZW", label: "Zimbabwe"}
];


export const fakeDB = ['EUR','AUD','CAD','CHF','CNH','CZK','DKK','GBP','HKD','JPY',
    'MXN','NOK','NZD','PLN','RUB','SEK','SGD','TRY','USD','ZAR',
    'COP','ALL','MDL','AMD','ANG','GNF','TND','KGS','BOB','MNT','FRF','LAK','NGN','GYD',
    'ITL','BGN','SDG','XPF','NAD','LRD','SIT','INR','UAH','CRC','GEL','KWD','SHP',
    'HUF','TOP','DEM','ISK','XDR','NLG','AOA','FKP','SRD','SZL','GMD','LKR','HRK','IQD',
    'GRD','BRL','PGK','LUF','HTG','DZD','BND','NIO','BSD','RON','PYG','PKR','MOP','FIM',
    'VUV','MMK','DOP','WST','IDR','TMT','GTQ','UYU','UZS','CLF','BWP','PHP','JOD','XAF',
    'BEF','QAR','GHS','LBP','LSL','BIF','XOF','MUR','LYD','RSD','ERN','AFN','PEN','GBX',
    'AED','TZS','RWF','YER','SBD','CLP','MZN','MKD','ARS','KHR','HNL','GIP','MYR','THB',
    'SCR','UGX','SAR','ESP','ATS','OMR','CVE','KES','PTE','EGP','NPR','KZT','SOS','MGA',
    'KMF','BHD','XCU','IEP','MXV','KRW','BTN','DJF','TTD','BDT','MAD','AWG','MVR','MWK',
    'CNY','ILS','JMD','BAM'
];

// { value: item, label: <><span className={`flag_24 ${item}`}/><span>{item}</span></> }

// export const options = [
//     { value: 'EUR', label: <><span className={`flag_24 EUR`}/><span>EUR</span></> },
//     { value: 'AUD', label: <><span className={`flag_24 AUD`}/><span>AUD</span></> },
//     { value: 'CAD', label: <><span className={`flag_24 CAD`}/><span>CAD</span></> },
//     { value: 'CHF', label: <><span className={`flag_24 CHF`}/><span>CHF</span></> },
//     { value: 'CNH', label: <><span className={`flag_24 CNH`}/><span>CNH</span></> },
//     { value: 'CZK', label: <><span className={`flag_24 CZK`}/><span>CZK</span></> },
//     { value: 'DKK', label: <><span className={`flag_24 DKK`}/><span>DKK</span></> },
//     { value: 'GBP', label: <><span className={`flag_24 GBP`}/><span>GBP</span></> },
//     { value: 'HKD', label: <><span className={`flag_24 HKD`}/><span>HKD</span></> },
//     { value: 'JPY', label: <><span className={`flag_24 JPY`}/><span>JPY</span></> },
//     { value: 'MXN', label: <><span className={`flag_24 MXN`}/><span>MXN</span></> },
//     { value: 'CNH', label: <><span className={`flag_24 CNH`}/><span>CNH</span></> },
//     { value: 'EUR', label: <><span className={`flag_24 EUR`}/><span>EUR</span></> },
//     { value: 'AUD', label: <><span className={`flag_24 AUD`}/><span>AUD</span></> },
//     { value: 'CAD', label: <><span className={`flag_24 CAD`}/><span>CAD</span></> },
//     { value: 'CHF', label: <><span className={`flag_24 CHF`}/><span>CHF</span></> },
//     { value: 'CNH', label: <><span className={`flag_24 CNH`}/><span>CNH</span></> },
//     { value: 'CZK', label: <><span className={`flag_24 CZK`}/><span>CZK</span></> },
//     { value: 'DKK', label: <><span className={`flag_24 DKK`}/><span>DKK</span></> },
//     { value: 'GBP', label: <><span className={`flag_24 GBP`}/><span>GBP</span></> },
//     { value: 'HKD', label: <><span className={`flag_24 HKD`}/><span>HKD</span></> },
//     { value: 'JPY', label: <><span className={`flag_24 JPY`}/><span>JPY</span></> },
//     { value: 'MXN', label: <><span className={`flag_24 MXN`}/><span>MXN</span></> },
//     { value: 'CNH', label: <><span className={`flag_24 CNH`}/><span>CNH</span></> }
// ];

export const options = [
    { value: 'EUR', label: <><span className={`flag_24 EUR`}/><span>EUR</span></> },
    { value: 'AUD', label: <><span className={`flag_24 AUD`}/><span>AUD</span></> },
    { value: 'CAD', label: <><span className={`flag_24 CAD`}/><span>CAD</span></> },
    { value: 'CHF', label: <><span className={`flag_24 CHF`}/><span>CHF</span></> },
    { value: 'CNH', label: <><span className={`flag_24 CNH`}/><span>CNH</span></> },
    { value: 'CZK', label: <><span className={`flag_24 CZK`}/><span>CZK</span></> },
    { value: 'DKK', label: <><span className={`flag_24 DKK`}/><span>DKK</span></> },
    { value: 'GBP', label: <><span className={`flag_24 GBP`}/><span>GBP</span></> },
    { value: 'HKD', label: <><span className={`flag_24 HKD`}/><span>HKD</span></> },
    { value: 'JPY', label: <><span className={`flag_24 JPY`}/><span>JPY</span></> },
    { value: 'MXN', label: <><span className={`flag_24 MXN`}/><span>MXN</span></> },
    { value: 'NOK', label: <><span className={`flag_24 NOK`}/><span>NOK</span></> },
    { value: 'NZD', label: <><span className={`flag_24 NZD`}/><span>NZD</span></> },
    { value: 'PLN', label: <><span className={`flag_24 PLN`}/><span>PLN</span></> },
    { value: 'RUB', label: <><span className={`flag_24 RUB`}/><span>RUB</span></> },
    { value: 'SEK', label: <><span className={`flag_24 SEK`}/><span>SEK</span></> },
    { value: 'SGD', label: <><span className={`flag_24 SGD`}/><span>SGD</span></> },
    { value: 'TRY', label: <><span className={`flag_24 TRY`}/><span>TRY</span></> },
    { value: 'USD', label: <><span className={`flag_24 USD`}/><span>USD</span></> },
    { value: 'ZAR', label: <><span className={`flag_24 ZAR`}/><span>ZAR</span></> },
    { value: 'COP', label: <><span className={`flag_24 COP`}/><span>COP</span></> },
    { value: 'ALL', label: <><span className={`flag_24 ALL`}/><span>ALL</span></> },
    { value: 'MDL', label: <><span className={`flag_24 MDL`}/><span>MDL</span></> },
    { value: 'AMD', label: <><span className={`flag_24 AMD`}/><span>AMD</span></> },
    { value: 'ANG', label: <><span className={`flag_24 ANG`}/><span>ANG</span></> },
    { value: 'GNF', label: <><span className={`flag_24 GNF`}/><span>GNF</span></> },
    { value: 'TND', label: <><span className={`flag_24 TND`}/><span>TND</span></> },
    { value: 'KGS', label: <><span className={`flag_24 KGS`}/><span>KGS</span></> },
    { value: 'BOB', label: <><span className={`flag_24 BOB`}/><span>BOB</span></> },
    { value: 'MNT', label: <><span className={`flag_24 MNT`}/><span>MNT</span></> },
    { value: 'FRF', label: <><span className={`flag_24 FRF`}/><span>FRF</span></> },
    { value: 'LAK', label: <><span className={`flag_24 LAK`}/><span>LAK</span></> },
    { value: 'NGN', label: <><span className={`flag_24 NGN`}/><span>NGN</span></> },
    { value: 'GYD', label: <><span className={`flag_24 GYD`}/><span>GYD</span></> },
    { value: 'ITL', label: <><span className={`flag_24 ITL`}/><span>ITL</span></> },
    { value: 'BGN', label: <><span className={`flag_24 BGN`}/><span>BGN</span></> },
    { value: 'SDG', label: <><span className={`flag_24 SDG`}/><span>SDG</span></> },
    { value: 'XPF', label: <><span className={`flag_24 XPF`}/><span>XPF</span></> },
    { value: 'NAD', label: <><span className={`flag_24 NAD`}/><span>NAD</span></> },
    { value: 'LRD', label: <><span className={`flag_24 LRD`}/><span>LRD</span></> },
    { value: 'SIT', label: <><span className={`flag_24 SIT`}/><span>SIT</span></> },
    { value: 'INR', label: <><span className={`flag_24 INR`}/><span>INR</span></> },
    { value: 'UAH', label: <><span className={`flag_24 UAH`}/><span>UAH</span></> },
    { value: 'CRC', label: <><span className={`flag_24 CRC`}/><span>CRC</span></> },
    { value: 'GEL', label: <><span className={`flag_24 GEL`}/><span>GEL</span></> },
    { value: 'KWD', label: <><span className={`flag_24 KWD`}/><span>KWD</span></> },
    { value: 'SHP', label: <><span className={`flag_24 SHP`}/><span>SHP</span></> },
    { value: 'HUF', label: <><span className={`flag_24 HUF`}/><span>HUF</span></> },
    { value: 'TOP', label: <><span className={`flag_24 TOP`}/><span>TOP</span></> },
    { value: 'DEM', label: <><span className={`flag_24 DEM`}/><span>DEM</span></> },
    { value: 'ISK', label: <><span className={`flag_24 ISK`}/><span>ISK</span></> },
    { value: 'XDR', label: <><span className={`flag_24 XDR`}/><span>XDR</span></> },
    { value: 'NLG', label: <><span className={`flag_24 NLG`}/><span>NLG</span></> },
    { value: 'AOA', label: <><span className={`flag_24 AOA`}/><span>AOA</span></> },
    { value: 'FKP', label: <><span className={`flag_24 FKP`}/><span>FKP</span></> },
    { value: 'SRD', label: <><span className={`flag_24 SRD`}/><span>SRD</span></> },
    { value: 'SZL', label: <><span className={`flag_24 SZL`}/><span>SZL</span></> },
    { value: 'GMD', label: <><span className={`flag_24 GMD`}/><span>GMD</span></> },
    { value: 'LKR', label: <><span className={`flag_24 LKR`}/><span>LKR</span></> },
    { value: 'HRK', label: <><span className={`flag_24 HRK`}/><span>HRK</span></> },
    { value: 'IQD', label: <><span className={`flag_24 IQD`}/><span>IQD</span></> },
    { value: 'GRD', label: <><span className={`flag_24 GRD`}/><span>GRD</span></> },
    { value: 'BRL', label: <><span className={`flag_24 BRL`}/><span>BRL</span></> },
    { value: 'PGK', label: <><span className={`flag_24 PGK`}/><span>PGK</span></> },
    { value: 'LUF', label: <><span className={`flag_24 LUF`}/><span>LUF</span></> },
    { value: 'HTG', label: <><span className={`flag_24 HTG`}/><span>HTG</span></> },
    { value: 'DZD', label: <><span className={`flag_24 DZD`}/><span>DZD</span></> },
    { value: 'BND', label: <><span className={`flag_24 BND`}/><span>BND</span></> },
    { value: 'NIO', label: <><span className={`flag_24 NIO`}/><span>NIO</span></> },
    { value: 'BSD', label: <><span className={`flag_24 BSD`}/><span>BSD</span></> },
    { value: 'RON', label: <><span className={`flag_24 RON`}/><span>RON</span></> },
    { value: 'PYG', label: <><span className={`flag_24 PYG`}/><span>PYG</span></> },
    { value: 'PKR', label: <><span className={`flag_24 PKR`}/><span>PKR</span></> },
    { value: 'MOP', label: <><span className={`flag_24 MOP`}/><span>MOP</span></> },
    { value: 'FIM', label: <><span className={`flag_24 FIM`}/><span>FIM</span></> },
    { value: 'VUV', label: <><span className={`flag_24 VUV`}/><span>VUV</span></> },
    { value: 'MMK', label: <><span className={`flag_24 MMK`}/><span>MMK</span></> },
    { value: 'DOP', label: <><span className={`flag_24 DOP`}/><span>DOP</span></> },
    { value: 'WST', label: <><span className={`flag_24 WST`}/><span>WST</span></> },
    { value: 'IDR', label: <><span className={`flag_24 IDR`}/><span>IDR</span></> },
    { value: 'TMT', label: <><span className={`flag_24 TMT`}/><span>TMT</span></> },
    { value: 'GTQ', label: <><span className={`flag_24 GTQ`}/><span>GTQ</span></> },
    { value: 'UYU', label: <><span className={`flag_24 UYU`}/><span>UYU</span></> },
    { value: 'UZS', label: <><span className={`flag_24 UZS`}/><span>UZS</span></> },
    { value: 'CLF', label: <><span className={`flag_24 CLF`}/><span>CLF</span></> },
    { value: 'BWP', label: <><span className={`flag_24 BWP`}/><span>BWP</span></> },
    { value: 'PHP', label: <><span className={`flag_24 PHP`}/><span>PHP</span></> },
    { value: 'JOD', label: <><span className={`flag_24 JOD`}/><span>JOD</span></> },
    { value: 'XAF', label: <><span className={`flag_24 XAF`}/><span>XAF</span></> },
    { value: 'BEF', label: <><span className={`flag_24 BEF`}/><span>BEF</span></> },
    { value: 'QAR', label: <><span className={`flag_24 QAR`}/><span>QAR</span></> },
    { value: 'GHS', label: <><span className={`flag_24 GHS`}/><span>GHS</span></> },
    { value: 'LBP', label: <><span className={`flag_24 LBP`}/><span>LBP</span></> },
    { value: 'LSL', label: <><span className={`flag_24 LSL`}/><span>LSL</span></> },
    { value: 'BIF', label: <><span className={`flag_24 BIF`}/><span>BIF</span></> },
    { value: 'XOF', label: <><span className={`flag_24 XOF`}/><span>XOF</span></> },
    { value: 'MUR', label: <><span className={`flag_24 MUR`}/><span>MUR</span></> },
    { value: 'LYD', label: <><span className={`flag_24 LYD`}/><span>LYD</span></> },
    { value: 'RSD', label: <><span className={`flag_24 RSD`}/><span>RSD</span></> },
    { value: 'ERN', label: <><span className={`flag_24 ERN`}/><span>ERN</span></> },
    { value: 'AFN', label: <><span className={`flag_24 AFN`}/><span>AFN</span></> },
    { value: 'PEN', label: <><span className={`flag_24 PEN`}/><span>PEN</span></> },
    { value: 'GBX', label: <><span className={`flag_24 GBX`}/><span>GBX</span></> },
    { value: 'AED', label: <><span className={`flag_24 AED`}/><span>AED</span></> },
    { value: 'TZS', label: <><span className={`flag_24 TZS`}/><span>TZS</span></> },
    { value: 'RWF', label: <><span className={`flag_24 RWF`}/><span>RWF</span></> },
    { value: 'YER', label: <><span className={`flag_24 YER`}/><span>YER</span></> },
    { value: 'SBD', label: <><span className={`flag_24 SBD`}/><span>SBD</span></> },
    { value: 'CLP', label: <><span className={`flag_24 CLP`}/><span>CLP</span></> },
    { value: 'MZN', label: <><span className={`flag_24 MZN`}/><span>MZN</span></> },
    { value: 'MKD', label: <><span className={`flag_24 MKD`}/><span>MKD</span></> },
    { value: 'ARS', label: <><span className={`flag_24 ARS`}/><span>ARS</span></> },
    { value: 'KHR', label: <><span className={`flag_24 KHR`}/><span>KHR</span></> },
    { value: 'HNL', label: <><span className={`flag_24 HNL`}/><span>HNL</span></> },
    { value: 'GIP', label: <><span className={`flag_24 GIP`}/><span>GIP</span></> },
    { value: 'MYR', label: <><span className={`flag_24 MYR`}/><span>MYR</span></> },
    { value: 'THB', label: <><span className={`flag_24 THB`}/><span>THB</span></> },
    { value: 'SCR', label: <><span className={`flag_24 SCR`}/><span>SCR</span></> },
    { value: 'UGX', label: <><span className={`flag_24 UGX`}/><span>UGX</span></> },
    { value: 'SAR', label: <><span className={`flag_24 SAR`}/><span>SAR</span></> },
    { value: 'ESP', label: <><span className={`flag_24 ESP`}/><span>ESP</span></> },
    { value: 'ATS', label: <><span className={`flag_24 ATS`}/><span>ATS</span></> },
    { value: 'OMR', label: <><span className={`flag_24 OMR`}/><span>OMR</span></> },
    { value: 'CVE', label: <><span className={`flag_24 CVE`}/><span>CVE</span></> },
    { value: 'KES', label: <><span className={`flag_24 KES`}/><span>KES</span></> },
    { value: 'PTE', label: <><span className={`flag_24 PTE`}/><span>PTE</span></> },
    { value: 'EGP', label: <><span className={`flag_24 EGP`}/><span>EGP</span></> },
    { value: 'NPR', label: <><span className={`flag_24 NPR`}/><span>NPR</span></> },
    { value: 'KZT', label: <><span className={`flag_24 KZT`}/><span>KZT</span></> },
    { value: 'SOS', label: <><span className={`flag_24 SOS`}/><span>SOS</span></> },
    { value: 'MGA', label: <><span className={`flag_24 MGA`}/><span>MGA</span></> },
    { value: 'KMF', label: <><span className={`flag_24 KMF`}/><span>KMF</span></> },
    { value: 'BHD', label: <><span className={`flag_24 BHD`}/><span>BHD</span></> },
    { value: 'XCU', label: <><span className={`flag_24 XCU`}/><span>XCU</span></> },
    { value: 'IEP', label: <><span className={`flag_24 IEP`}/><span>IEP</span></> },
    { value: 'MXV', label: <><span className={`flag_24 MXV`}/><span>MXV</span></> },
    { value: 'KRW', label: <><span className={`flag_24 KRW`}/><span>KRW</span></> },
    { value: 'BTN', label: <><span className={`flag_24 BTN`}/><span>BTN</span></> },
    { value: 'DJF', label: <><span className={`flag_24 DJF`}/><span>DJF</span></> },
    { value: 'TTD', label: <><span className={`flag_24 TTD`}/><span>TTD</span></> },
    { value: 'BDT', label: <><span className={`flag_24 BDT`}/><span>BDT</span></> },
    { value: 'MAD', label: <><span className={`flag_24 MAD`}/><span>MAD</span></> },
    { value: 'AWG', label: <><span className={`flag_24 AWG`}/><span>AWG</span></> },
    { value: 'MVR', label: <><span className={`flag_24 MVR`}/><span>MVR</span></> },
    { value: 'MWK', label: <><span className={`flag_24 MWK`}/><span>MWK</span></> },
    { value: 'CNY', label: <><span className={`flag_24 CNY`}/><span>CNY</span></> },
    { value: 'ILS', label: <><span className={`flag_24 ILS`}/><span>ILS</span></> },
    { value: 'JMD', label: <><span className={`flag_24 JMD`}/><span>JMD</span></> },
    { value: 'BAM', label: <><span className={`flag_24 BAM`}/><span>BAM</span></> }
];


export const optionsCrypto = [
    { value: 'BTC', label: <><span className={`flag_24 BTC`}/><span>BTC</span></> },
    { value: 'ETH', label: <><span className={`flag_24 ETH`}/><span>ETH</span></> },
    { value: 'LTC', label: <><span className={`flag_24 LTC`}/><span>LTC</span></> },
    { value: 'XRP', label: <><span className={`flag_24 XRP`}/><span>XRP</span></> },
    { value: 'BCH', label: <><span className={`flag_24 BCH`}/><span>BCH</span></> }
];