import {Helmet} from "react-helmet";
import React, {Component} from "react";
import ExchangeRatetoday from "./ExchangeRatetoday";
import TopLossLeader from "./TopLossLeader";
import {connect} from "react-redux";
import {getAvailableCouples, getExchange} from "../../redux/actions/userActions";
import {translate} from "react-i18next";


const getAvailab = () => {
    return ["AUDCAD", "AUDCHF", "AUDJPY", "AUDNZD", "AUDUSD", "CADCHF", "CADJPY", "CHFJPY", "EURAUD", "EURCAD", "EURCHF", "EURCZK", "EURGBP", "EURHKD", "EURJPY", "EURMXN", "EURNZD", "EURPLN", "EURRUB", "EURSEK", "EURTRY", "EURUSD", "EURZAR", "GBPAUD", "GBPCAD", "GBPCHF", "GBPJPY", "GBPNZD", "GBPSEK", "GBPUSD", "NZDCAD", "NZDCHF", "NZDJPY", "NZDUSD", "USDCAD", "USDCHF", "USDCNH", "USDCZK", "USDDKK", "USDHKD", "USDJPY", "USDMXN", "USDNOK", "USDPLN", "USDRUB", "USDSEK", "USDSGD", "USDTRY", "USDZAR"]
}

const renderHtml = (props) => (<span
    dangerouslySetInnerHTML={{__html: props.replace("{{CUR1}}", "sdsd")}}>
</span>);

class HomePageLanding extends Component {

    constructor(props) {
        super(props);
        //console.log("home",props);
        this.state = {
            phrases: "",
            chart_text: "",
            quotes_text: "",
            banner_url: `https://www.ifcmarkets.com/${this.props.language}/trading/stocks`,
            lang: ["en", "cs", "fr", "hi", "it", "ja", "ms", "pt", "ru", "tr", "vi", "zh", "zh_cn", "ar", "ID", "fa"]
        };

    }

    componentDidMount() {
        document.body.dir = `${(this.props.language === 'ar' || this.props.language === 'fa') ? 'rtl' : "ltr"}`;
        getAvailab();
        // this.props.getAvailableCouples();
        this.props.getExchange(this.props.cur, this.props.cur2);
        this.getCurrectLink()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.getCurrectLink()
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.cur !== nextProps.cur) || (this.props.cur2 !== nextProps.cur2) || (this.props.language !== nextProps.language)
    }

    getCurrectLink = () => {
        switch (this.props.language) {
            case "en":
            case "cs":
            case "fr":
            case "hi":
            case "it":
            case "ja":
            case "ms":
            case "pt":
            case "ru":
            case "tr":
            case "vi":
            case "zh":
            case "zh_cn":
                this.setState({banner_url: `https://www.ifcmarkets.com/${this.props.language}/trading/stocks`});
                break;
            case "ar":
                this.setState({banner_url: "https://www.ifcmarkets.net/trading/stocks"});
                break;
            case "ID":
                this.setState({banner_url: "https://www.ifcmarkets.co.id/trading/stocks"});
                break;
            case "fa":
                this.setState({banner_url: `https://www.ifcmiran.asia/${this.props.language}/trading/stocks`});
                break;
            default:
                this.setState({banner_url: `https://www.ifcmarkets.com/${this.props.language}/trading/stocks`});
        }
    }

    render() {
        const {cur, cur2, number} = this.props;
        const {t} = this.props;
        const ifc_domain = `$_IFC_DOMAIN`;
        return (
            <div>
                <Helmet>
                    <html lang={this.props.language}/>
                    <title>{`${(t('$_NEW_CURRENCY_CONVERTOR_TITLE', {
                        CUR1: `${cur}`,
                        CUR2: `${cur2}`,
                        CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                        CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
                        CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                        CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
                        CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                        CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                        CUR1_TRANS: (t(`${cur}`)),
                        CUR2_TRANS: (t(`${cur2}`)),
                        AMOUNT: number
                    }))} | CFDRATES`}</title>
                    <meta property="og:title" content={`${(t('$_NEW_CURRENCY_CONVERTOR_TITLE', {
                        CUR1: `${cur}`,
                        CUR2: `${cur2}`,
                        CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                        CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
                        CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                        CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
                        CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                        CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                        CUR1_TRANS: (t(`${cur}`)),
                        CUR2_TRANS: (t(`${cur2}`)),
                        AMOUNT: number
                    }))} | CFDRATES`}/>
                    <link rel="canonical" href={window.location.href}/>
                    <meta name="description" content={`${(t('$_CURRENCY_CONVERTOR_DESC', {
                        CUR1: `${cur}`,
                        CUR2: `${cur2}`,
                        CUR1_PLURAL: (t(`${cur}_PLURAL`)),
                        CUR2_PLURAL: (t(`${cur2}_PLURAL`)),
                        CUR1_DATIVE: (t(`${cur}_DATIVE`)),
                        CUR2_DATIVE: (t(`${cur2}_DATIVE`)),
                        CUR1_MULTIPLE: (t(`${cur}_MULTIPLE`)),
                        CUR2_MULTIPLE: (t(`${cur2}_MULTIPLE`)),
                        CUR1_TRANS: (t(`${cur}`)),
                        CUR2_TRANS: (t(`${cur2}`)),
                        AMOUNT: number
                    }))}`}/>
                </Helmet>
                <div className="content">
                    <ExchangeRatetoday/>
                    <TopLossLeader/>
                    <div className="bg-banner">
                        <div><img src={'/assets/img/banner/ifc-logo.png'} width={'236'} height={'96'} alt={'IFC logo'}/>
                        </div>
                        <div className="head-start-trading">
                            <h2>{t('REG_BANNER_HEADER')}</h2>
                            <div className="btn-block">
                                <a
                                    className="btn btn-md button_blue"
                                    target="_blank"
                                    href={`${t('REGISTER_NOW_DEMO')}`}
                                    rel="noopener noreferrer"
                                >
                                    {t('DEMO_ACCOUNT')}

                                </a>
                                <a target="_blank"
                                   href={`${t('REGISTER_NOW_REAL')}`}
                                   rel="noopener noreferrer" className="btn btn-md button_green"
                                >
                                    {t('CREATE_ACCOUNT')}
                                </a>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        cur: state.cur,
        cur2: state.cur2,
        data: state.data,
        availableCouple: state.availableCouple,
        number: state.number,
        language: state.language

    }),
    dispatch => ({
        getExchange: (cur, cur2) => dispatch(getExchange(cur, cur2)),
        getAvailableCouples: () => dispatch(getAvailableCouples())
    })
)(translate('common')(HomePageLanding));
