import React, {Component} from 'react';
import LiveExchangeRates from "./LiveExchangeRates";
import Tabs from "../tab_component/Tabs";
import Tab from "../tab_component/Tab";
import MarginCalcForm from "./MarginCalcForm";
import _ from "lodash";
import {translate} from "react-i18next";
import {Link} from "react-router-dom";
import { CALCULATOR_ROUTE_MAKER, MAIN_ROUTE_BY_LANGUAGE__FUNC } from "../helpers/constants";
import {connect} from "react-redux";


const renderHtml = (props) => (<div
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

class MarginCalculator extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        fetch("https://www.ifcm.co.uk:2083/instrument")
            .then(res => res.json())
            .then(instruments => {

                var grouped = _.mapValues(_.groupBy(instruments, 'category'),
                    clist => clist.map(car => _.omit(car, 'category')));

                // console.log(grouped)

                this.setState({instruments: grouped});

            })
    }

    render(){
        const { t, language } = this.props;

        return(
            <div>
                <div className="bradcrumbs size_900">
                    <Link to={MAIN_ROUTE_BY_LANGUAGE__FUNC(language)}>
                        {(t('$_HOME'))}</Link>
                    <span className="brdcrumbs_line"> / </span>
                    <Link to={CALCULATOR_ROUTE_MAKER(language)}>
                        {(t('$_TRADING_TOOLS'))}</Link>
                    <span className="brdcrumbs_line"> / </span>
                    {(t('$_MARGIN_CALCULATOR'))}
                </div>
            <div className="size_900 margin_calc">
            <p className="calculators">
                {renderHtml(t('$_MARGIN_CALCULATOR'))}
            </p>
                <div className="nt_mt_tabs">
                    <Tabs type={"margin"} platform={this.props.platform}>
                        <Tab label="NetTradeX" >
                            <MarginCalcForm hideBuySell={true}
                                            instruments={this.state.instruments}
                                            platform={"nttx"}
                            />
                        </Tab>
                        <Tab label="MetaTrader 4/5">
                            <MarginCalcForm instruments={this.state.instruments}
                                            platform={"mt4"}
                            />
                        </Tab>
                    </Tabs>
                </div>
            <LiveExchangeRates/>
            </div>
            </div>
        )
        }
        }
        export default connect(
            state => ({
                language: state.language,
                platform: state.platform,
            }))(translate('common')(MarginCalculator));
