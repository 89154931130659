import React from "react";
import {connect} from "react-redux";
import {options, optionsCrypto} from "./../helpers/CountryList";
import {withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import {numberValueCrypto} from "./../redux/actions/userActions";
import Select from 'react-select';

import "./SelectComp.css";

class SelectCompCrypto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            secondOption: null,
            reversed : false
        };

    }

    handleChange = selectedOption => {
        this.setState({selectedOption: selectedOption}, () => this.props.onCurrencyChangedCrypto(selectedOption.value, this.props.curCrypto2));
    };
    handleChange2 = secondOption => {
        this.setState({secondOption: secondOption}, () => this.props.onCurrencyChangedCrypto(this.props.curCrypto, secondOption.value,));
    };


    numberChangedCrypto(e){
        if(e.target.value.length <= 10){
            this.props.onNumberValueCrypto(e)} else {return ""}
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.curCrypto !== nextProps.curCrypto) || (this.props.curCrypto2 !== nextProps.curCrypto2) || (this.props.numberCrypto!==nextProps.numberCrypto)
    }

    reverseCurrencies(curCrypto2,curCrypto){
        this.setState({reversed: !this.state.reversed}, () => this.props.onCurrencyChangedCrypto(curCrypto2, curCrypto));
    }

    render() {
        const { numberCrypto, curCrypto, curCrypto2, onCurrencyChangedCrypto} = this.props;
        const allCurr = optionsCrypto.concat(options);

        let optionsOne = this.state.reversed ? allCurr : optionsCrypto;
        let optionsSec = this.state.reversed ? optionsCrypto : allCurr;

        return (
            <div className='selectCur'>

                <label for="__frst_conve_val" className="sections sec1">
                    <input id="__frst_conve_val"
                           type="number"
                           name="amount"
                           aria-label="Value"
                           value={numberCrypto}
                           className="magicLabels"
                           onChange={(e) => this.numberChangedCrypto(e)}
                    />
                </label>

                <Select
                    className={"sections sec2"}
                    value={{value: curCrypto,
                        label: <><span
                            className={`flag_24 ${curCrypto}`}/><span>{curCrypto}</span></>
                    }}
                    aria-label="Currency"
                    onChange={this.handleChange}
                    options={optionsOne}
                />

                <div className="sections sec3"
                     onClick={() => this.reverseCurrencies(curCrypto2, curCrypto)}>
                    <button id="replasecur" className="center-block converter_replasecur" aria-label="convert">
                        <span/>
                    </button>
                </div>
                <Select
                    className={"sections sec4"}
                    value={{value: curCrypto2,
                        label: <><span
                            className={`flag_24 ${curCrypto2}`}/><span>{curCrypto2}</span></>
                    }}
                    aria-label="Currency"
                    onChange={this.handleChange2}
                    options={optionsSec}
                />
            </div>);
    }
}

export default connect(
    state => ({
        curCrypto: state.curCrypto,
        curCrypto2: state.curCrypto2,
        rate: state.rate,
        numberCrypto: state.numberCrypto,
        language: state.language
    }),
    dispatch => ({
        onInitialNumberValueCrypto: (num) => dispatch(numberValueCrypto(num)),
        onNumberValueCrypto: (e) => dispatch(numberValueCrypto(e.target.value))
    })
)(translate('common')(withRouter(SelectCompCrypto)))

