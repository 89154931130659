import {
    CHANGE_CURRENCY,
    CHANGE_CURRENCY_CRYPTO,
    CHANGE_CURRENCY_LANDING,
    GET_RATE_SUCCESS,
    GET_EXCHANGE_SUCCESS,
    OTHER_CURRENCY,
    CHANGE_NUMBER,
    LANGUAGE_CHANGE,
    NUMBER_VALUE,
    GET_AVAILABLE_COUPLES_SUCCESS,
    CLOSE_MOBILE_MENU,
    OPEN_MOBILE_MENU,
    CHANGE_PLATFORM_TYPE,
    HANDLE_IS_FETCHING,
    GET_CURS_DATA_SUCCESS,
    CHANGED_CALCULATOR_COUPLES,
    GET_TOP_GAIN_LOSS_SUCCESS,
    CHANGED_ACTIVE_INDEX,
    CURRENCY_CONVERTER_SUCCESS,
    GET_EXCHANGE_RATE_TODAY_SUCCESS,
    GET_INITIAL_CURS_DATA_SUCCESS,
    GET_INITIAL_CURS_DATA_SUCCESS_CRYPTO,
    NUMBER_VALUE_CRYPTO,
    NUMBER_VALUE_LANDING,
    GET_AVAILABLE_COUPLES_SUCCESS_CRYPTO,
    GET_EXCHANGE_SUCCESS_CRYPTO,
    GET_EXCHANGE_RATE_TODAY_SUCCESS_CRYPTO,
    GET_TOP_CRYPTO_PAIRS_RATE_SUCCESS, GET_VOLATILITY_SUCCESS, GET_INITIAL_CURS_DATA_SUCCESS_LANDING,
} from "../actions/userActions";

// console.log(history, window.location.pathname.length);


// const initialCurs = `${(window.location.pathname.length > 2)?window.location.pathname.split("/")[2].split('-'): firstArr}` ;


let tabIndexNumber;
if(window.location.pathname.includes("currencies")){
    tabIndexNumber =  2
}else if(window.location.pathname.includes("forex")){
    tabIndexNumber = 1
}else if(window.location.pathname.includes("crypto")){
    tabIndexNumber = 3;
}else{
    tabIndexNumber = 0;
}

const availableCouplesCrypto =  ["BCHUSD","BTCUSD","ETHUSD","LTCUSD","XRPUSD"],
    availableCouples = [
        "AUDCAD","AUDCHF","AUDJPY","AUDNZD","AUDUSD","CADCHF","CADJPY","CHFJPY",
        "EURAUD","EURCAD","EURCHF","EURCZK","EURGBP","EURHKD","EURJPY","EURMXN",
        "EURNZD","EURPLN","EURRUB","EURSEK","EURTRY","EURUSD","EURZAR","GBPAUD",
        "GBPCAD","GBPCHF","GBPJPY","GBPNZD","GBPSEK","GBPUSD","NZDCAD","NZDCHF",
        "NZDJPY","NZDUSD","USDCAD","USDCHF","USDCNH","USDCZK","USDDKK","USDHKD",
        "USDJPY","USDMXN","USDNOK","USDPLN","USDRUB","USDSEK","USDSGD","USDTRY",
        "USDZAR"
    ];



function getCurrenciesFromPathname(){
    let res = {
        cur : "EUR",
        cur2 : "USD",
        curCrypto : "BTC",
        curCrypto2 : "USD"
    }
    if(window.location.pathname.length>7){
        if(!window.location.pathname.includes("forex-calculator")){
            if(window.location.pathname.includes("crypto-converter")) {
                if(window.location.pathname.split("/")[3]) {
                    res.curCrypto = window.location.pathname.split("/")[3].split("-")[0].toUpperCase()
                    res.curCrypto2 = window.location.pathname.split("/")[3].split("-")[1].toUpperCase()
                }
            }else if(window.location.pathname.includes("landing-canada")){
                if(window.location.pathname.split("/")[3]) {
                    res.cur = window.location.pathname.split("/")[3].split("-")[0].toUpperCase()
                    res.cur2 = window.location.pathname.split("/")[3].split("-")[1].toUpperCase()
                }
            }else{
                const pathSegments = window.location.pathname.split("/");
                const parts = pathSegments[2].split("-");
                if (parts.length >= 2) {
                    res.cur = parts[0].toUpperCase();
                    res.cur2 = parts[1].toUpperCase();
                }
            }
        }
    }
    return res;
}

function getLanguage() {

    let lang = localStorage.getItem("language");

    if (!lang) {
        lang = window.location.pathname.split("/")[1] || "en";
    }

    if(lang === 'fa' || lang === 'ar'){
        document.body.dir = "rtl";
    }

    return lang;
}

function getInitialNumber(){
    let number = 1;
    let parts = window.location.pathname.split("/");
    if(parts && parts[3]){
        number = Number(parts[3]);
    }
    return number;
}

const initialState = {
    cur:getCurrenciesFromPathname()['cur'],
    cur2: getCurrenciesFromPathname()['cur2'],
    curCrypto: getCurrenciesFromPathname()['curCrypto'],
    curCrypto2: getCurrenciesFromPathname()['curCrypto2'],
    availableCouple: getInitialAvailableCouple(getCurrenciesFromPathname()['cur'],  getCurrenciesFromPathname()['cur2']),
    availableCoupleCrypto: getCryptoAvailableCouple(getCurrenciesFromPathname()['curCrypto'],  getCurrenciesFromPathname()['curCrypto2']),
    curData: null,
    calculatorInitialCouple: "EURUSD",
    availableCouples: availableCouples,
    availableCouplesCrypto: availableCouplesCrypto,
    miniIndex: 0,
    rate: null,
    number: getInitialNumber(),
    numberCrypto: getInitialNumber(),
    data: {
        ask: null,
        bid: null,
        state: null,
        close: null,
        open: null,
        high: null,
        low: null,
        percent: null,
    },
    dataCrypto: {
        ask: null,
        bid: null,
        state: null,
        close: null,
        open: null,
        high: null,
        low: null,
        percent: null,
    },
    language: getLanguage(),
    isMobileMenuOpened: false,
    platform: 'nttx',
    isFetching: true,
    CurrencyConverteData: null,
    activeIndex: + tabIndexNumber,
    exchangeRateTodayRate: null,
    exchangeRateTodayRateCrypto: null,
    topCryptoPairsRate: null,
    volatility: null
};

function getInitialAvailableCouple(cur, cur2){
    let returnCouple = null
    let couples = cur + cur2;
    let reverseCouples = cur2 + cur;
    if(availableCouples.includes(couples)){
        returnCouple =  couples;
    }else if (availableCouples.includes(reverseCouples)){
        returnCouple =  reverseCouples;
    }
    return returnCouple;
}

function getCryptoAvailableCouple(cur, cur2){
    let returnCouple = "BTCUSD"
    let couples = cur + cur2;
    let reverseCouples = cur2 + cur;
    if(availableCouplesCrypto.includes(couples)){
        returnCouple =  couples;
    }
    else if(availableCouplesCrypto.includes(reverseCouples)){
        returnCouple =  reverseCouples;
    }
    return returnCouple;
}

function getAvailableCouples(availableCouples, cur, cur2) {
    if(availableCouples.includes(cur + cur2)){
        return cur + cur2
    } else {
        if(availableCouples.includes(cur2 + cur)){
            return cur2 + cur
        }
    }
    return null
}

function getInitialCouple(state,payload) {
    if(payload.cur && state.availableCouples.length){
        return getAvailableCouples(state.availableCouples, payload.cur, payload.cur2)
    }
    return state.availableCouple
}

function getInitialCoupleCrypto(state,payload) {
    if(payload.curCrypto && state.availableCouplesCrypto.length){
        return getAvailableCouples(state.availableCouplesCrypto, payload.curCrypto, payload.curCrypto2)
    }
    return state.availableCoupleCrypto
}


export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CHANGE_CURRENCY: return {
            ...state,
            availableCouple: payload.isInitial ? getInitialCouple(state, payload) : state.availableCouples.length ? getAvailableCouples(state.availableCouples, payload.cur, payload.cur2): "",
            cur: payload.cur,
            cur2: payload.cur2,
        };
        case CHANGE_CURRENCY_LANDING:
            return {
                ...state,
                availableCouple: payload.isInitial ? getInitialCouple(state, payload) : state.availableCouples.length ? getAvailableCouples(state.availableCouples, payload.cur, payload.cur2): "",
                cur: payload.cur,
                cur2: payload.cur2,
            };
        case CHANGE_CURRENCY_CRYPTO:
            return {
            ...state,
            availableCoupleCrypto: payload.isInitial ? getInitialCoupleCrypto(state, payload) : state.availableCouplesCrypto.length ? getAvailableCouples(state.availableCouplesCrypto, payload.curCrypto, payload.curCrypto2): "",
            curCrypto: payload.curCrypto,
            curCrypto2: payload.curCrypto2,
        };
        case GET_RATE_SUCCESS: return {
            ...state,
            rate: payload.rate
        };
        case HANDLE_IS_FETCHING:
            return {
                ...state,
                isFetching: payload.isFetching
            };
        case GET_EXCHANGE_SUCCESS: return {
            ...state,
            data: payload.data
        };
        case GET_EXCHANGE_SUCCESS_CRYPTO: return {
            ...state,
            dataCrypto: payload.dataCrypto
        };
        case OTHER_CURRENCY: return {
            ...state,
            cur: payload.cur,
            cur2: payload.cur2,
        };
        case CHANGE_NUMBER: return {
            ...state,
            number: payload.item
        };
        case LANGUAGE_CHANGE: return {
            ...state,
            language: payload.language
        };
        case NUMBER_VALUE: return{
            ...state,
            number: payload.number
        };
        case NUMBER_VALUE_CRYPTO: return{
            ...state,
            numberCrypto: payload.numberCrypto
        };
        case NUMBER_VALUE_LANDING: return{
            ...state,
            number: payload.number
        };
        case GET_AVAILABLE_COUPLES_SUCCESS: return {
            ...state,
            availableCouples: payload.availableCouples
        };
        case GET_AVAILABLE_COUPLES_SUCCESS_CRYPTO: return {
            ...state,
            availableCouplesCrypto: payload.availableCouplesCrypto
        };
        case CURRENCY_CONVERTER_SUCCESS: return {
            ...state,
            CurrencyConverteData: payload.CurrencyConverteData
        };
        case CLOSE_MOBILE_MENU : return {
            ...state,
            isMobileMenuOpened: false
        };
        case OPEN_MOBILE_MENU: return {
            ...state,
            isMobileMenuOpened: true
        };
        case CHANGE_PLATFORM_TYPE: return {
            ...state,
            platform: payload.platform
        };
        case GET_CURS_DATA_SUCCESS: return {
            ...state,
            curData: payload.curData
        };
        case CHANGED_CALCULATOR_COUPLES: return {
            ...state,
            calculatorInitialCouple: payload.calculatorInitialCouple
        };
        case GET_TOP_GAIN_LOSS_SUCCESS: return {
            ...state,
            topGainLoss: payload.topGainLoss
        };
        case CHANGED_ACTIVE_INDEX: return {
            ...state,
            activeIndex: payload.activeIndex
        };
        case GET_EXCHANGE_RATE_TODAY_SUCCESS: return {
            ...state,
            exchangeRateTodayRate: payload.exchangeRateTodayRate
        };
        case GET_EXCHANGE_RATE_TODAY_SUCCESS_CRYPTO:
            return {
            ...state,
            exchangeRateTodayRateCrypto: payload.exchangeRateTodayRateCrypto
        };
        case GET_TOP_CRYPTO_PAIRS_RATE_SUCCESS:
            return {
                ...state,
                topCryptoPairsRate: payload.topCryptoPairsRate
        };
        case GET_INITIAL_CURS_DATA_SUCCESS: return {
            ...state,
            rate: payload.rate
        }
        case GET_INITIAL_CURS_DATA_SUCCESS_CRYPTO: return {
            ...state,
            rate: payload.rate
        }
        case GET_INITIAL_CURS_DATA_SUCCESS_LANDING: return {
            ...state,
            rate: payload.rate
        }
        case GET_VOLATILITY_SUCCESS: return {
            ...state,
            volatility: payload.volatility
        }
        default: return state;

    }
}
