import React from "react";
import {connect} from "react-redux";
import {
    changeCurencyCrypto, getInitialCursDataCrypto, currencyConverterCrypto, numberValueCrypto
} from "../redux/actions/userActions"
import {withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import SelectCompCrypto from "../components/SelectCompCrypto";

const renderHtml = (props) => (<div className="p2"
    dangerouslySetInnerHTML={{__html: props}}>
</div>);

const renderHtml_2 = (props) => (<h1 className="h1_title"
    dangerouslySetInnerHTML={{__html: props}}>
</h1>);


class DemoMiniHomePageCrypto extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            rate: ""
        }
    }

    handleTextChanged = (curCrypto, curCrypto2) => {
        this.props.onCurrencyChangedCrypto(curCrypto, curCrypto2)
        this.props.currencyConverterCrypto(curCrypto, curCrypto2)
    }

    componentDidMount() {
        this.props.getInitialCursDataCrypto(this.props.curCrypto, this.props.curCrypto2);
        const currenciesArrayFromHistory = this.props.match.params.curencies && this.props.match.params.curencies.split("-");
        const currenciesAmount = this.props.match.params.amount;
        if (currenciesAmount) {
            this.props.onInitialNumberValueCrypto(currenciesAmount)
        }
        if (currenciesArrayFromHistory) {
            this.props.onCurrencyChangedCrypto(currenciesArrayFromHistory[0].toUpperCase(), currenciesArrayFromHistory[1].toUpperCase(), true);
        } else {
            this.props.onCurrencyChangedCrypto(this.props.curCrypto, this.props.curCrypto2, true);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        console.log(this.props.language,nextProps.language)
        return (this.props.language !== nextProps.language)
            || (this.props.curCrypto !== nextProps.curCrypto)
            || (this.props.curCrypto2 !== nextProps.curCrypto2)
            || (this.props.rate !== nextProps.rate)
            || (this.props.numberCrypto !== nextProps.numberCrypto)
    }

    render() {
        const {curCrypto, curCrypto2, changedAmount, rate, numberCrypto} = this.props;
        const {t} = this.props;
        const myCur_first = `${curCrypto}`;
        const myCur_second = `${curCrypto2}`;
        const myCur_first_plural = `${curCrypto}_PLURAL`;
        const myCur_second_plural = `${curCrypto2}_PLURAL`;
        const myCur_first_dative = `${curCrypto}_DATIVE`;
        const myCur_second_dative = `${curCrypto2}_DATIVE`;
        const myCur_first_multiple = `${curCrypto}_MULTIPLE`;
        const myCur_second_multiple = `${curCrypto2}_MULTIPLE`;
        const myCur_first_trans = `${curCrypto}_TRANS`;
        const myCur_second_trans = `${curCrypto2}_TRANS`;


        return (
            <div className="currency_box">

                <div className="left_box">
                    <div className="left_text">
                        {renderHtml_2(t('$_CRYPTO_CONVERTER_H1', {
                                AMOUNT: numberCrypto,
                                CUR1: myCur_first,
                                CUR2: myCur_second,
                                CUR1_PLURAL: (t(myCur_first_plural)),
                                CUR2_PLURAL: (t(myCur_second_plural)),
                                CUR1_DATIVE: (t(myCur_first_dative)),
                                CUR2_DATIVE: (t(myCur_second_dative)),
                                CUR1_MULTIPLE: (t(myCur_first_multiple)),
                                CUR2_MULTIPLE: (t(myCur_second_multiple)),
                                CUR1_TRANS: (t(myCur_first_trans)),
                                CUR2_TRANS: (t(myCur_second_trans)),
                            }
                        ))}
                        {renderHtml(t('$_CRYPTO_TEXT1'))}
                        <div className="select_div"
                             id="select_wrapper"
                             data-cur1={curCrypto.toUpperCase()}
                             data-cur2={curCrypto2.toUpperCase()}>
                            <SelectCompCrypto cur={"wewewewe"} onCurrencyChangedCrypto={this.handleTextChanged}/>
                        </div>
                        <div className="select_text" dir="ltr">
                            {
                                (numberCrypto > 0 || numberCrypto < 0) ?
                                    <span className="amount" id="cur1_val">{numberCrypto}</span>
                                    : <span className="amount" id="cur1_val">0</span>
                            }
                            <span className="cur1" data-currency={curCrypto.toLowerCase()}>{` ${curCrypto}`}</span>
                            {` = `}
                            {
                                ((numberCrypto * (1 * rate)).toFixed(6) > 0) ? <span id="fz">
                            <span className="result" id="_calc_wrap">{changedAmount}</span>
                            <span className="cur2"
                                  data-currency={curCrypto2.toLowerCase()}>{`${(numberCrypto * (1 * rate)).toFixed(6)} ${curCrypto2}`}</span>
                            </span>
                                    :
                                    `${curCrypto2}`
                            }
                        </div>

                    </div>
                    <div className="p2-currency">
                        <div className="select_text" dir="ltr">
                            {
                                <span className="amount" id="cur1_val">1</span>
                            }
                            <span className="cur1" data-currency={curCrypto.toLowerCase()}>{` ${curCrypto}`}</span>
                            <span className="equal">{` = `}</span>
                            {
                                <span id="fz">
                            <span className="result" id="_calc_wrap">{changedAmount}</span>
                            <span className="cur2 cur2-color"
                                  data-currency={curCrypto2.toLowerCase()}>{`${((1 * rate)).toFixed(2)} `}</span>
                               <span className="cur2">{`${curCrypto2}`}</span>
                            </span>
                            }
                            <span className="slash">{` / `}</span>
                            {
                                <span className="amount" id="cur1_val">1</span>
                            }
                            <span className="cur1" data-currency={curCrypto.toLowerCase()}>{` ${curCrypto2}`}</span>
                            <span className="equal">{` = `}</span>
                            {
                                <span id="fz">
                            <span className="result" id="_calc_wrap">{changedAmount}</span>
                            <span className="cur2 cur2-color"
                                  data-currency={curCrypto2.toLowerCase()}>{`${((1 / rate)).toFixed(2)} `}</span>
                               <span className="cur2" data-currency={curCrypto2.toLowerCase()}>{`${curCrypto}`}</span>
                            </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, ownProps) {
    return {
        curCrypto: state.curCrypto,
        curCrypto2: state.curCrypto2,
        rate: state.rate,
        numberCrypto: state.numberCrypto,
        language: state.language
    }
}

function mapDispatchToProps(dispatch){

    return {
        // dispatching plain actions
        onCurrencyChangedCrypto: (curCrypto, curCrypto2, isInitial) => dispatch(changeCurencyCrypto(curCrypto, curCrypto2, isInitial)),
        onNumberValueCrypto: (e) => dispatch(numberValueCrypto(e.target.value)),
        onInitialNumberValueCrypto: (num) => dispatch(numberValueCrypto(num)),
        currencyConverterCrypto: (curCrypto, curCrypto2) => dispatch(currencyConverterCrypto(curCrypto, curCrypto2)),
        getInitialCursDataCrypto: (curCrypto, curCrypto2) => dispatch(getInitialCursDataCrypto(curCrypto, curCrypto2)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(translate('common')(withRouter(DemoMiniHomePageCrypto)))

